<template>
  <div
    class="main_container"
    style="padding: 0"
  >
    <div
      style="position: absolute; height: 56px; width: 100%; background: var(--card-secondary-background)"
      class="main_container"
    />
  </div>

  <head-new
    :background-color="state.imageIsCover ? 'transparent' : 'var(--card-secondary-background)'"
    :back-action="back"
    style="position: relative; z-index: 1"
  />
  <div
    class="main_container header"
    style="position:relative;"
  >
    <div
      v-if="state.imageIsCover"
      class="coverImage"
      :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.course.preview_internal_image))}"
    />
    <div
      v-if="state.imageIsCover"
      class="coverWrapper"
    />
    <div
      v-else
      class="imageWrapper"
    >
      <Skeleton
        v-if="state.isLoading"
        width="168"
        height="168"
      />
      <div
        v-else
        class="image"
        :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.course.preview_image))}"
      />
    </div>

    <div
      :style="{display: 'flex', gap: '16px', flexDirection: 'column', position: 'relative'}"
    >
      <div
        class="courseTitle"
      >
        <div
          v-if="state.isLoading"
        >
          <Skeleton
            variant="text"
            width="240"
            height="24"
          />
        </div>
        <span
          v-else
        >{{ state?.course?.title }}</span>
      </div>

      <CourseProgressbar
        :is-loading="state.isLoading"
        :text="state.userCourse && getCourseProgressText(state.userCourse.progress)"
        :completed="state.userCourse && getCourseProgressPercent(state.userCourse.progress)"
      />

      <Skeleton
        v-if="state.isLoading"
        full-width
        height="48"
        radius="12"
      />
      <MenuItem
        v-else
        text="Возникли вопросы?"
        :disabled="state.course.course_questions.length === 0 && store.state.user.directories.course_question_list === 0"
        :icon="require(`@/assets/icons/gray_24_question.svg`)"
        :action="() => router.push({
          name: 'StudyCourseQuestions',
          params: {userCourseId: state.userCourse.id},
        })"
      />
    </div>
  </div>
  <div
    class="main_container"
  >
    <Card
      v-if="
        state.userCourse
          &&
          state.userCourse.student_course_reviews.length === 0
          &&
          Number(state.userCourse.progress.completed_lessons_percent) === 100
      "
      style="background: var(--app-accent-light-color);margin-bottom: 12px"
    >
      <Typography
        variant="v16-700"
        style="margin-bottom: 4px"
      >
        Поздравляем
      </Typography>
      <Typography
        variant="v14-400"
        style="margin-bottom: 16px"
      >
        Теперь ты можешь покорять вершины с новыми знаниями! Поделись, как тебе курс, и мы сможем сделать его ещё лучше
      </Typography>
      <AppButton
        variant="white"
        color="#000"
        size="small"
        @click="() => {
          router.push({name: 'StudyCourseReview', params: {userCourseId: state.userCourse.id }})
        }"
      >
        Написать отзыв
      </AppButton>
    </Card>

    <div
      v-if="!state.isLoading"
      class="moduleList"
    >
      <CourseModuleLink
        v-for="(item, i) in state.modules"
        :index="i"
        :module="item"
        @click="() => {
          router.push({name: 'StudyModule', params: {userCourseId: state.userCourse.id, moduleId: item.id}})
        }"
      />
    </div>

    <div
      v-if="!state.isLoading"
      class="lessonList"
    >
      <CourseLessonLink
        v-for="(item, i) in state.lessons"
        :index="i"
        :lesson="item"
        @click="() => {
          router.push({name: 'StudyCourseLesson', params: {userCourseId: state.userCourse.id, lessonId: item.id}})
        }"
      />
    </div>

    <div
      v-if="!state.isLoading"
      class="lessonList"
    >
      <CourseLessonLink
        v-for="(item, i) in state.materials"
        :index="i"
        :lesson="item"
        is-material
        @click="() => {
          router.push({name: 'StudyCourseLesson', params: {
            userCourseId: state.userCourse.id,
            lessonId: item.id
          }})
        }"
      />
    </div>
  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios'
import CourseProgressbar from '@/components/UI/CourseProgressbar.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import MenuItem from '@/components/UI/MenuItem.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import CourseModuleLink from '@/components/Courses/CourseModuleLink.vue'
import CourseLessonLink from '@/components/Courses/CourseLessonLink.vue'
import {router} from '@/router/router'
import {getCourseProgressPercent, getCourseProgressText} from '@/utils/courseUtils'
import {lessonTypes} from '@/configs/lessonTypes'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import store from '@/store/store'
import Typography from "@/components/UI/Typography.vue"
import AppButton from "@/components/UI/AppButton.vue"
import Card from "@/components/UI/Card.vue"

const props = defineProps({
  userCourseId: {
    type: String,
  },
})

const state = reactive({
  isLoading: true,
  userCourse: null,
  course: null,
  courseType: '', // lessons | modules

  lessons: [],
  materials: [],
  modules: [],

  imageIsCover: false,

  studentLessonsMap: {},
  studentModulesMap: {},
})

onBeforeMount(async () => {
  try {
    const res = await appAxios.myCourses.fetchOne(props.userCourseId)
    const resCourse = await appAxios.course.cacheOne(res.data.course_id)

    state.userCourse = res.data
    state.course = resCourse.data
    state.courseType = state.course.modules.length > 0 ? 'modules' : 'lessons'

    state.imageIsCover = state.course.preview_internal_image

    state.userCourse.student_lessons.forEach(sl => {
      state.studentLessonsMap[sl.lesson_id] = sl
    })

    state.userCourse.student_modules.forEach(sm => {
      // todo?
      state.studentModulesMap[sm.module_id] = sm
    })

    let lastUnfinishedModuleIndex = null
    state.modules = state.course.modules.map((module, index) => {
      const isFinished = !!state.studentModulesMap[module.id]?.finished_at
      if (lastUnfinishedModuleIndex === null && !isFinished) {
        lastUnfinishedModuleIndex = index
      }
      return {
        ...module,
        isFinished: isFinished,
        isLastUnfinished: lastUnfinishedModuleIndex === index,
      }
    })

    let lastUnfinishedLessonIndex = null
    state.lessons = state.course.lessons
      .filter(lesson => lesson.lesson_type === lessonTypes.lesson)
      .map((lesson, index) => {
        const isFinished = !!state.studentLessonsMap[lesson.id]?.finished_at
        if (lastUnfinishedLessonIndex === null && !isFinished) {
          lastUnfinishedLessonIndex = index
        }
        return {
          ...lesson,
          isFinished: isFinished,
          isLastUnfinished: lastUnfinishedLessonIndex === index,
        }
      })
    state.materials = state.course.lessons
      .filter(lesson => lesson.lesson_type === lessonTypes.material)
      .map((lesson, index) => {
        const isFinished = !!state.studentLessonsMap[lesson.id]?.finished_at
        return {
          ...lesson,
          isFinished: isFinished,
          isLastUnfinished: false,
        }
      })

  } catch (e) {
    console.log('error', e)
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

function back() {
  router.push({
    name: 'StudyCourses'
  })
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.coverWrapper {
  height: 168px;
  margin-bottom: 12px;
}

.coverImage {
  position: absolute;
  height: calc(100% + 56px);
  left: 0;
  right: 0;
  top: -56px;
  bottom: 0;
  z-index: 0;
  background-position: center center;
  background-size: cover;

  &:after {
    content: " ";
    display: block;
    position: absolute;
    height: 100px;
    background: linear-gradient(0deg, rgba(23, 23, 23, 1) 0%, rgba(23, 23, 23, 0) 100%);
    bottom: -1px;
    left: 0;
    right: 0;
  }
}

.header {
  background: var(--card-secondary-background);
  border-color: var(--card-secondary-border, transparent);
  box-shadow: var(--card-secondary-shadow, none);
  border-radius: 0 0 20px 20px;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.imageWrapper {
  width: 168px;
  height: 168px;
  margin: 0 auto 12px auto;
}

.image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.courseTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.review {
  margin-bottom: 12px;

  .title {
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 700;
  }

  .text {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.moduleList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lessonList:not(:empty):not(:last-child) {
  border-bottom: 1px solid #2B2B2B;
}

</style>
