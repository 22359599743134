<template>
  <div>
    <Typography
      variant="h2"
      style="margin-bottom: 8px"
    >
      Контакты
    </Typography>

    <Typography
      variant="body2"
      style="margin-bottom: 12px"
      color="var(--text-notice-color)"
    >
      Обрати внимание, что все контакты будут отображаться в твоем профиле
    </Typography>

    <FormError
      v-show="errors.error"
      name="error"
      style="margin-bottom: 12px"
    />

    <FormTextInput
      :label="ct('Label.PhoneNumber')"
      name="contact_phone"
      pattern="[0-9]*"
      inputmode="tel"
      :mask="state.phoneMask"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim()
        if(paste.at(0) === '8') {
          cleared = paste.replace('8', '+7')
        }
        setFieldValue('contact_phone', cleared)
      }"
    />

    <FormTextInput
      label="Telegram"
      name="contact_telegram"
      data-maska-tokens="*:[a-zA-Z0-9+@_]|T:[a-zA-Z0-9+]"
      :show-mask="'t.me/'"
      mask="t.me/T********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('t.me', '').replace('/', '')
        setFieldValue('contact_telegram', 't.me/' + cleared)
      }"
    />

    <FormTextInput
      label="Instagram*"
      name="contact_instagram"
      text-info="*Instagram, продукт компании Meta, признанная экстремистской организацией в России"
      :data-maska-tokens="'*:[a-zA-Z0-9+@_\.~-]'"
      :show-mask="'instagram.com/'"
      mask="instagram.com/********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('instagram.com', '').replace('/', '')
        setFieldValue('contact_instagram', cleared)
      }"
    />

    <FormTextInput
      label="VK"
      name="contact_vk"
      :data-maska-tokens="'*:[a-zA-Z0-9+@_\.~-]'"
      :show-mask="'vk.com/'"
      mask="vk.com/********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('vk.com', '').replace('/', '')
        setFieldValue('contact_vk', cleared)
      }"
    />

    <FormTextInput
      label="YouTube"
      name="contact_youtube"
      :data-maska-tokens="'*:[a-zA-Z0-9+@_\.~-]'"
      :show-mask="'youtube.com/'"
      mask="youtube.com/********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('youtube.com', '').replace('/', '')
        setFieldValue('contact_youtube', cleared)
      }"
    />

    <FormTextInput
      label="TikTok"
      name="contact_tiktok"
      :data-maska-tokens="'*:[a-zA-Z0-9+@_\.~-]'"
      :show-mask="'tiktok.com/'"
      mask="tiktok.com/********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('tiktok.com', '').replace('/', '')
        setFieldValue('contact_tiktok', cleared)
      }"
    />

    <div
      style="flex-grow: 1"
    />

    <div
      id="bottom-support"
      style="padding: 0 16px; background: var(--background-main-color); border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <div
        class="main_container"
        style="padding: 0;"
      >
        <AppButton
          style="margin-top: 16px; margin-bottom: 12px"
          size="large"
          full-width
          :loading="isSubmitting"
          :disabled="isSubmitting"
          @click="onSubmit"
        >
          Завершить
        </AppButton>

        <AppButton
          variant="secondary"
          style="margin-top: 8px; margin-bottom: 24px"
          size="large"
          full-width
          @click="() => props.nextStep()"
        >
          Пропустить
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store.js'
import {onBeforeMount, onMounted, reactive, watch} from 'vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import FormError from '@/components/form/FormError.vue'
import {openExternalURL} from "@/utils/openExternalURL.js";
import AppLink from "@/components/AppLink.vue";
import * as libPhoneNumber from 'libphonenumber-js'
import {ct} from '@/locales/i18nextInit.js'

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
  createUserStepIfNotExist: {
    type: Function,
    require: true,
  },
})

const state = reactive({
  phoneMask: '+######################'
})

const {handleSubmit, setErrors, errors, isSubmitting, setFieldValue, values, setValues,} = useForm({
  validationSchema: Yup.object({
    contact_instagram: Yup.string().trim().label('Instagram'),
    contact_telegram: Yup.string().trim().label('Telegram'),
    contact_tiktok: Yup.string().trim().label('TikTok'),
    contact_vk: Yup.string().trim().label('VK'),
    contact_youtube: Yup.string().trim().label('YouTube'),
    contact_phone: Yup.string()
      .trim()
      .test('phone', 'Некорректный номер телефона', value => {
        if (value) {
          try {
            const phone = libPhoneNumber.parsePhoneNumber(value)
            return phone.isValid()
          } catch (e) {
            return false
          }
        }
        return true
      })
      .label('Номер телефона'),
    error: Yup.mixed()
      .test('test1', 'Необходимо добавить контакт', () =>
        values.contact_instagram || values.contact_telegram || values.contact_tiktok || values.contact_vk || values.contact_youtube || values.contact_phone
      ),
  }),
})

watch(values, values => {
  try {
    const value = String(values.contact_phone).replaceAll(' ', '')
    let newMask = new libPhoneNumber
      .AsYouType()
      .input(value)
      .toString()
      .replace(/\d/g, '#')
    newMask = `+${newMask.replace('+', '')}######################`
    if (newMask !== state.phoneMask) {
      state.phoneMask = newMask
    }
  } catch (e) {
    state.phoneMask = '+######################'
  }
})
onMounted(() => {
  setValues({
    contact_telegram: store.state.user.profile.contact_telegram ?? '',
    contact_tiktok: store.state.user.profile.contact_tiktok ?? '',
    contact_instagram: store.state.user.profile.contact_instagram ?? '',
    contact_vk: store.state.user.profile.contact_vk ?? '',
    contact_youtube: store.state.user.profile.contact_youtube ?? '',
    contact_phone: store.state.user.profile.contact_phone ?? '',
  })
  requestAnimationFrame(() => {
    setErrors({
      contact_instagram: null,
      contact_telegram: null,
      contact_tiktok: null,
      contact_vk: null,
      contact_youtube: null,
      contact_phone: null,
      error: null,
    })
    requestAnimationFrame(() => {
      setErrors({
        contact_instagram: null,
        contact_telegram: null,
        contact_tiktok: null,
        contact_vk: null,
        contact_youtube: null,
        contact_phone: null,
        error: null,
      })
      requestAnimationFrame(() => {
        setErrors({
          contact_instagram: null,
          contact_telegram: null,
          contact_tiktok: null,
          contact_vk: null,
          contact_youtube: null,
          contact_phone: null,
          error: null,
        })
      })
    })
  })
})

const onSubmit = handleSubmit(async () => {
  const payload = {
    ...values,
  }
  await store.dispatch('user/updateProfile', payload)
  await props.nextStep()
})

</script>

<style lang="scss" scoped>

</style>
