<template>
  <div
    class="pageWrapper"
  >
    <head-new
      :page_title="t('marketplace:Title')"
      :back-action="() => router.push({name: 'Home'})"
      :right-icon="icons.search"
      :right-action="() => router.push({name: 'MarketplaceProductsSearch', params: { productId }})"
    />

    <AnotherUserProfileModalLoading
      :user="state.selectedUser"
      style="position: relative;z-index: 1002"
      @close="() => {state.selectedUser = null}"
    />

    <MarketplaceProductsList
      v-if="!state.isLoading"
      :products="state.products"
      :is-loading="state.isLoading"
      :count="state.count"
      :is-selected-product="state.isSelectedProduct"
      @open-user="openUser"
    />
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {router} from '@/router/router'
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios'
import AnotherUserProfileModalLoading from "@/views/profile/AnotherUserProfileModalLoading.vue"
import store from "@/store/store.js"
import {subscriptionStatuses} from '@/configs/subscriptionStatuses.js'
import MarketplaceProductsList from './MarketplaceProductsList.vue'
import {loadingCount} from '@/configs/productsStatus.js'
import {icons} from '@/assets/icon/icons.js'

const props = defineProps({
  productId: {
    type: String,
  }
})

import {t} from 'i18next'
import Card from '@/components/UI/Card.vue'

const state = reactive({
  isLoading: true,
  products: [],
  isSelectedProduct: null,
  selectedUser: null,
  count: 0,

  subscriptionStatus: store.state.user.profile.subscriptions?.at(0)?.status ?? subscriptionStatuses.new
})

function openUser(user) {
  if (!store.getters["user/profileProgress"].isEnoughFilled) {
    store.dispatch('app/setMessageComponent', {
      messageComponent: 'EnoughFilledRequired',
      messageComponentData: {text: 'Чтобы получить доступ к этой функции'},
    })
    return
  }
  state.selectedUser = user
}

onBeforeMount(async () => {
  const params = {
    limit: loadingCount,
    offset: 0,
  }
  const res = await appAxios.products.fetch(params)

  state.count = res.data.count
  state.products = res.data.results

  if (props.productId) {
    const found = res.data.find(p => String(p.id) === String(props.productId))
    if (found) {
      state.isSelectedProduct = found
    }
  }

  state.isLoading = false
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}


</style>
