<template>
  <div
    class="head_new"
  >
    <div
      class="main_container"
    >
      <div
        class="buttonSpace"
      >
        <div
          v-if="backAction"
          class="link"
          @click="backAction()"
        >
          <BackIcon />
        </div>
      </div>

      <div
        v-if="isLoading"
      >
        <Skeleton
          width="220"
          height="16"
        />
      </div>
      <div
        v-else-if="!isLoading && (typeof title === 'string' || typeof page_title === 'string')"
        class="title"
      >
        {{ page_title ? page_title : title }}
      </div>
      <slot
        v-else
      />

      <div
        v-if="!noSpace || (rightAction && rightIcon)"
        class="buttonSpace"
      >
        <template
          v-if="rightAction && rightIcon"
        >
          <component
              v-if="!rightIsButton"
              class="rightIcon"
              :is="rightIcon"
              @click="rightAction"
          />
          <AppButton
              v-else
            class="appButton"
            variant="secondary"
            size="large"
            @click="rightAction"
          >
            <component :is="rightIcon" />
          </AppButton>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '@/components/UI/Skeleton.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import AppButton from '@/components/UI/AppButton.vue'
import Icon from '@/components/UI/Icon.vue'
import BackIcon from '@/assets/icon/BackIcon.vue'

export default {
  components: {BackIcon, Icon, AppButton, Skeleton},
  props: {
    to: {
      type: String,
      default: "#",
    },
    page_title: {
      type: String,
    },
    title: {
      type: String,
    },
    backAction: {
      type: Function,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: 'transparent'
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    rightIcon: {
      type: Object,
      default: null,
    },
    rightAction: {
      type: Function,
      default: null,
    },
    rightIsButton: {
      type: Boolean,
      default: false,
    },
    noSpace: {
      type: Boolean,
      default: false,
    }
  },
  methods: {urlToCssURL},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.head_new {
  flex-shrink: 0;

  .main_container {
    padding: 8px 16px 0 16px;
    height: 44px + $topOffset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: v-bind(backgroundColor);
  }

  .buttonSpace {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .rightIcon {
    width: 24px;
    height: 24px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .link {
    cursor: pointer;
  }

  .title {
    color: var(--text-primary-color);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
}

.appButton {
  margin: -10px 0 0 -24px;
}
</style>
