<template>
  <div
    class="pageWrapper"
  >
    <head-new
      :back-action="() => router.push({name: 'Profile'})"
      title="Управление подпиской"
    />
    <div
      v-if="state.shownCardsRemoved"
      class="page"
    >
      <div
        style="flex-grow: 1"
      />
      <div
        class="main_container"
        style="display:flex; flex-direction: column; align-items: center;"
      >
        <div
          class="finalIcon"
        >
          <div
            class="greenBackground"
          />
        </div>
        <Typography
          variant="v20-700"
          style="margin-bottom: 8px"
          center
        >
          Подписка отменена
        </Typography>
        <Typography
          variant="v14-400"
          color="secondary"
          center
        >
          Возобновить её можно в любой момент, через свой профиль
        </Typography>
      </div>
      <div
        style="flex-grow: 1"
      />
      <div
        class="main_container"
      >
        <AppButton
          full-width
          size="large"
          @click="router.push({name: 'Home'})"
        >
          Вернуться на главный экран
        </AppButton>
      </div>
    </div>

    <BottomDrawer
      :close-on-click-away="!state.isCardsRemoving"
      :is-shown="state.shownPaymentsPause"
      @close="state.shownPaymentsPause = false"
    >
      <div
        style="display: flex; align-items: center; justify-content: center;"
      >
        <YounesisLogo
          variant="leadpay"
          size="large"
        />
      </div>

      <Typography
        variant="v24-700"
        center
      >
        Подтверждение отмены подписки
      </Typography>

      <Typography
        variant="v14-400"
        color="#838181"
      >
        Ты можешь отменить подписку сейчас и продолжить её использование до даты окончания
        {{ (new Date(state.subscription.ends_at)).toLocaleDateString('ru-RU', dataFormatOptions) }}
      </Typography>

      <div
        class="delimiter"
      />

      <AppButton
        full-width
        style="margin-bottom: 8px"
        :loading="state.isCardsRemoving"
        :disabled="state.isCardsRemoving"
        @click="removeCards"
      >
        Подтвердить
      </AppButton>
      <AppButton
        full-width
        variant="secondary"
        :disabled="state.isCardsRemoving"
        @click="state.shownPaymentsPause = false"
      >
        Не сейчас
      </AppButton>
    </BottomDrawer>

    <div
      class="main_container pageWrapperOverflow"
    >
      <div
        style="display: flex; gap: 8px; align-items: center; margin-top: 16px"
      >
        <Typography
          variant="v24-700"
        >
          Younesis Premium
        </Typography>
        <div
          class="check"
        />
      </div>

      <Card
        style="margin-top: 16px"
      >
        <Typography
          variant="v12-500"
          color="secondary"
          style="margin-bottom: 4px"
        >
          {{ state.price.title }}
        </Typography>

        <SubscriptionFeaturesBlock
          :title="state.price.title"
          style="margin-top: 8px; margin-bottom: 16px"
        />

        <Typography
          variant="v16-600"
          style="margin-bottom: 4px"
        >
          {{ rubFormat.format(state.price.discounted_price ?? state.price.price) }} ₽
        </Typography>

        <Typography
          variant="v12-500"
          color="secondary"
        >
          {{ state.cards.length ? 'Спишется' : 'До' }}
          {{ (new Date(state.subscription.ends_at)).toLocaleDateString('ru-RU', dataFormatOptions) }}
        </Typography>

        <AppButton
          v-if="state.cards.length"
          style="margin-top: 16px"
          size="small"
          variant="secondary"
          @click="state.shownPaymentsPause = true"
        >
          Приостановить
        </AppButton>
      </Card>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {onBeforeMount, reactive} from 'vue'
import {router} from '@/router/router.js'
import Typography from '@/components/UI/Typography.vue'
import Card from '@/components/UI/Card.vue'
import store from '@/store/store.js'
import AppButton from '@/components/UI/AppButton.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import YounesisLogo from '@/components/logo/YounesisLogo.vue'
import {appAxios} from '@/axios.js'
import SubscriptionFeaturesBlock from '@/views/subscriptions/SubscriptionFeaturesBlock.vue'

const rubFormat = new Intl.NumberFormat('ru-RU', {})

const dataFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric'
}

const state = reactive({
  cards: store.state.user.profile.cards,
  shownPaymentsPause: false,
  isCardsRemoving: false,
  shownCardsRemoved: false,
  price: store.state.user.profile.subscriptions.at(0).price,
  subscription: store.state.user.profile.subscriptions.at(0)
})

onBeforeMount(() => {
  // console.log('>', store.state.user.profile.subscriptions.at(0))
})

async function removeCards() {
  state.isCardsRemoving = true
  await Promise.all(state.cards.map(card => {
    return appAxios.cards.delete(card.id)
  }))
  state.cards = []
  await store.dispatch('user/updateProfile', {cards: []})
  state.shownPaymentsPause = false
  state.shownCardsRemoved = true
  state.isCardsRemoving = false
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.page {
  min-height: calc(100svh);
  max-height: calc(100svh);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  z-index: 101;
  background: var(--background-main-color);
  padding: 32px 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.pageWrapper {
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  min-height: calc(100svh - 60px);
  max-height: calc(100svh - 60px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
  padding-bottom: 24px;
}


.check {
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  background-color: #20B537;
  border-radius: 12px;

  &:before {
    content: " ";
    position: absolute;
    top: -2px;
    left: -2px;
    display: block;
    width: 28px;
    height: 28px;
    background: url("@/assets/icons/white_24_checkBox.svg") no-repeat;
    background-size: contain;
  }
}

.delimiter {
  width: 100%;
  margin: 16px 0;
  border: 1px solid #2B2B2B;
}

.greenBackground {
  position: absolute;
  background: radial-gradient(rgba(86, 231, 118, 0.35) 0%, rgba(88, 234, 120, 0.05) 25%, rgba(0, 0, 0, 0) 50%);
  width: 224px;
  height: 224px;
  left: -50%;
  top: -50%;
}

.finalIcon {
  position: relative;
  background-image: url("@/assets/icons/bigSuccess.svg");
  height: 112px;
  width: 112px;
}

</style>
