<template>
  <Card
    class="card"
    :class="{isNotEnoughFilled: !profileProgress.isEnoughFilled}"
  >
    <Typography
      variant="subtitle1"
      style="margin-bottom: 4px;"
    >
      {{ t('user:CheckList.Home.Title') }}
    </Typography>

    <Typography
      variant="body1"
      color="primary"
      style="margin-bottom: 12px;"
    >
      {{ t('user:CheckList.Home.Text') }}
    </Typography>

    <BarProgress
      :completed="!profileProgress.isEnoughFilled ? (profileProgress.enoughFilledCount) : (profileProgress.fullFilledCount)"
      :bar-count="!profileProgress.isEnoughFilled ? profileProgress.enoughFields.length : profileProgress.fullFields.length"
      style="padding-bottom: 12px"
    />

    <AppButton
      full-width
      size="small"
      @click="navigate({name: 'CheckList'})"
    >
      {{ t('user:CheckList.Home.Start') }}
    </AppButton>
  </Card>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import Card from '@/components/UI/Card.vue'
import AppButton from '@/components/UI/AppButton.vue'
import store from '@/store/store'
import {computed, reactive, watch} from 'vue'
import {router} from '@/router/router'
import BarProgress from "@/components/BarProgress.vue"
import {t} from 'i18next'

const profileProgress = computed(() => store.getters["user/profileProgress"])

const props = defineProps({
  progress: {
    type: String,
  }
})

function navigate(route) {
  router.push({
    ...route,
  })
}

</script>

<style lang="scss" scoped>

.card {
  margin-bottom: 16px;
}


</style>
