<template>
  <div
    class="container"
  >
    <div
      class="pinnedTop"
    >
      <slot
        name="header"
      ></slot>
    </div>
    <div
      class="content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .pinnedTop {

  }

  .content {
    overflow: auto;
    flex-direction: column;
    display: flex;

    & > * {
      flex-shrink: 0;
    }
  }
}

</style>
