import BackIcon from '@/assets/icon/BackIcon.vue'
import CalendarIcon from '@/assets/icon/CalendarIcon.vue'
import EditIcon from '@/assets/icon/EditIcon.vue'
import FolderIcon from '@/assets/icon/FolderIcon.vue'
import PlusIcon from '@/assets/icon/PlusIcon.vue'
import SearchIcon from '@/assets/icon/SearchIcon.vue'
import UserIcon from '@/assets/icon/UserIcon.vue'
import ClockIcon from '@/assets/icon/ClockIcon.vue'
import NextIcon from '@/assets/icon/NextIcon.vue'
import HomeProductsIcon from '@/assets/icon/HomeProductsIcon.vue'
import HomeStudyIcon from '@/assets/icon/HomeStudyIcon.vue'

const icons = {
  homeProducts: HomeProductsIcon,
  homeStudy: HomeStudyIcon,
  //
  back: BackIcon,
  calendar: CalendarIcon,
  edit: EditIcon,
  folder: FolderIcon,
  plus: PlusIcon,
  search: SearchIcon,
  user: UserIcon,
  clock: ClockIcon,
  next: NextIcon,
}

export {icons}
