import axios from "axios"
import store from '@/store/store.js'
import {settings} from '@/configs/settings.js'

async function getVersion() {
  const res = await axios({
    url: `/version.json?&timestamp=${new Date().getTime()}`, //your url
    method: 'GET',
    responseType: 'json',
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  })
  return res.data.version
}

async function checkVersion() {
  const requiredVersion = await getVersion()
  const currentVersion = settings.env.VUE_APP_VERSION

  if (requiredVersion !== currentVersion) {
    await store.dispatch('app/setIsNewVersionAvailable', true)
  }
}

checkVersion()
setInterval(() => {
  checkVersion()
}, 5 * 60e3)
