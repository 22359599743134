<template>
  <head-new
    page_title=""
    :back-action="() => {
      router.push({name: $route.meta.returnName, params: {collectionId: collectionId}})
    }"
  />

  <BottomDrawer
    v-if="!state.isLoading"
    :is-shown="state.isAddingContactShown"
    :title="state.userContact ? t('cardholder:Action.Delete') : t('cardholder:Action.Add')"
    @close="state.isAddingContactShown = false"
  >
    <Typography
      variant="v14-600"
      color="secondary"
    >
      {{
        t('cardholder:RemovePersonWarning', {
          person: `${state.user.first_name} ${state.user.last_name}`
        })
      }}
    </Typography>

    <div
      style="display: flex; gap: 4px; margin: 16px 0"
    >
      <appButton
        full-width
        class="submitButton"
        variant="secondary"
        :disabled="state.isDeleting"
        @click="state.isAddingContactShown = false"
      >
        {{ ct('Action.Cancel') }}
      </appButton>
      <appButton
        full-width
        class="submitButton"
        variant="secondary"
        color="var(--text-error-color)"
        :disabled="state.isDeleting"
        :loading="state.isDeleting"
        @click="() => evaluated(0)"
      >
        {{ ct('Action.Delete') }}
      </appButton>
    </div>
  </BottomDrawer>

  <BottomDrawer
    v-if="!state.isLoading"
    :is-shown="state.isSelectedProduct"
    close-button-outside
    @close="state.isSelectedProduct = null"
  >
    <div
      v-if="state.isSelectedProduct"
      class="productInfo"
    >
      <div
        v-if="state.isSelectedProduct.preview_image"
        class="imgWrapper full"
        :style="{height: `${((Math.min(width, 480)) / 1.8)|0}px`}"
      >
        <div
          class="img"
          :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.isSelectedProduct.preview_image))}"
        />
      </div>

      <div>
        <div
          class="title"
        >
          {{ state.isSelectedProduct.title }}
        </div>
        <Typography
          variant="body1"
        >
          {{ state.isSelectedProduct.description }}
        </Typography>
      </div>

      <div
        class="bottom drawer"
      >
        <Avatar
          size="tiny"
          :image="state.user?.preview_avatar"
        />

        <div
          style="flex-grow: 1; flex-shrink: 1;"
        >
          <Typography
            base="span"
            variant="body1"
            color="secondary"
          >
            {{ state.user.first_name }}
          </Typography>
          {{ ' ' }}
          <Typography
            base="span"
            variant="body1"
            color="secondary"
          >
            {{ state.user.last_name }}
          </Typography>
        </div>

        <AppButton
          size="small"
          @click="applyForProduct(state.isSelectedProduct)"
        >
          {{ ct('Action.StartChat') }}
        </AppButton>
      </div>
    </div>
  </BottomDrawer>

  <div
    class="main_container userData"
  >
    <Avatar
      v-if="!state.isLoading"
      v-fullscreen-image="{
        imageUrl: [state.user?.avatar],
        zoom: true,
      }"
      size="large"
      :image="imagesLinkCache.cache(state.user?.preview_avatar)"
    />
    <Skeleton
      v-else
      variant="text"
      height="106"
      width="106"
      radius="16"
    />

    <div
      class="userInfo"
    >
      <Typography
        v-if="!state.isLoading"
        variant="v16-700"
      >
        {{ state.user?.first_name }} {{ state.user?.last_name }}
      </Typography>
      <Skeleton
        v-else
        variant="text"
        height="24"
        width="200"
      />

      <AppButton
        v-if="!state.isLoading"
        variant="secondary"
        size="small"
        @click="startChat(userId)"
      >
        <Icon
          :icon="require(`@/assets/icons/white_20_chat.svg`)"
        />
        {{ ct('Action.StartChat') }}
      </AppButton>
      <Skeleton
        v-else
        width="126"
        height="36"
      />
    </div>
  </div>

  <div
    class="main_container"
  >
    <UserLocationAndAge
      v-if="!state.isLoading"
      :city="state.user?.city"
      :birth-date="state.user?.birth_date"
    />

    <UserSpecializations
      style="margin-bottom: 8px; margin-top: 12px;"
      :specializations="state.user?.specializations"
    />

    <UserProfessions
      v-if="!state.isLoading"
      class="userProfile"
      :professions="state.user?.professions"
    />

    <Typography
      v-if="!state.isLoading"
      pre-line
      variant="body1"
    >
      {{ state.user?.about_me }}
    </Typography>
    <Skeleton
      v-for="i in 3"
      v-else
      variant="text"
      :width="String(200 + (i % 2) * 50 + (i * 20))"
      height="14"
    />
  </div>

  <div
    class="main_container buttons"
  >
    <BlockContacts
      v-if="state.user"
      :profile="state.user"
    />

    <AppButton
      v-if="!state.userContact"
      :is-loading="state.isLoading"
      full-width
      variant="secondary"
      @click="() => evaluated(5)"
    >
      {{ t('cardholder:Action.Add') }}
    </AppButton>
    <AppButton
      v-else
      :is-loading="state.isLoading"
      full-width
      variant="secondary"
      @click="() => state.isAddingContactShown = true"
    >
      В визитнице
    </AppButton>
  </div>
  <div
    class="main_container"
  >
    <BlockNiches
      v-if="state.user?.niches.length"
      :niches="state.user?.niches"
    />

    <BlockLookingFor
      v-if="state.user?.looking_for.length"
      :looking-for="state.user?.looking_for"
      @apply-for-skill="applyForSkill"
    />

    <BlockAchievements
      v-if="state.user?.achievements.length"
      :achievements="state.user?.achievements"
    />

    <BlockProducts
      v-if="state.user?.products.length"
      :products="state.user?.products"
      :can-be-obtained="true"
      @select-product="product => state.isSelectedProduct = product"
    />

    <BlockEducations
      v-if="state.user?.education[0] && (state.user?.education[0].courses.length || state.user?.education[0].communities.length || state.user?.education[0].institutions.length)"
      :education="state.user?.education"
    />
  </div>
</template>

<script setup>
import {router} from '@/router/router'
import HeadNew from '@/components/Head.vue'
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios'
import Icon from '@/components/UI/Icon.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Avatar from '@/components/profile/Avatar.vue'
import Typography from '@/components/UI/Typography.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import UserCard from '@/components/UserCard.vue'
import Evaluation from '@/components/Evaluation.vue'
import UserProfessions from '@/components/UserProfessions.vue'
import UserLocationAndAge from '@/components/UserLocationAndAge.vue'
import BlockNiches from '@/components/BlockNiches.vue'
import BlockAchievements from '@/components/BlockAchievements.vue'
import {copyToClipboard} from '@/utils/copyToClipboard'
import BlockLookingFor from '@/components/BlockLookingFor.vue'
import BlockProducts from '@/components/BlockProducts.vue'
import BlockEducations from '@/components/BlockEducations.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {useShare} from '@/hooks/useShare'
import {useRoute} from 'vue-router'
import store from '@/store/store'
import {createSendMessageByType} from '@/utils/createSendMessageByType'
import BlockContacts from '@/components/BlockContacts.vue'
import UserSpecializations from '@/components/UserSpecializations.vue'

const route = useRoute()
const {canShare, share} = useShare()

import {useWindowSize} from '@vueuse/core'
import {ct} from '../../locales/i18nextInit.js'
import {t} from 'i18next'

const {width, height} = useWindowSize()

const props = defineProps({
  userId: {
    type: String,
    required: true,
  },
  collectionId: {
    type: String,
  }
})

const state = reactive({
  isLoading: true,
  user: null,
  userContact: null,

  isSelectedProduct: null,
  // isContactsShown: false,
  isAddingContactShown: false,

  isDeleting: false,
})

// async function submitShare() {
//   const url = new URL(window.location.href)
//   url.pathname = route.fullPath
//   await share({
//     title: `${state.user.first_name} ${state.user.last_name}`,
//     description: state.user.about_me,
//     url: url.toString(),
//   })
// }

onBeforeMount(async () => {
  if (store.state.user.profile.id.toString() === props.userId.toString()) {
    await router.replace({name: 'Profile'})
    return
  }

  const [userRes, contactsRes] = await Promise.all([
    appAxios.user.fetchProfile(props.userId),
    appAxios.myContacts.fetchContact(props.userId)
  ])
  state.user = userRes.data
  state.userContact = contactsRes.data.contacts.find(contact => contact.contact_user.id === state.user.id)

  state.isLoading = false
})

async function evaluated(rate) {
  try {
    let userContact = null
    if (!state.userContact) {
      const res = await appAxios.myContacts.addContact(state.user.id, rate)
      userContact = res.data
    } else if (rate === 0) {
      state.isDeleting = true
      await appAxios.myContacts.removeContact(state.userContact.id, state.userContact.contact_user.id)
      state.isDeleting = false
    } else {
      const res = await appAxios.myContacts.updateContact(state.userContact.id, rate)
      userContact = res.data
    }
    state.userContact = userContact
    state.isAddingContactShown = false
  } catch (e) {
    console.error(e)
  }
}

async function applyForSkill(skill) {
  const user = state.user
  const uid = createSendMessageByType.lookingFor({user, skill})
  await router.push({
    name: route.meta.chatName ?? 'UserChat',
    params: {userId: user.id, sentUID: uid},
  })
}

async function applyForProduct(product) {
  const user = state.user
  const uid = createSendMessageByType.product({user, product})
  await router.push({
    name: route.meta.chatName ?? 'UserChat',
    params: {userId: user.id, sentUID: uid},
  })
}

async function startChat(userId) {
  await router.push({
    name: route.meta.chatName ?? 'UserChat',
    params: {userId},
  })
}

</script>

<style lang="scss" scoped>

.userData {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.userProfile {
  margin-bottom: 8px;
  margin-top: 12px;
}

//.contacts {
//  background: red;
//  display: flex;
//  flex-direction: column;
//  margin-bottom: 8px;
//
//  .contact {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    padding: 6px 0;
//    border-bottom: 1px solid #2B2B2B;
//  }
//
//  .contact:last-child {
//    border-bottom: none;
//  }
//
//  .test {
//    display: flex;
//    align-items: center;
//  }
//}

.productInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .imgWrapper {
    width: 100%;
    height: 116px;
    border-radius: 8px;
    background: var(--app-skeleton-color);
    display: flex;

    &.full {
      margin: -16px -16px 0 -16px;
      width: calc(100% + 32px);
    }

    .img {
      width: 100%;
      //height: 50px;
      flex-shrink: 0;
      border-radius: 8px 8px 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .bottom {
    display: flex;
    gap: 8px;
    padding-top: 16px;
    margin-top: 4px;
    border-top: 1px solid #494949;
    align-items: center;

    & > * {
      flex-shrink: 0;
    }

    &.drawer {
      margin: 0 -16px;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.share {
  margin-top: 20px;
  margin-bottom: 12px;
}

</style>
