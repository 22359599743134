<template>
  <div>
    <OnboardingEditEducation
      :education-id="state.educationId"
      :education-type="state.educationType"
      @close="validate(); state.educationId = null; validate();"
    />

    <Typography
      variant="h2"
      style="margin-bottom: 8px"
    >
      Образование
    </Typography>

    <Typography
      variant="body2"
      color="var(--text-notice-color)"
    >
      Информация о пройденном обучении: вузы, курсы, сообщества
    </Typography>

    <div
      v-if="education"
      class="educationList"
    >
      <template
        v-for="educationType in educationTypeArray"
      >
        <Card
          v-for="item in education[educationTypeToPlural(educationType)]"
          class="education"
        >
          <AppButton
            v-if="educationsCount > 1"
            variant="transparent"
            class="removeBtn"
            @click="setToDelete(item, educationType)"
          >
            <Icon
              :icon="require(`@/assets/icons/gray_16_trash.svg`)"
            />
          </AppButton>

          <Typography
            variant="text1"
            style="padding-right: 24px"
            color="var(--text-notice-color)"
          >
            {{ item.degree ? item.degree : getEducationName(educationType) }}
          </Typography>

          <Typography
            variant="subtitle1"
            class="educationTitle"
          >
            {{ item.title }}
          </Typography>

          <Typography
            v-if="item.ended_at"
            variant="body1"
            class="educationText"
          >
            Год окончания: {{ item.ended_at ? item.ended_at.slice(0, 4) : '' }}
          </Typography>

          <div
            v-if="item.images && item.images.length"
            class="images"
          >
            <template
              v-for="(i, index) in item.images"
            >
              <div
                v-fullscreen-image="{
                  panoramaCurrentIndex: index,
                  imageUrl: item.images.map(img => img.image),
                }"
                class="image"
                :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(i.image))}"
              />
            </template>
          </div>

          <appButton
            full-width
            variant="secondary"
            @click="editEducation(item.id, educationType)"
          >
            {{ ct('Action.Edit') }}
          </appButton>
        </Card>
      </template>
    </div>

    <AppButton
      v-if="newEducationAvailable"
      full-width
      variant="secondary"
      size="large"
      style="margin-top: 24px"
      @click="createAchievement"
    >
      {{ ct('Action.Add') }}
    </AppButton>

    <FormError
      v-show="errors.error"
      style="margin-top: 12px; margin-bottom: 0"
      name="error"
    />

    <div
      style="flex-grow: 1 "
    />

    <div
      id="bottom-support"
      style="padding: 0 16px; background: var(--background-main-color); border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <div
        class="main_container"
        style="padding: 0;"
      >
        <AppButton
          style="margin-top: 16px; margin-bottom: 12px"
          size="large"
          full-width
          :loading="isSubmitting"
          :disabled="isSubmitting"
          @click="onSubmit"
        >
          Продолжить
        </AppButton>

        <AppButton
          variant="secondary"
          style="margin-top: 8px; margin-bottom: 24px"
          size="large"
          full-width
          @click="() => props.nextStep()"
        >
          Пропустить
        </AppButton>
      </div>
    </div>

    <BottomDrawer
      :is-shown="state.isDelete"
      title="Удаление данных"
      @close="state.isDelete = null"
    >
      <Typography
        variant="body1"
        color="secondary"
      >
        Ты действительно хочешь удалить данные об образовании?
      </Typography>

      <div
        class="modalButtons"
      >
        <appButton
          variant="secondary"
          @click="state.isDelete = null"
        >
          Оставить
        </appButton>

        <appButton
          variant="danger"
          color="var(--text-error-color)"
          :disabled="state.isDeleting"
          :loading="state.isDeleting"
          @click="deleteEducation(state.isDelete)"
        >
          {{ ct('Action.Delete') }}
        </appButton>
      </div>
    </BottomDrawer>
  </div>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store.js'
import {computed, reactive} from 'vue'
import Card from '@/components/UI/Card.vue'
import Icon from '@/components/UI/Icon.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {appAxios} from '@/axios.js'
import FormError from '@/components/form/FormError.vue'
import {educationTypeArray, educationTypeToPlural, getEducationName} from '@/configs/educationType.js'
import {urlToCssURL} from '@/utils/urlToCssURL.js'
import {imagesLinkCache} from '@/utils/imagesLinkCache.js'
import OnboardingEditEducation from '@/views/profile/onboarding/OnboardingEditEducation.vue'
import {ct} from '../../../locales/i18nextInit.js'

const {handleSubmit, values, errors, isSubmitting, setFieldValue, setValues, setErrors, validate} = useForm({
  validationSchema: Yup.object({
    error: Yup.mixed()
      .test('required', 'Необходимо добавить образование', () => {
        const education = store.state.user.profile?.education?.at(0)
        return education && (education.courses.length || education.communities.length || education.institutions.length)
      })
  })
})

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
  createUserStepIfNotExist: {
    type: Function,
    require: true,
  },
})

const educationLimits = store.state.user.directories.education_type_limit

const education = computed(() => {
  return store.state.user.profile?.education?.at(0)
})

const newEducationAvailable = computed(() => {
  if (!education.value) {
    return true
  }
  return education.value.courses.length < educationLimits.course || education.value.communities.length < educationLimits.community || education.value.institutions.length < educationLimits.institution
})

const educationsCount = computed(() => {
  if (!education.value) {
    return 0
  }
  return education.value.courses.length + education.value.communities.length + education.value.institutions.length
})

const state = reactive({
  educationId: null,
  isDeleteId: null,
  isDeleting: false,
})

function createAchievement() {
  state.educationId = 'new'
  state.educationType = undefined
}

function editEducation(id, type) {
  state.educationId = id
  state.educationType = type
}

function setToDelete(item, educationType) {
  state.isDelete = {item, educationType}
}

async function deleteEducation({item, educationType}) {
  state.isDeleting = true
  try {
    const res = await appAxios.educations.remove(item, educationType)
    await store.commit('user/setProfile', {
      education: [res.data],
    })
    state.isDelete = null
  } catch (e) {
    console.log('e', e)
  }
  state.isDeleting = false
}


const onSubmit = handleSubmit(async () => {
  await props.nextStep()
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.wrapper {
  min-height: 100svh;
  max-height: 100svh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.info {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;

  & > .icon {
    flex-shrink: 0;
  }
}

.educationList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.education {
  margin-top: 16px;
  cursor: pointer;
  position: relative;
}

.educationTitle {
  margin-bottom: 16px;
  margin-top: 4px;
}

.educationText {
  margin-bottom: 16px;
  margin-top: 4px;
}

.removeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
  }
}

.images {
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;

  .image {
    width: 44px;
    height: 56px;
    border-radius: 5px;
    background-color: var(--app-skeleton-color);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

</style>
