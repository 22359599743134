<template>
  <div
    class="pageWrapper"
  >
    <head-new
      :back-action="() => router.push({name: 'MarketplaceCourses'})"
    />

    <div
      class="pageWrapperOverflow"
    >
      <div
        class="main_container"
        style="padding: 0; margin-top: 8px; margin-top: 8px; overflow: hidden"
      >
        <div
          class="main_container"
        >
          <swiper
            v-if="state.course?.landing_image_list?.length"
            :allow-slide-next="true"
            :allow-slide-prev="true"
            :slides-per-view="'auto'"
            :space-between="8"
            :free-mode="true"
            class="swiper"
          >
            <swiper-slide
              v-for="(item, index) in state.course.landing_image_list"
              class="slide"
              :class="{fullSize: state.course?.landing_image_list?.length === 1}"
            >
              <div
                v-if="store.state.app?.appData?.pageTitle === 'younesis'"
                class="backgroundWrapper"
              >
                <div
                  class="background"
                  :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(item.image))}"
                />
              </div>
              <div
                v-fullscreen-image="{
                  panoramaCurrentIndex: index,
                  imageUrl: state.course.landing_image_list.map(i => i.image),
                }"
                class="sliderImage"
                :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(item.image))}"
              />
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div
        v-if="state.course?.lessons_count || state.course?.estimated_time_to_pass"
        class="main_container"
        style="display: flex; gap: 8px; margin-top: 20px"
      >
        <Card
          v-if="state.course.lessons_count"
          style="padding: 8px;background: var(--card-light-background); border-radius: 16px; display: flex; align-items: center;"
        >
          <Icon
            class="icon"
            width="20"
            height="20"
            :icon="require(`@/assets/icons/white_20_training.svg`)"
            style="margin-right: 6px"
          />
          {{ state.course.lessons_count }} {{ declination("уроков", "урок", "урока", state.course.lessons_count) }}
        </Card>
        <Card
          v-if="state.course.estimated_time_to_pass"
          style="padding: 8px;background: var(--card-light-background); border-radius: 16px; display: flex; align-items: center;"
        >
          <Icon
            class="icon"
            width="20"
            height="20"
            :icon="require(`@/assets/icons/white_20_clock.svg`)"
            style="margin-right: 6px"
          />
          {{ state.course.estimated_time_to_pass }}
        </Card>
      </div>

      <div
        v-if="!state.isLoading"
        class="main_container"
        style="margin-top: 20px"
      >
        <Typography
          variant="h1"
          style="margin-bottom: 8px"
        >
          {{ state.course.title }}
        </Typography>

        <Typography
          variant="subtitle3"
          color="var(--text-notice-color)"
          style="margin-bottom: 32px"
        >
          {{ state.course.description }}
        </Typography>

        <AppButton
          v-if="!state.isSubscriptionActive"
          full-width
          size="large"
          @click="openSubscription"
        >
          Получить доступ
        </AppButton>
      </div>

      <div
        v-if="state.course?.landing_item_list?.length"
        style="margin-top: 24px"
      >
        <div
          class="main_container"
          style="margin-bottom: 24px"
        >
          <typography
            variant="title"
          >
            На курсе ты узнаешь:
          </typography>
        </div>

        <div
          class="main_container"
          style="padding: 0; margin-top: 8px; margin-top: 8px; overflow: hidden"
        >
          <div
            class="main_container"
          >
            <swiper
              v-if="state.course?.landing_item_list?.length"
              :allow-slide-next="true"
              :allow-slide-prev="true"
              :slides-per-view="'auto'"
              :space-between="8"
              :free-mode="true"
              class="swiper"
            >
              <swiper-slide
                v-for="(item, index) in state.course.landing_item_list"
                class="slide"
                style="padding: 16px"
              >
                <div
                  class="redCircle"
                  style="margin-bottom: 12px"
                >
                  {{ index + 1 }}
                </div>

                <typography
                  variant="subtitle"
                  style="margin-bottom: 8px"
                  one-line
                >
                  {{ item.title }}
                </typography>

                <typography
                  variant="body2"
                  class="desc"
                >
                  {{ item.description }}
                </typography>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>

      <div
        style="margin-top: 32px"
      />
    </div>
  </div>
</template>

<script setup>
import {router} from '@/router/router'
import {onBeforeMount, reactive} from 'vue'
import HeadNew from '@/components/Head.vue'
import Typography from '@/components/UI/Typography.vue'
import Card from '@/components/UI/Card.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {useRoute} from 'vue-router'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import {appAxios} from '@/axios'
import {Swiper, SwiperSlide} from "swiper/vue";
import Icon from "@/components/UI/Icon.vue";
import {declination} from "@/utils/declination.js";
import BottomDrawer from "@/components/BottomDrawer.vue";
import store from "@/store/store.js";
import {subscriptionStatuses} from "@/configs/subscriptionStatuses.js"
import BuySubscription from "@/views/subscriptions/BuySubscription.vue";

const route = useRoute()

const rubFormat = new Intl.NumberFormat('ru-RU', {
  // style: 'currency',
  // maximumSignificantDigits: 2,
  // minimumFractionDigits : 2,
})

const props = defineProps({
  courseId: {
    type: String,
  },
})

const state = reactive({
  isLoading: true,
  course: null,
  isSubscriptionLoading: false,
  isSubscriptionActive: false,
  selectedCourse: null,
})

onBeforeMount(async () => {
  try {
    const res = await appAxios.course.fetchOne(props.courseId)
    state.course = res.data

    state.subscription = store.state.user.directories.subscription_price_list[0]
    const userSubscription = store.state.user.profile?.subscriptions[0]
    if (userSubscription && userSubscription.status === subscriptionStatuses.active) {
      state.isSubscriptionActive = true
    }
  } catch (e) {
    console.log('error', e)
  } finally {
    state.isLoading = false
  }
})

async function openSubscription() {
  sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
    returnName: 'MarketplaceCourseLanding',
    params: {courseId: state.course.id}
  }))
  await router.push({
    name: 'SubscriptionTariffSelection',
  })
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  //min-height: calc(100svh);
  //max-height: calc(100svh);
  //display: flex;
  //flex-direction: column;
  //overflow: hidden;
  margin-bottom: -114px;
}

.pageWrapperOverflow {
  overflow-y: auto;
  flex-grow: 1;
  //margin-bottom: $bottomMenuOffset;
}

.image {
  width: 240px;
  height: 240px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.swiper {
  overflow: visible;
  height: 184px;
}

.slide {
  width: calc(70%);
  min-width: 290px;
  background: var(--card-light-background);
  border-radius: 24px;

  &.fullSize {
    width: 100%;
  }
}

.sliderImage {
  height: 160px;
  margin: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: relative;
  z-index: 2;
}

.accordionList {
  & > * {
    border-bottom: 1px solid #494949;
    padding-bottom: 16px;
    padding-top: 16px;
  }

  & > *:first-child {
    padding-top: 0;
  }

  & > *:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.questionList {
  & > * {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  & > *:first-child {
    padding-top: 0;
  }

  & > *:last-child {
    padding-bottom: 0;
  }
}

ul {
  list-style: none;
}

ul li {
  position: relative;
  margin-left: 16px;
}

ul li:before {
  position: absolute;
  top: 8px;
  left: -11px;
  vertical-align: middle;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #ccc;
  content: "";
}

ul.red li:before {
  background: var(--app-accent-light-color);
  width: 6px;
  height: 6px;
  top: 8px;
  left: -14px;
  border-radius: 6px;
}

ol {
  list-style: none;
  padding-left: 18px;
}

ol li {
  position: relative;
  margin-left: 8px;
  counter-increment: step-counter;
  margin-bottom: 8px;
}

ol li:before {
  content: counter(step-counter) '.';
  position: absolute;
  left: -24px;
  //vertical-align: middle;
  margin-right: 8px;
}

ul.checks {
  list-style: none;
}

ul.checks li {
  position: relative;
  margin-left: 28px;
  margin-bottom: 24px;
}

ul.checks li:before {
  position: absolute;
  top: -2px;
  left: -30px;
  vertical-align: middle;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("@/assets/icons/_images/24_redCheck.svg");
  background-color: transparent;
  content: "";
}

.redCircle {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--app-accent-light-color);
  color: var(--text-primary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.redBlob {
  background: var(--app-accent-light-color);
  color: var(--text-primary-color);
  padding: 4px 12px 4px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  display: inline-block;
}

.person {
  height: 228px;
  margin: -16px -16px 16px -16px;
  background-color: #141313;
  border-radius: 16px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.desc {
  //line-clamp: 5;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.backgroundWrapper {
  position: absolute;
  border-radius: 20px;
  z-index: 1;
  top: 0;
  left: 0;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  display: flex;
  overflow: hidden;

  .background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    //filter: blur(20px) contrast(100%);
    filter: blur(20px) contrast(150%);
    //filter: contrast(30%) brightness(150%);
    transform: translate3d(0, 0, 0);
    //opacity: .12;
    flex-grow: 1;
  }
}

</style>
