<template>
  <div
    class="card_holder"
  >
    <head-new
      page_title="Найти своего человека"
      :back-action="() => $router.push({name: 'SocialBrowsing'})"
      :right-action="targetIsVisible || state.isLoading ? null : openSearch"
      :right-icon="icons.search"
    />

    <bottom-drawer
      v-if="!state.isLoading"
      :is-shown="state.userContactShown"
      :title="t('cardholder:Action.Delete')"
      @close="state.userContactShown = false"
    >
      <template
        v-if="state.userContactShown"
      >
        <Typography
          variant="v14-600"
          color="secondary"
        >
          {{
            t('cardholder:RemovePersonWarning', {
              person: `${state.userContactShown.contact_user.first_name } ${state.userContactShown.contact_user.last_name}`
            })
          }}
        </Typography>

        <div
          style="display: flex; gap: 4px; margin: 16px 0"
        >
          <appButton
            full-width
            class="submitButton"
            variant="secondary"
            :disabled="state.isDeleting"
            @click="state.userContactShown = false"
          >
            {{ ct('Action.Cancel') }}
          </appButton>
          <appButton
            full-width
            class="submitButton"
            variant="secondary"
            color="var(--text-error-color)"
            :disabled="state.isDeleting"
            :loading="state.isDeleting"
            @click="() => evaluated(0, state.userContactShown)"
          >
            {{ ct('Action.Delete') }}
          </appButton>
        </div>
      </template>
    </bottom-drawer>

    <RecycleScroller
      v-if="state.contactList.length"
      class="main_container holder_in"
      :items="state.contactList"
      :item-size="264"
      key-field="indexKey"
      :buffer="264"
    >
      <template
        #before
      >
        <div
          ref="refSearch"
          class="search"
        >
          <FormTextInputSearch
            left-icon="`@/assets/icons/gray_24_search.svg`"
            name="searchQuery"
            placeholder="Ключевые слова"
            full-width
            only-input
            @focus="openSearch"
            @options="openSearch({filter: true})"
          />
          <!--          :iosTelegramFix="{top: '30'}"-->
        </div>
      </template>

      <template
        #after
      >
        <div
          style="height: 16px"
        />
      </template>

      <template
        #default="{item}"
      >
        <UserCard
          :data="item.user ?? item.contact_user"
          :user-contact="item"
          style="margin-bottom: 4px"
          :profile-click="() => {
            if(item.user) {
              router.push({name: 'BrowsingUserProfile', params: {userId: item.user.id}})
            } else {
              router.push({name: 'BrowsingUserProfile', params: {userId: item.contact_user.id}})
            }
          }"
          @re-evaluate="contact => state.userContactShown = contact"
          @add-contact="addToContact"
        />
      </template>
    </RecycleScroller>

    <div
      class="main_container holder_in"
    >
      <template
        v-if="!state.isLoading"
      >
        <div
          v-if="state.contactList.length === 0"
          class="listIsEmpty"
        >
          <div
            class="userEmptyCards"
          >
            <div
              class="userFakeCard"
            />
            <div
              class="userFakeCard"
            />
            <UserCard
              :is-loading="true"
              :data="null"
              hide-actions
            />
          </div>

          <Typography
            variant="subtitle"
            center
            style="padding: 0 12px"
          >
            Визитница пуста
          </Typography>

          <Typography
            variant="body1"
            center
            color="secondary"
            style="padding: 0 12px"
          >
            Начинай нетворкинг, добавляй пользователей и создавай окружение своих людей!
          </Typography>

          <AppButton
            style="margin-top: 24px"
            @click="router.push({name: 'CardHolderSocialBrowsing'})"
          >
            Нетворкать
          </AppButton>
        </div>
      </template>

      <template
        v-if="state.isLoading"
      >
        <div
          class="search"
        >
          <Skeleton
            height="48"
            radius="12"
            full-width
          />
        </div>
        <div
          style="display: flex; flex-direction: column; gap: 16px;"
        >
          <UserCard
            v-for="i in 5"
            v-if="state.isLoading"
            :data="{}"
            :is-loading="true"
            :user-contact="true"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {computed, onBeforeMount, reactive, ref, toRaw} from 'vue'
import {appAxios} from '@/axios'
import {router} from '@/router/router'
import UserCard from '@/components/UserCard.vue'
import Evalution from '@/components/Evaluation.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {useElementVisibility} from '@vueuse/core'
import FormTextInputSearch from "@/components/form/FormTextInputSearch.vue"
import Skeleton from "@/components/UI/Skeleton.vue"
import {ct} from '../../locales/i18nextInit.js'
import {t} from 'i18next'
import {icons} from '@/assets/icon/icons.js'

const refSearch = ref(null)
const targetIsVisible = useElementVisibility(refSearch)

const state = reactive({
  isLoading: true,
  userContactShown: false,

  user: null,
  contactList: [],

  isDeleting: false,
})

function close() {
  // router.replace({name: 'CardHolder'})
}

onBeforeMount(async () => {
  try {
    const res = await appAxios.user.searchPaginated({}, 10, 0)
    res.data.results.forEach((item, i) => item.indexKey = i)
    state.contactList = res.data.results
  } catch (e) {
    console.log('error', e)
  } finally {
    state.isLoading = false
  }
})

function openSearch(params = {}) {
  const {filter} = params
  router.push({name: 'CardHolderSearch', params: {filter}})
}

async function evaluated(rate, userContact) {
  if (rate === 0) {
    state.isDeleting = true

    await appAxios.myContacts.removeContact(userContact.id, userContact.contact_user.id)
    const found1 = state.contactList.find(c => c.id === userContact.id)

    Object.assign(found1, {
      user: found1.contact_user ?? found1.user,
      id: null,
      user_id: null,
      rating: null,
      contact_user: null,
      isLoading: false,
    })

    state.isDeleting = false
  }
  state.userContactShown = false
}


async function addToContact(user) {
  const found1 = state.contactList.find(uc => uc.user?.id === user.id || uc.contact_user?.id === user.id)
  found1.isLoading = true
  const res = await appAxios.myContacts.addContact(user.id, 5)

  Object.assign(found1, {
    user: null,
    ...res.data,
  })

  found1.isLoading = false
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.card_holder {
  margin-bottom: -$homeLayoutPaddingBottom;
  min-height: calc(100svh - $bottomMenuOffset);
  max-height: calc(100svh - $bottomMenuOffset);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.holder_modal_active {
  opacity: 1;
  z-index: 101;

  .holder_modal_in {
    transform: translateY(0);
  }
}

.listIsEmpty {
  margin-top: 96px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 60px;
}

.userFakeCard {
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #FFFFFF14;
  background: var(--card-secondary-background);
  height: 220px;
}

.userEmptyCards {
  margin-bottom: 32px;
  position: relative;

  & > *:nth-child(1) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -48px;
    transform: scale(0.8);
    z-index: 1;
    //background: #1B1B1B;
    background: var(--card-secondary-background);
    filter: brightness(90%);
  }

  & > *:nth-child(2) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -24px;
    transform: scale(0.9);
    z-index: 2;
    //background: #1F1F1F;
    background: var(--card-secondary-background);
    filter: brightness(95%);
  }

  & > *:nth-child(3) {
    position: relative;
    z-index: 3;
  }
}

.search {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
}

</style>
