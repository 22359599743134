<template>
  <div
    class="pageWrapperOverflow"
  >
    <RecycleScroller
      v-if="!isLoading && state.products.length"
      class="list main_container"
      :items="state.products"
      :item-size="152 + 12 + (((Math.min(width, 480) - 32) / 1.8)|0)"
      :buffer="(152 + 12) * 4 + (((Math.min(width, 480) - 32) / 1.8)|0)"
      key-field="id"
      @scroll-end="additionalLoading"
    >
      <template
        #before
      >
        <BlockSubscription
          :show-at-statuses="{[subscriptionStatuses.new]: true, [subscriptionStatuses.pending]: true}"
          :icon-size="40"
          new-subscription-title="Загружай свои продукты с подпиской Younesis Premium"
          new-subscription-text="Продвигай бесплатные продукты, находи клиентов и становись популярным экспертом на платформе"
          new-subscription-button="Подключить подписку"
          :new-subscription-action="openSubscription"
          pending-subscription-title="Загружай свои продукты с подпиской Younesis Premium"
          pending-subscription-text="Продвигай бесплатные продукты, находи клиентов и становись популярным экспертом на платформе"
          pending-subscription-button="Подключить подписку"
          :pending-subscription-action="openSubscription"
        />
        <div
          v-if="!(state.subscriptionStatus in {[subscriptionStatuses.new]: true, [subscriptionStatuses.pending]: true})"
          style="height: 24px"
        />
      </template>
      <template
        #after
      >
        <div
          style="height: 24px"
        />
      </template>
      
      <template
        #default="{item: product, index, active}"
      >
        <div
          style="padding-bottom: 12px"
        >
          <div
            v-if="product.id > 0"
            class="product"
          >
            <div
              class="productInfo"
              style="cursor: pointer;"
              @click="state.isSelectedProduct = product"
            >
              <div
                class="imgWrapper"
                :style="{height: `${((Math.min(width, 480) - 32) / 1.8)|0}px`}"
              >
                <div
                  v-if="product.image"
                  class="img"
                  :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(product.preview_image))}"
                />
                <div
                  v-else
                  class="img"
                  :style="{backgroundImage: urlToCssURL(require(`@/assets/images/product.png`))}"
                />
              </div>

              <div
                style="overflow: hidden; flex-grow: 1; display: flex; flex-direction: column"
              >
                <Typography
                  variant="v16-600"
                  style="
                        white-space: pre-wrap;
                        overflow: hidden;
                        display: block;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        text-overflow: ellipsis;
                        height: 60px;
                      "
                >
                  {{ product.title }}
                </Typography>
              </div>

              <div
                class="bottom"
              >
                <Avatar
                  size="nano"
                  :image="product.created_by?.preview_avatar"
                />
                <div
                  style="flex-shrink: 1"
                >
                  <Typography
                    base="span"
                    variant="body1"
                    color="secondary"
                  >
                    {{ product.created_by.first_name }}
                  </Typography>
                  {{ ' ' }}
                  <Typography
                    base="span"
                    variant="body1"
                    color="secondary"
                  >
                    {{ product.created_by.last_name }}
                  </Typography>
                </div>
                <div
                  style="flex-grow: 1"
                />

                <AppButton
                  v-if="store.state.user.profile.id !== product.created_by.id"
                  size="small"
                  @click="state.isSelectedProduct = product"
                >
                  {{ t('marketplace:Action.ReadMore') }}
                </AppButton>
              </div>
            </div>
          </div>
          <div
            v-else
            class="product"
          >
            <div
              class="productInfo"
            >
              <Skeleton
                height="116"
                radius="8"
                full-width
              />

              <div>
                <Skeleton
                  width="180"
                  height="19"
                  :style="{marginBottom: '8px'}"
                />
                <Skeleton
                  variant="text"
                  width="260"
                  height="12"
                  style="margin-right: 12px"
                />
                <Skeleton
                  variant="text"
                  width="180"
                  height="12"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </RecycleScroller>
    <div
      v-if="!state.isLoading && state.isFilterError"
      class="main_container holder_in"
    >
      <div
        class="listIsEmpty"
      >
        <div
          class="imgNotFound"
        />
        <Typography
          variant="body1"
          center
          color="secondary"
          style="padding: 0 12px"
        >
          <template
            v-if="state.isFilterError === 'less'"
          >
            Мало символов для поиска, набери хотя бы 3
          </template>
          <template
            v-else-if="state.isFilterError === 'more'"
          >
            Слишком много символов для поиска, введи не более 50
          </template>
          <template
            v-else-if="state.isFilterError === 'empty'"
          >
            По твоему {{ state.searchQuery ? `«${state.searchQuery}»` : null }}
            запросу ничего нет. Напиши по-другому
          </template>
        </Typography>
      </div>
    </div>

    <BottomDrawer
      :is-shown="state.isSelectedProduct"
      close-button-outside
      @close="state.isSelectedProduct = null"
    >
      <div
        v-if="state.isSelectedProduct"
        class="productInfo"
      >
        <div
          v-if="state.isSelectedProduct.preview_image"
          class="imgWrapper full"
          :style="{height: `${((Math.min(width, 480)) / 1.8)|0}px`}"
        >
          <div
            class="img"
            :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.isSelectedProduct.preview_image))}"
          />
        </div>

        <div>
          <div
            class="title"
          >
            {{ state.isSelectedProduct.title }}
          </div>
          <Typography
            variant="body1"
            style="white-space: pre-wrap;"
          >
            {{ state.isSelectedProduct.description }}
          </Typography>
        </div>

        <div
          class="bottom drawer"
        >
          <Avatar
            size="tiny"
            :image="state.isSelectedProduct.created_by?.preview_avatar"
            @click="$emit('openUser',state.isSelectedProduct.created_by)"
          />
          <div
            style="flex-grow: 1; flex-shrink: 1;"
            @click="$emit('openUser',state.isSelectedProduct.created_by)"
          >
            <Typography
              base="span"
              variant="body1"
              color="secondary"
            >
              {{ state.isSelectedProduct.created_by.first_name }}
            </Typography>
            {{ ' ' }}
            <Typography
              base="span"
              variant="body1"
              color="secondary"
            >
              {{ state.isSelectedProduct.created_by.last_name }}
            </Typography>
          </div>

          <AppButton
            v-if="store.state.user.profile.id !== state.isSelectedProduct.created_by.id"
            size="small"
            @click="applyForProduct(state.isSelectedProduct.created_by, state.isSelectedProduct)"
          >
            {{ t('marketplace:Action.GetProduct') }}
          </AppButton>
        </div>
      </div>
    </BottomDrawer>
  </div>
</template>

<script setup>
import {reactive, watchEffect, watch} from 'vue'
import {appAxios} from '@/axios'
import store from "@/store/store.js"
import {router} from '@/router/router'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import Skeleton from '@/components/UI/Skeleton.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Typography from '@/components/UI/Typography.vue'
import Avatar from '@/components/profile/Avatar.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import BlockSubscription from '@/views/subscriptions/BlockSubscription.vue'
import {useRoute} from 'vue-router'
import {createSendMessageByType} from '@/utils/createSendMessageByType'
import {useWindowSize} from '@vueuse/core'
import {t} from 'i18next'
import {subscriptionStatuses} from '@/configs/subscriptionStatuses.js'
import {loadingCount} from '@/configs/productsStatus.js'

const route = useRoute()

const props = defineProps({
  products: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: true,
  },
  count: {
    type: Number,
    default: 0,
  },
  isSelectedProduct: {
    type: [Object, null],
    default: null,
  },
  searchQuery: {
    type: String,
    default: '',
  },
  isFilterError: {
    type: String,
    default: '',
  }

})

const {width, height} = useWindowSize()

const state = reactive({
  products: [],
  isSelectedProduct: null,
  count: 0,
  searchQuery: '',
  isAdditionalLoading: false,
  isAdditionalLoadingNeedMore: false,

  subscriptionStatus: store.state.user.profile.subscriptions?.at(0)?.status ?? subscriptionStatuses.new
})

watchEffect(() => {
  state.products = props.products
  state.count = props.count
  state.isSelectedProduct = props.isSelectedProduct
  state.isFilterError = props.isFilterError
  state.searchQuery = props.searchQuery  
})

watch(() => store.state.user.profile.subscriptions, subscriptions => {
  state.subscriptionStatus = subscriptions.at(0)?.status ?? subscriptionStatuses.new
})

async function additionalLoading() {
  if (state.count > state.products.length) {
    if (state.isAdditionalLoading) {
      state.isAdditionalLoadingNeedMore = true
      return
    }
    state.isAdditionalLoading = true

    state.products.push(...Array(loadingCount).fill(0).map((_, index) => ({
      id: -index - 1,
    })))
    const params = {
      limit: loadingCount,
      offset: state.products.length - loadingCount,
    }
    const res = await appAxios.products.fetch(params)
    state.products = [...state.products.filter(i => i.id >= 0), ...res.data.results]

    state.isAdditionalLoading = false
    if (state.isAdditionalLoadingNeedMore) {
      state.isAdditionalLoadingNeedMore = false
      additionalLoading()
    }
  }
}

async function applyForProduct(user, product) {
  if (!store.getters["user/profileProgress"].isEnoughFilled) {
    store.dispatch('app/setMessageComponent', {
      messageComponent: 'EnoughFilledRequired',
      messageComponentData: {text: 'Чтобы получить доступ к этой функции'},
    })
    return
  }
  const uid = createSendMessageByType.product({user, product})
  await router.push({
    name: route.meta.chatName ?? 'UserChat',
    params: {userId: user.id, sentUID: uid},
  })
}

async function openSubscription() {
  sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
    returnName: 'MarketplaceProducts',
  }))
  await router.push({
    name: 'SubscriptionTariffSelection',
  })
}
</script>

<style lang="scss" scoped>

.pageWrapperOverflow {
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.productsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px !important;
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: 34px;
}

.list {
  height: calc(100vh - 56px - 80px);
}

.product {
  background: var(--background-paper-color);
  padding: 12px;
  border-radius: 16px;
}

.productInfo {
  display: flex;
  flex-direction: column;
  gap: 11px;

  .imgWrapper {
    margin: -12px -12px 0 -12px;
    width: calc(100% + 24px);
    border-radius: 8px 8px 0 0;
    background: #3B3B3B;
    display: flex;

    &.full {
      margin: -16px -16px 0 -16px;
      width: calc(100% + 32px);
    }

    .img {
      width: 100%;
      flex-shrink: 0;
      border-radius: 8px 8px 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.bottom {
  display: flex;
  gap: 8px;
  padding-top: 16px;
  margin-top: 4px;
  border-top: 1px solid #494949;
  align-items: center;

  & > * {
    flex-shrink: 0;
  }

  &.drawer {
    margin: 0 -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.descriptionContainer {
  overflow: hidden;
}

.description {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listIsEmpty {
  margin-top: 96px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 60px;
  padding: 0 32px;
  color: var(--text-primary-color);
  text-align: center;
}

.imgNotFound {
  background: url("@/assets/images/search/notFound.png") no-repeat center center;
  width: 180px;
  height: 180px;
  margin-bottom: 12px;
}
</style>
