<template>
  <div
    class="backdrop"
    :class="{younesis: store.state.app?.appData?.pageTitle === 'younesis'}"
  >
    <div
      class="logo"
    />
  </div>
</template>

<script>
import store from '@/store/store.js'
import {base64ToCssURL, urlToCssURL} from '@/utils/urlToCssURL.js'

export default {
  name: "Logo",
  computed: {
    store() {
      return store
    }
  },
  methods: {urlToCssURL},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.backdrop {
  display: inline-flex;
  width: 100%;
  height: 120px;
  align-items: center;
  justify-content: center;
  position: relative;
  &.younesis:after {
    content: "";
    width: 100%;
    height: 239px;
    filter: blur(100px);
    transform: translate3d(0, 0, 0);
    background: rgb(207, 46, 46);
    opacity: 0.08;
    position: absolute;
    top: -119px;
    left: 0;
  }
  .logo {
    display: block;
    height: var(--logo-home-height, 58px);
    width: var(--logo-home-width, 128px);
    background-image: var(--logo-home-src);

    position: relative;
    background-repeat: no-repeat;
    //background: url("@/assets/images/logo/white_logo_leadpay_128_58.svg") no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

img {
  user-select: none;
}
</style>
