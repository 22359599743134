<template>
  <div
    class="headerIcon"
  >
    <div
      class="innerIcon"
      :class="{active}"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.5333 21.9999H5.4665C4.0966 21.9999 2.94957 20.9099 2.81315 19.4787L2.01314 11.0787C1.93811 10.2915 2.18372 9.50777 2.68926 8.92118C3.19479 8.33458 3.91306 7.99988 4.6665 7.99988H19.3333C20.0867 7.99988 20.805 8.33458 21.3105 8.92118C21.816 9.50777 22.0616 10.2915 21.9866 11.0787L21.1866 19.4787C21.0502 20.9099 19.9032 21.9999 18.5333 21.9999V21.9999Z"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.99988 7.49988L3.99988 6.99988C3.99973 6.4694 4.2104 5.96061 4.5855 5.5855C4.96061 5.2104 5.4694 4.99973 5.99988 4.99988H17.9999C18.5304 4.99973 19.0391 5.2104 19.4143 5.5855C19.7894 5.96061 20 6.4694 19.9999 6.99988V7.49988"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.73376 4.99988V3.99988C5.73362 3.4694 5.94429 2.96061 6.31939 2.5855C6.69449 2.2104 7.20329 1.99973 7.73376 1.99988H16.2678C16.7982 1.99973 17.307 2.2104 17.6821 2.5855C18.0572 2.96061 18.2679 3.4694 18.2678 3.99988V4.99988"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0012 10.9999C13.1062 10.9999 14.002 11.8957 14.002 13.0007C14.002 14.1057 13.1062 15.0015 12.0012 15.0015C10.8962 15.0015 10.0004 14.1057 10.0004 13.0007C10.0004 11.8957 10.8962 10.9999 12.0012 10.9999"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.0032 18.8334C15.8869 18.5407 15.7064 18.2778 15.475 18.0641V18.0641C15.0851 17.7017 14.5727 17.5002 14.0404 17.4999H9.9637C9.43114 17.5002 8.91837 17.7018 8.5281 18.0641V18.0641C8.29724 18.2783 8.11683 18.5411 7.99988 18.8334"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div
        v-if="messages"
        class="messages"
      >
        {{ messages }}
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed} from "vue";
import store from "@/store/store.js";

const messages = computed(() => {
  const count = store.state.user?.profile?.unchecked_interest_count + store.state.user?.profile?.unchecked_match_count
  if (count) {
    const num = Number(count)
    if (num > 99) {
      return `99+`
    }
    return num
  }
  return null
})

const props = defineProps({
  color: {
    type: String,
    default: '#ffffff' // #8B8B8B
  },
  active: {
    type: Boolean,
    default: false,
  },
})

</script>

<style lang="scss" scoped>
.headerIcon {
  width: 24px;
  height: 24px;
  position: relative;
}

.innerIcon {
  position: absolute;
  background: rgba(0, 0, 0, 0);
  width: 48px;
  height: 48px;
  left: -12px;
  top: -12px;
  padding: 10px;
  border: 2px solid rgba(231, 94, 94, 0);
  border-radius: 12px;
  box-shadow: 0 0 16px 0 rgba(231, 94, 94, 0);
  transition: all 1s linear;

  &.active {
    background: var(--app-accent-light-color);
    width: 48px;
    height: 48px;
    left: -12px;
    top: -24px;
    padding: 10px;
    border: 2px solid rgba(231, 94, 94, 0.24);
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(231, 94, 94, 0.3);
  }
}

.messages {
  position: absolute;
  background: var(--text-error-color);
  height: 15px;
  min-width: 15px;
  top: 5px;
  right: 5px;
  padding: 1px 4px;
  border-radius: 15px;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: var(--text-primary-color);
}

</style>
