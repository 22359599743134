<template>
  <head-new
    title="Возникли вопросы"
    :back-action="back"
  />
  <div
    v-if="!state.isLoading"
    class="main_container questions"
  >
    <Card
      v-for="item in state.questions"
    >
      <div
        class="question"
        @click="item.isOpen = !item.isOpen"
      >
        <div>
          {{ item.question }}
        </div>
        <div
          class="icon"
          :class="{active: item.isOpen}"
        />
      </div>
      <div
        class="answer"
        :style="{height: item.isOpen ? 'auto' : '0'}"
      >
        <div
          style="height: 12px;"
        />
        {{ item.answer }}
      </div>
    </Card>
  </div>

  <div
    v-if="!state.isLoading"
    class="main_container"
  >
    <div
      class="title"
    >
      Остались вопросы?
    </div>
    <div
      class="text"
    >
      Задай их в чате поддержки платформы
    </div>

    <AppButton
      full-width
      size="large"
      @click="() => {
        router.push({
          name: route.meta.chatName ?? 'UserChat',
          params: {userId: store.state.user.directories.support_chat_user.id},
        })
      }"
    >
      Написать в чат
    </AppButton>
  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import store from '@/store/store'
import {useRoute} from 'vue-router'

const route = useRoute()

const props = defineProps({
  userCourseId: {
    type: String,
  },
  moduleId: {
    type: String,
  },
  lessonId: {
    type: String,
  },
})

const state = reactive({
  isLoading: true,
  userCourse: null,
  course: null,

  questions: [],
})

onBeforeMount(async () => {
  const res = await appAxios.myCourses.fetchOne(props.userCourseId)
  const resCourse = await appAxios.course.cacheOne(res.data.course_id)

  state.userCourse = res.data
  state.course = resCourse.data
  state.questions = [
    ...state.course.course_questions.map(item => {
      return {
        isOpen: false,
        answer: item.answer,
        question: item.question,
      }
    }),
    ...store.state.user.directories.course_question_list.map(item => {
      return {
        isOpen: false,
        answer: item.answer,
        question: item.question,
      }
    }),
  ]
  state.isLoading = false

})

function back() {
  router.push({
    name: props.moduleId ? 'StudyModule' : 'StudyCourse',
    params: {
      userCourseId: props.userCourseId,
      moduleId: props.moduleId,
    },
  })
}
</script>

<style lang="scss" scoped>

.questions {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background: url("@/assets/icons/white_24_arrowDown.svg") no-repeat;

  &.active {
    transform: rotate(180deg);
  }
}

.question {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.answer {
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-notice-color);
  white-space: break-spaces;
}

.title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: #D3D2D2;
  margin-bottom: 24px;
}
</style>
