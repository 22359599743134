<template>
  <div
    class="pageWrapper"
  >
    <head-new
      :back-action="back"
      no-space
    >
      <FormTextInputSearch
        search-ref="search"
        name="searchQuery"
        placeholder="Поиск по заголовку и имени"
        full-width
        only-input
        :is-filters="true"
        style="margin-left: 12px"
        @submit="initialSearch"
        @blur="initialSearch"
      />
    </head-new>
    <div
      v-if="state.products.length"
      class="information-text"
    >
      Найдено: {{ state.products.length }} 
      {{ declination('продуктов', 'продукт', 'продукта', state.products.length) }}
    </div>

    <AnotherUserProfileModalLoading
      :user="state.selectedUser"
      style="position: relative;z-index: 1002"
      @close="() => {state.selectedUser = null}"
    />

    <template
      v-if="state.isSearched"
    >
      <MarketplaceProductsList
        v-if="!state.isLoading"
        :products="state.products"
        :is-loading="state.isLoading"
        :count="state.count"
        :is-selected-product="state.isSelectedProduct"
        :search-query="state.lastSearch"
        :is-filter-error="state.isFilterError"
        @open-user="openUser"
      />
    </template>
  </div>
</template>

<script setup>
import {nextTick, onMounted, reactive, ref, watch} from 'vue'
import HeadNew from '@/components/Head.vue'
import FormTextInputSearch from '@/components/form/FormTextInputSearch.vue'
import {router} from '@/router/router'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import {appAxios} from '@/axios'
import {subscriptionStatuses} from '@/configs/subscriptionStatuses.js'
import AnotherUserProfileModalLoading from "@/views/profile/AnotherUserProfileModalLoading.vue"
import MarketplaceProductsList from './MarketplaceProductsList.vue'
import store from "@/store/store.js"
import {declination} from '@/utils/declination.js'

const props = defineProps({
  productId: {
    type: String,
  }
})

const state = reactive({
  isLoading: true,
  isSearched: false,
  isFilterError: '',
  products: [],
  filters: {
    searchQuery: '',
  },

  isSelectedProduct: null,
  selectedUser: null,
  count: 0,
  subscriptionStatus: store.state.user.profile.subscriptions?.at(0)?.status ?? subscriptionStatuses.new

})

const {values} = useForm({
  validationSchema: Yup.object({}),
})

watch(values, value => {
  state.filters.searchQuery = value.searchQuery
})

onMounted(() => {
  state.isLoading = true
  nextTick(() => {
    const el = document.querySelector('#searchQuery')
    if (el) {
      el.focus()
    }
  });
})

function back() {
  router.push({name: 'MarketplaceProducts'})
}

async function initialSearch() {
  state.isLoading = true
  state.isFilterError = ''
  state.products = []

  const searchQuery = state.filters.searchQuery
  state.lastSearch = searchQuery

  if (searchQuery.length <= 2) {
    state.isSearched = true
    state.isFilterError = "less"
    state.isLoading = false
    return
  }

  if (searchQuery.length > 50) {
    state.isSearched = true
    state.isFilterError = "more"
    state.isLoading = false
    return
  }

  state.isSearched = true
  const params = {
    limit: 10,
    offset: 0,
    search: searchQuery
  }
  try {
    const res = await appAxios.products.fetch(params)
    const products = res.data.results;
    state.products = products
    if (!products.length) {
      state.isFilterError = 'empty'
    }
    state.isLoading = false
  } catch (e) {
    console.log('error', e)
  }
}

function openUser(user) {
  if (!store.getters["user/profileProgress"].isEnoughFilled) {
    store.dispatch('app/setMessageComponent', {
      messageComponent: 'EnoughFilledRequired',
      messageComponentData: {text: 'Чтобы получить доступ к этой функции'},
    })
    return
  }
  state.selectedUser = user
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}

.pageWrapperOverflow {
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.productsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px !important;
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: 34px;
}

.list {
  height: calc(100vh - 56px - 80px);
}

.product {
  background: var(--background-paper-color);
  padding: 12px;
  border-radius: 16px;
}

.productInfo {
  display: flex;
  flex-direction: column;
  gap: 11px;

  .imgWrapper {
    margin: -12px -12px 0 -12px;
    width: calc(100% + 24px);
    border-radius: 8px 8px 0 0;
    background: #3B3B3B;
    display: flex;

    &.full {
      margin: -16px -16px 0 -16px;
      width: calc(100% + 32px);
    }

    .img {
      width: 100%;
      flex-shrink: 0;
      border-radius: 8px 8px 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.bottom {
  display: flex;
  gap: 8px;
  padding-top: 16px;
  margin-top: 4px;
  border-top: 1px solid #494949;
  align-items: center;

  & > * {
    flex-shrink: 0;
  }

  &.drawer {
    margin: 0 -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.descriptionContainer {
  overflow: hidden;
}

.description {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.information-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 24px 0;
  padding: 0 16px;
}
</style>
