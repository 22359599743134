<template>
  <Logo />
  <Typography
    variant="v20-700"
    style="max-width: 280px; margin-bottom: 6px; margin-top: 0px;"
    center
  >
    Отсканируй QR-код
  </Typography>

  <Typography
    style="margin-top: 6px;margin-bottom: 32px;"
    center
    variant="v14-600"
  >
    для установки мобильного приложения
  </Typography>

  <div
    ref="qrCodeRef"
    style="margin-bottom: 12px"
  />

<!--  <AppButton-->
<!--      width="60"-->
<!--      style="margin-bottom: 24px;margin-top: 24px;"-->
<!--      @click="copyToClipboard(location.href, $event)"-->
<!--  >-->
<!--    Скопировать ссылку-->
<!--  </AppButton>-->
</template>

<script setup>
import QRCodeStyling from "qr-code-styling"
import {onMounted, ref} from "vue"
import Typography from "@/components/UI/Typography.vue"
import {copyToClipboard} from "@/utils/copyToClipboard.js"
import AppButton from "@/components/UI/AppButton.vue"
import Logo from "@/components/UI/Logo.vue";

const location = window.location

const qrCodeRef = ref()

const backgroundColor = 'var(--background-main-color)'
// const backgroundColor = '#000'
const color = 'var(--app-accent-light-color)'

// const backgroundColor = '#fff'
// const color = '#000'

const qrCode = new QRCodeStyling({
  width: 256,
  height: 256,
  type: "svg",
  // image: '/favicon.ico',
  // margin: 2,
  qrOptions: {
    typeNumber: 0,
    mode: 'Byte',
    errorCorrectionLevel: 'Q'
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 16,
    crossOrigin: 'anonymous',
  },
  backgroundOptions: {
    // color: 'var(--background-main-color)',
    color: backgroundColor,
  },
  dotsOptions: {
    // color: '#FFF',
    color,
    // type: 'square',
    type: "dots",
  },
  cornersSquareOptions: {
    // color: '#FFF',
    color,
    // type: 'square',
    type: "extra-rounded",
  },
  cornersDotOptions: {
    // color: '#FFF',
    color,
    type: 'square',
    // type: "rounded",
  }
})

onMounted(() => {
  qrCode.append(qrCodeRef.value)
  qrCode.update({
    data: document.location.href,
  })
})

</script>

<style lang="scss" scoped>

</style>
