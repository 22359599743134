<template>
  <div
    ref="messageRef"
    class="message"
    :class="{
      [variant]: variant,
      [message_type]: true,
      isNew,
    }"
  >
    <template
      v-if="message_type === 'looking_for'"
    >
      <div
        class="special lookingFor"
        :class="{empty: tags.length === 0}"
      >
        <Icon
          class="icon"
          width="20"
          height="20"
          :icon="require(`@/assets/icons/user_20_search.svg`)"
        />
        <Typography
          class="overflow"
        >
          {{ getTitle() }}
          <!--          {{ variant === 'answer' ? 'Я' : chatWithUser.first_name }}-->
          <!--          {{ variant === 'answer' ? 'ищу' : 'ищет' }}-->
        </Typography>
      </div>
    </template>

    <template
      v-if="message_type === 'product'"
    >
      <div
        class="special lookingFor"
        :class="{empty: tags.length === 0}"
      >
        <Icon
          class="icon"
          width="20"
          height="20"
          :icon="require(`@/assets/icons/user_20_showcase.svg`)"
        />
        <Typography
          class="overflow"
        >
          {{ getTitle() }}
          <!--      Продукт пользователя-->
        </Typography>
      </div>
    </template>

    <Typography
      variant="body2"
    >
      <template
        v-for="i in tags"
      >
        <template
          v-if="i.type === 'text'"
        >
          <span
            class="text"
          >
            {{ i.value }}
          </span>
        </template>
        <template
          v-if="i.type === 'link'"
        >
          <a
            class="link"
            :href="i.href"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ i.value }}
          </a>
        </template>
      </template>
    </Typography>

    <Typography
      v-if="message_type === 'message'"
      variant="text1"
      class="time"
      :style="{color: variant === 'response' ? '#fff' : undefined}"
    >
      {{ time }}
    </Typography>
  </div>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import Icon from '@/components/UI/Icon.vue'
import {onBeforeMount, reactive, ref} from 'vue'

const messageRef = ref()

const props = defineProps({
  variant: {   // answer, response
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  time: {
    type: String,
    required: true,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
  message_type: {
    type: String,
  },
  currentUser: {
    type: Object,
  },
  chatWithUser: {
    type: Object,
  },
  tags: {
    type: Array,
  }
})

const state = reactive({

})

onBeforeMount(() => {

})

function getTitle() {
  if (props.text.includes('title') && props.text.includes('type')) {
    try {
      const json = JSON.parse(props.text)
      if(json.title && json.type) {
        return json.title
      }
    } catch (e) {
      // can't parse
    }
  }

  switch (props.message_type) {
  case 'message': {
    return ''
  }
  case 'product': {
    return 'Продукт пользователя'
  }
  case 'looking_for': {
    let predMessage = props.variant === 'answer' ? 'Я' : props.chatWithUser.first_name
    let message = props.variant === 'answer' ? 'ищу' : 'ищет'
    return `${predMessage} ${message}`
  }
  }
}

</script>

<style lang="scss" scoped>

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.message {
  max-width: 80%;
  padding: 12px 12px 4px 12px;
  border-radius: 12px;
  margin-top: 16px;

  &.looking_for.looking_for {
    background: var(--card-secondary-background);
    padding: 12px;
    min-width: 100%;
    max-width: 100%;

    .special {
      color: #7EC9FF;
    }
  }

  &.product.product {
    background: var(--card-secondary-background);
    padding: 12px;
    min-width: 100%;
    max-width: 100%;

    .special {
      color: #54C35F;
    }
  }

  &.answer {
    background: var(--app-accent-light-color);
    align-self: flex-end;
    min-width: 40%;
  }

  &.response {
    background: var(--card-secondary-background);
    align-self: flex-start;
    min-width: 30%;
  }

  &.answer.isNew:last-child {
    animation: .2s ease-out 0s 1 slideInFromRight;
  }

  &.response.isNew:last-child {
    animation: .2s ease-out 0s 1 slideInFromLeft;
  }
}

.time {
  margin-top: 4px;
  text-align: right;
}

.message.answer + .answer,
.message.response + .response {
  margin-top: 8px;
}

.special {
  margin-bottom: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  & > * {
    flex-shrink: 0;
  }
  &.empty {
    margin-bottom: 0;
  }
  .overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
  }
}

.link {
  text-decoration: underline;
  word-break: break-word;
}

.text {
  white-space: break-spaces;
  word-break: break-word;
}

</style>
