import store from "@/store/store.js"
import {sleep} from "@/utils/sleep.js"
import {settings} from '@/configs/settings.js'

const wsBroadcast = {
  isWorking: false,
  start,
  stop,
}

export {wsBroadcast}

let ws = null

function stop() {
  try {
    ws.close()
  } catch (e) {
    console.log('close', e)
  }
}

function start() {
  if(wsBroadcast.isWorking) {
    return
  }
  wsBroadcast.isWorking = true
  const url = new URL(`${settings.env.VUE_APP_WS_URL}/ws/user/${store.state.user.profile.id}/`)
  ws = new WebSocket(url.toString())

  ws.onopen = async () => {
    // state.ws.send({})
  }

  ws.onerror = async (e) => {
    console.log('error', e)
    try {
      ws.close()
    } catch (e) {
      console.error(e)
    }
    wsBroadcast.isWorking = false
    await sleep(1e3)
    start()
  }

  ws.onclose = async (e) => {
    try {
      ws.close()
    } catch (e) {
      console.error(e)
    }
    wsBroadcast.isWorking = false
    await sleep(1e3)
    start()
  }

  ws.onmessage = message => {
    const json = JSON.parse(message.data)
    store.commit('user/setProfile', json.user)
  }

}
