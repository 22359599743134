<template>
  <head-new
    page_title="Контакты"
    :back-action="() => router.push({name: $route.meta.returnName ?? 'EditProfile'})"
  />

  <div
    class="main_container form"
  >
    <FormError
      v-show="errors.error"
      name="error"
      style="margin-bottom: 12px"
    />

    <!--    mask="+7 (###) ###-##-##"-->
    <FormTextInput
      :label="ct('Label.PhoneNumber')"
      name="contact_phone"
      pattern="[0-9]*"
      inputmode="tel"
      :mask="state.phoneMask"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim()
        if(paste.at(0) === '8') {
          cleared = paste.replace('8', '+7')
        }
        setFieldValue('contact_phone', cleared)
      }"
    />

    <FormTextInput
      label="Telegram"
      name="contact_telegram"
      :show-mask="'t.me/'"
      data-maska-tokens="*:[a-zA-Z0-9+@_]|T:[a-zA-Z0-9+]"
      mask="t.me/T********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('t.me', '').replace('/', '')
        setFieldValue('contact_telegram', 't.me/' + cleared)
      }"
    />

    <FormTextInput
      label="Instagram*"
      name="contact_instagram"
      text-info="*Instagram, продукт компании Meta, признанная экстремистской организацией в России"
      :data-maska-tokens="'*:[a-zA-Z0-9+@_\.~-]'"
      :show-mask="'instagram.com/'"
      mask="instagram.com/********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('instagram.com', '').replace('/', '')
        setFieldValue('contact_instagram', cleared)
      }"
    />

    <FormTextInput
      label="VK"
      name="contact_vk"
      :data-maska-tokens="'*:[a-zA-Z0-9+@_\.~-]'"
      :show-mask="'vk.com/'"
      mask="vk.com/********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('vk.com', '').replace('/', '')
        setFieldValue('contact_vk', cleared)
      }"
    />

    <FormTextInput
      label="YouTube"
      name="contact_youtube"
      :data-maska-tokens="'*:[a-zA-Z0-9+@_\.~-]'"
      :show-mask="'youtube.com/'"
      mask="youtube.com/********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('youtube.com', '').replace('/', '')
        setFieldValue('contact_youtube', cleared)
      }"
    />

    <FormTextInput
      label="TikTok"
      name="contact_tiktok"
      :data-maska-tokens="'*:[a-zA-Z0-9+@_\.~-]'"
      :show-mask="'tiktok.com/'"
      mask="tiktok.com/********************************************************************************************"
      @paste="e => {
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        let cleared = paste.trim().replace('https://www.', '').replace('https://', '').replace('tiktok.com', '').replace('/', '')
        setFieldValue('contact_tiktok', cleared)
      }"
    />

    <appButton
      full-width
      :loading="isSubmitting"
      :disabled="isSubmitting"
      @click="onSubmit"
    >
      {{ ct('Action.Save') }}
    </appButton>
  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {onMounted, reactive, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import {useRoute} from 'vue-router'
import FormError from "@/components/form/FormError.vue";
import * as libPhoneNumber from 'libphonenumber-js'
import {ct} from '../../../locales/i18nextInit.js'

const route = useRoute()

const state = reactive({
  phoneMask: '+######################'
})

const {handleSubmit, setErrors, errors, isSubmitting, setFieldValue, values, setValues,} = useForm({
  validationSchema: Yup.object({
    contact_instagram: Yup.string().trim().label('Instagram'),
    contact_telegram: Yup.string().trim().label('Telegram'),
    contact_tiktok: Yup.string().trim().label('TikTok'),
    contact_vk: Yup.string().trim().label('VK'),
    contact_youtube: Yup.string().trim().label('YouTube'),
    contact_phone: Yup.string()
      .trim()
      .test('phone', 'Некорректный номер телефона', value => {
        if(value) {
          try {
            const phone = libPhoneNumber.parsePhoneNumber(value)
            return phone.isValid()
          } catch (e) {
            return false
          }
        }
        return true
      })
      .label('Номер телефона'),
    error: Yup.mixed()
      .test('test1', 'Необходимо добавить контакт', () =>
        values.contact_instagram || values.contact_telegram || values.contact_tiktok || values.contact_vk || values.contact_youtube || values.contact_phone
      ),
  }),
})

watch(values, values => {
  try {
    const value = String(values.contact_phone).replaceAll(' ', '')
    let newMask = new libPhoneNumber
      .AsYouType()
      .input(value)
      .toString()
      .replace(/\d/g, '#')
    newMask = `+${newMask.replace('+', '')}######################`
    if (newMask !== state.phoneMask) {
      state.phoneMask = newMask
    }
  } catch (e) {
    state.phoneMask = '+######################'
  }
})

onMounted(() => {
  setValues({
    contact_telegram: store.state.user.profile.contact_telegram ?? '',
    contact_tiktok: store.state.user.profile.contact_tiktok ?? '',
    contact_instagram: store.state.user.profile.contact_instagram ?? '',
    contact_vk: store.state.user.profile.contact_vk ?? '',
    contact_youtube: store.state.user.profile.contact_youtube ?? '',
    contact_phone: store.state.user.profile.contact_phone ?? '',
  })
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      setErrors({error: ''})
    })
  })
})

const onSubmit = handleSubmit(async values => {
  const payload = {
    ...values,
  }
  await store.dispatch('user/updateProfile', payload)
  await router.push({name: route.meta.isCheckList ? 'CheckList' : 'Profile'})
})

</script>

<style lang="scss" scoped>

.form {
  margin-top: 16px;
}

</style>
