<template>
  <div>
    <AnotherUserProfileModal
      v-if="!state.isLoading && state.loadedUser"
      full-height
      :user="state.loadedUser"
      :user-contact="state.userContact"
      @close="$emit('close')"
    />
    <div
      v-else
    >
      <div
        v-if="user"
        class="modal"
      >
        <head-new
          page_title=""
          :back-action="() => {
            $emit('close')
          }"
        />

        <div
          class="main_container userData"
        >
          <Skeleton
            variant="text"
            height="106"
            width="106"
            radius="16"
          />

          <div
            class="userInfo"
          >
            <Skeleton
              variant="text"
              height="24"
              width="200"
            />

            <Skeleton
              width="126"
              height="36"
            />
          </div>
        </div>

        <div
          class="main_container"
        >
          <Skeleton
            v-for="i in 3"
            variant="text"
            :width="String(200 + (i % 2) * 50 + (i * 20))"
            height="14"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive, watch} from 'vue'
import {appAxios} from "@/axios.js"
import AnotherUserProfileModal from "@/views/profile/AnotherUserProfileModal.vue"
import {imagesLinkCache} from '@/utils/imagesLinkCache.js'
import Typography from '@/components/UI/Typography.vue'
import Avatar from '@/components/profile/Avatar.vue'
import Icon from '@/components/UI/Icon.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import {router} from '@/router/router.js'
import HeadNew from '@/components/Head.vue'
import UserProfessions from '@/components/UserProfessions.vue'
import UserSpecializations from '@/components/UserSpecializations.vue'
import UserLocationAndAge from '@/components/UserLocationAndAge.vue'

const emit = defineEmits(['close'])

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
  userContact: {
    type: Object,
    default: null,
  },
  fullHeight: {
    type: Boolean,
    default: null,
  }
})

const state = reactive({
  loadedUser: null,
  userContact: null,
  isLoading: false,
})

watch(() => props.user, async (user) => {
  state.isLoading = true
  state.loadedUser = null
  state.userContact = null
  if (user && user.id) {
    const [userRes, contactsRes] = await Promise.all([
      appAxios.user.fetchProfile(user.id),
      appAxios.myContacts.fetchContact(user.id)
    ])
    state.userContact = contactsRes.data.contacts.find(contact => {
      return contact.contact_user.id.toString() === user.id.toString()
    })
    state.loadedUser = userRes.data
  }
  state.isLoading = false
})

function back() {
  emit('close')
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.modal {
  position: fixed;
  z-index: 1001;
  background: var(--background-main-color);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.userData {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }
}

</style>
