<template>
  <Card
    v-if="state.subscriptionStatus in showAtStatuses"
    class="subscriptionBlock"
  >
    <template
      v-if="!(state.subscriptionStatus in {[subscriptionStatuses.active]: true})"
    >
      <div
        style="display: flex;"
        :style="{gap: `${iconSize / 5}px`}"
      >
        <Icon
          style="flex-shrink: 0"
          :icon="require(`@/assets/icons/_images/${iconSize}_subscription.svg`)"
          :width="`${iconSize}`"
          :height="`${iconSize}`"
        />

        <Typography
          variant="v16-700"
        >
          {{ props[`${state.subscriptionStatus}SubscriptionTitle`] }}
        </Typography>
      </div>

      <Typography
        style="margin-top: 8px;"
        variant="v14-400"
        color="var(--text-notice-color)"
      >
        {{ props[`${state.subscriptionStatus}SubscriptionText`] }}
      </Typography>

      <AppButton
        style="margin-top: 16px;"
        full-width
        @click="props[`${state.subscriptionStatus}SubscriptionAction`]()"
      >
        {{ props[`${state.subscriptionStatus}SubscriptionButton`] }}
      </AppButton>
    </template>
    <template
      v-else
    >
      <div
        style="display: flex; align-items: center;"
        :style="{gap: `${iconSize / 5}px`}"
      >
        <Icon
          style="flex-shrink: 0"
          :icon="require(`@/assets/icons/_images/${iconSize}_subscription.svg`)"
          :width="`${iconSize}`"
          :height="`${iconSize}`"
        />
        <Typography
          variant="v16-700"
        >
          Younesis Premium
        </Typography>
        <Typography
          style="background: var(--card-light-background); margin-left: 4px; padding: 4px 12px; border-radius: 100px"
          variant="v14-500"
        >
          Активен
        </Typography>
      </div>
    </template>
  </Card>
</template>

<script setup>
import Card from "@/components/UI/Card.vue"
import AppButton from "@/components/UI/AppButton.vue"
import Icon from "@/components/UI/Icon.vue"
import Typography from "@/components/UI/Typography.vue"
import {reactive, watch} from 'vue'
import store from '@/store/store.js'
import {subscriptionStatuses} from '@/configs/subscriptionStatuses.js'
import {router} from '@/router/router.js'

const state = reactive({
  subscriptionStatus: store.state.user.profile.subscriptions?.at(0)?.status ?? subscriptionStatuses.new
})

watch(() => store.state.user.profile.subscriptions, subscriptions => {
  state.subscriptionStatus = subscriptions.at(0)?.status ?? subscriptionStatuses.new
})

const props = defineProps({
  showAtStatuses: {
    type: Object,
    default: {
      [subscriptionStatuses.new]: true,
      [subscriptionStatuses.pending]: true,
      [subscriptionStatuses.disabled]: true,
      [subscriptionStatuses.active]: true,
    },
  },
  iconSize: {
    type: Number, // 20, 40
    default: 20,
  },
  // new
  newSubscriptionTitle: {
    type: String,
    default: 'Younesis Premium',
  },
  newSubscriptionText: {
    type: String,
    default: 'Больше возможностей для личного роста и развития',
  },
  newSubscriptionButton: {
    type: String,
    default: 'Подключить подписку',
  },
  newSubscriptionAction: {
    type: Function,
    default: () => {
      sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
        returnName: 'Profile',
      }))
      router.push({
        name: 'SubscriptionTariffSelection',
      })
    },
  },
  // pending
  pendingSubscriptionTitle: {
    type: String,
    default: 'Younesis Premium',
  },
  pendingSubscriptionText: {
    type: String,
    default: 'Больше возможностей для личного роста и развития',
  },
  pendingSubscriptionButton: {
    type: String,
    default: 'Подключить подписку',
  },
  pendingSubscriptionAction: {
    type: Function,
    default: () => {
      sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
        returnName: 'Profile',
      }))
      router.push({
        name: 'SubscriptionTariffSelection',
      })
    },
  },
})

</script>

<style lang="scss" scoped>
.subscriptionBlock {
  margin: 16px 0;
}
</style>
