<template>
  <div
    class="main_container tabs"
  >
    <button
      :class="{ selected: route.name === 'MarketplaceCourses' }"
      @click="changeRoute('MarketplaceCourses')"
    >
      Каталог
    </button>
    <button
      :class="{ selected: route.name === 'StudyCourses' }"
      @click="changeRoute('StudyCourses')"
    >
      Учусь
    </button>
  </div>
</template>

<script setup>
import {useRoute} from 'vue-router'
import {router} from '@/router/router'

const route = useRoute()
function changeRoute(name) {
  router.push({name})
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  padding: 0;
}

button {
  font-family: Raleway;
  width: 100%;
  background: var(--background-main-color);
  height: 42px;
  color: var(--text-primary-color);
  font-size: 14px;
  border-bottom: 2px #494949 solid;

  &.selected {
    border-bottom: 2px var(--app-accent-light-color) solid;
  }
}
</style>
