<template>
  <head-new
    :title="state.lesson?.lesson_type === 'lesson' ? `${state.lessonNumber} урок из ${state.displayedLessonsCount}` : 'Дополнительный материал'"
    :is-loading="state.isLoading"
    :back-action="back"
  />

  <div
    v-for="(item, i) in state.lesson.lesson_items"
    v-if="!state.isLoading"
    class="main_container content"
    :class="{video: 'video' === item.content_type}"
  >
    <component
      :is="lessonContent[item.content_type]"
      :data="item"
    />
  </div>

  <div
    v-if="!state.isLoading"
    class="main_container content"
  >
    <template
      v-if="state.lesson.lesson_materials.length"
    >
      <Card>
        <div
          class="filesTitle"
        >
          Материалы урока
          <div
            class="filesContainer"
          >
            <div
              v-for="material in state.lesson.lesson_materials"
              class="fileLink"
              @click="() => openInNewTab(material.link ?? material.file)"
            >
              {{ material.title }}
              <div
                class="icon"
              />
            </div>
          </div>
        </div>
      </Card>
    </template>

    <Card
      v-for="lessonTest in state.lessonTests"
    >
      <div
        class="taskText"
      >
        Задание
      </div>

      <div
        class="filesTitle"
        style="margin-bottom: 8px;"
      >
        Тестирование
      </div>

      <div
        v-if="lessonTest.studentTest && lessonTest.studentTest.finished_at"
        style="margin-bottom: 8px;"
        class="taskInfo"
      >
        <Typography
          variant="body1"
          color="#838181"
        >
          Правильно решено {{ lessonTest.studentTest.progress.correct_answers }} из
          {{ lessonTest.studentTest.progress.total_questions }}
        </Typography>
      </div>

      <AppButton
        style="margin-top: 16px;"
        :variant="lessonTest.studentTest ? (lessonTest.studentTest.finished_at ? 'secondary' : 'primary') : 'primary'"
        size="small"
        @click="() => {
          router.push({
            name: props.moduleId ? 'StudyModuleTest' : 'StudyCourseTest',
            params: {
              userCourseId: props.userCourseId,
              moduleId: props.moduleId,
              testId: lessonTest.test.id,
            },
          })
        }"
      >
        {{
          lessonTest.studentTest ? (lessonTest.studentTest.finished_at ? 'Посмотреть результаты' : 'Продолжить') : 'Приступить'
        }}
      </AppButton>
    </Card>
  </div>

  <div
    class="main_container buttons"
  >
    <AppButton
      :is-loading="state.isLoading"
      variant="primary"
      full-width
      @click="submitNext"
    >
      {{
        state.lessonNumber === state.lessonsCount ?
          (state.courseType === 'StudyModuleLesson' ? 'Вернуться к модулю' : 'Вернуться к курсу')
          : 'Следующий урок'
      }}
    </AppButton>

    <AppButton
      v-if="!(state.lessonNumber === 1)"
      :is-loading="state.isLoading"
      variant="secondary"
      full-width
      @click="() => {
        if(state.lessonNumber === 1) {
          router.push({
            name: props.moduleId ? 'StudyModule' : 'StudyCourse',
            params: {
              userCourseId: props.userCourseId,
              moduleId: props.moduleId,
            },
          })
        } else {
          router.push({name: state.courseType, params: {
            userCourseId: state.userCourse.id,
            moduleId: props.moduleId,
            lessonId: state.lessons[state.lessonNumber - 2].id
          }})
        }
      }"
    >
      {{
        state.lessonNumber === 1 ? (state.courseType === 'StudyModuleLesson' ? 'Вернуться к модулю' : 'Вернуться к курсу') : 'Предыдущий урок'
      }}
    </AppButton>
  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import {onBeforeMount, onMounted, onUnmounted, reactive} from 'vue'
import {appAxios} from '@/axios'
import AppButton from '@/components/UI/AppButton.vue'
import {router} from '@/router/router'
import {lessonContent} from '@/views/study/lessonContent/LessonContent'
import Card from '@/components/UI/Card.vue'
import {openInNewTab} from '@/utils/openInNewTab'
import Typography from '@/components/UI/Typography.vue'
import {indexedStore} from '@/indexedDB/indexedStore.js'

const props = defineProps({
  userCourseId: {
    type: String,
  },
  moduleId: {
    type: String,
  },
  lessonId: {
    type: String,
  },
})

const state = reactive({
  isLoading: true,
  userCourse: null,
  course: null,
  module: null,

  courseType: '', // lesson / modules

  lesson: null,
  studentLesson: null,
  studentModule: null,
  lessons: [],
  lessonNumber: null,

  lessonsCount: null,
  displayedLessonsCount: null,

  lessonTests: [],
})

function handleTimecodes(e) {
  let target = e.target
  if (target.tagName !== 'CODE') {
    if (target.parentNode.tagName === 'CODE') {
      target = e.target.parentNode
    } else {
      return
    }
  }

  const timeString = target.querySelector('strong')

  let text = timeString.innerText

  const arr = text.replaceAll(' ', '').split(':')

  let seconds = 0
  let minutes = 0
  let hours = 0

  if (arr.length === 1) {
    seconds = Number(arr[0])
  }
  if (arr.length === 2) {
    seconds = Number(arr[1])
    minutes = Number(arr[0])
  }
  if (arr.length === 3) {
    seconds = Number(arr[2])
    minutes = Number(arr[1])
    hours = Number(arr[0])
  }

  const time = seconds + minutes * 60 + hours * 60 * 60

  if (!time) {
    // todo: handle error
    console.log('err')
    return
  }

  let content = target.closest(".content")
  while (content) {
    content = content.previousSibling
    if (content.classList.contains('video')) {
      break
    }
  }
  if (!content) {
    return
  }

  const video = content.querySelector('video') ?? content.querySelector('.video')
  const videoWrapper = content.querySelector('.videoWrapper')

  if (videoWrapper && videoWrapper.dataset.uid) {
    const player = window.kinescopePlayers[videoWrapper.dataset.uid]
    player.play()
    player.seekTo(time)
  } else {
    video.currentTime = time
    video.play()
  }
}

onMounted(() => {
  addEventListener("click", handleTimecodes)
})

onUnmounted(() => {
  removeEventListener("click", handleTimecodes)
})


onBeforeMount(async () => {
  try {
    const res = await appAxios.myCourses.fetchOne(props.userCourseId)
    const resCourse = await appAxios.course.cacheOne(res.data.course_id)

    let lessons = null
    if (props.moduleId) {
      state.courseType = 'StudyModuleLesson'
      const module = resCourse.data.modules.find(module => module.id === Number(props.moduleId))
      const studentModule = res.data.student_modules.find(st => st.module_id === module.id)
      state.module = module
      lessons = module.lessons
      const lesson = lessons.find(l => l.id.toString() === props.lessonId)

      state.studentModule = studentModule
      studentModule.student_lessons.forEach(ml => {
        if (ml.lesson_id === lesson.id) {
          state.studentLesson = ml
        }
      })
    } else {
      state.courseType = 'StudyCourseLesson'
      lessons = resCourse.data.lessons
      const lesson = lessons.find(l => l.id.toString() === props.lessonId)

      res.data.student_lessons.forEach(sl => {
        if (sl.lesson_id === lesson.id) {
          state.studentLesson = sl
        }
      })
    }

    state.lessons = [
      ...lessons.filter(l => l.lesson_type === 'lesson').sort((a, b) => a.order - b.order),
      ...lessons.filter(l => l.lesson_type !== 'lesson').sort((a, b) => a.order - b.order),
    ]

    const lesson = state.lessons.find(l => l.id.toString() === props.lessonId)
    const lessonNumber = state.lessons.indexOf(lesson) + 1

    state.lesson = lesson

    state.lessonNumber = lessonNumber
    state.lessonsCount = lessons.length
    state.displayedLessonsCount = lessons.filter(l => l.lesson_type === 'lesson').length

    state.userCourse = res.data
    state.course = resCourse.data

    if (!state.studentLesson) {
      const res = await appAxios.myLessons.start(state.lesson.id)
      state.studentLesson = res.data
    }

    const lessonTests = state.lesson.lesson_tests
    state.studentLesson.student_tests.forEach(studentTest => {
      const found = lessonTests.find(test => test.id === studentTest.lesson_test.id)
      found.studentTest = studentTest
    })

    state.lessonTests = lessonTests
  } catch (e) {
    console.log('error', e)
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

function back() {
  router.push({
    name: props.moduleId ? 'StudyModule' : 'StudyCourse',
    params: {
      userCourseId: props.userCourseId,
      moduleId: props.moduleId,
    },
  })
}

async function submitNext() {
  // const courseRes = await appAxios.myCourses.fetchOne(props.userCourseId)
  // const lessonRes = await appAxios.myLessons.finish(state.studentLesson.id)
  //
  // courseRes.data.student_lessons.forEach((lesson, index) => {
  //   if(lesson.id === lessonRes.data.id) {
  //     courseRes.data.student_lessons[courseRes.data.student_lessons] = lessonRes.data
  //   }
  // })
  //
  // await indexedStore.slices.myCourses.update(`myCourses-fetchOne-${props.userCourseId}`, {
  //   data: courseRes.data,
  // })
  await appAxios.myLessons.finish(state.studentLesson.id)

  if (state.lessonNumber === state.lessonsCount) {
    await router.push({
      name: props.moduleId ? 'StudyModule' : 'StudyCourse',
      params: {
        userCourseId: props.userCourseId,
        moduleId: props.moduleId,
      },
    })
  } else {
    await router.push({
      name: state.courseType, params: {
        userCourseId: state.userCourse.id,
        moduleId: props.moduleId,
        lessonId: state.lessons[state.lessonNumber].id
      }
    })
  }
}

</script>

<style lang="scss" scoped>

.content {
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.taskText {
  font-size: 12px;
  font-weight: 500;
  color: #838181;
  margin-bottom: 6px;
}

.taskInfo {
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 18px;
}

.filesTitle {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 700;
  color: var(--text-primary-color);
}

.filesContainer {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 8px;
}

.fileLink {
  transition: background-color ease .3s, border-color ease .3s, color ease .3s;
  background: var(--card-light-background);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary-color);

  &:hover {
    background: #393939;
  }

  &:active {
    background: #2C2B2B;
  }

  .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background: url("@/assets/icons/white_24_arrowDiagonal.svg") no-repeat;
  }
}

.buttons {
  margin-top: 24px;
  display: flex;
  flex-flow: column;
  gap: 12px;
}
</style>
