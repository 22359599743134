<script setup>
import {toRef} from 'vue'
import {useField} from 'vee-validate'
import Icon from '@/components/UI/Icon.vue'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
})

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>

<template>
  <div
    class="textInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <Icon
      v-if="errorMessage"
      width="16"
      height="16"
      :icon="require(`@/assets/icons/_images/16_redCircleError.svg`)"
    />
    <div
      class="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/variables";

.textInput {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  min-height: 16px;

  &.fullWidth {
    width: 100%;
  }

  label {
    color: var(--form-label-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    width: 100%;
    position: relative;
  }

  .inputError {
    border: 1px solid var(--form-border-active-color) !important;
  }

  .errorMessage {
    color: var(--text-error-color);
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    position: relative;
    top: 1px;
  }

  .textInfo {
    color: #838181;
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .error_icon {
    position: absolute;
    top: calc(50%);
    right: 12px;
    transform: translateY(-50%);
    z-index: 100000;
  }
}

.TextInput input {
  color: #fff;
  border: 1px solid var(--form-border-base-color);

  &:focus {
    border: 1px solid var(--form-border-active-color);
  }
}

</style>
