<template>
  <!--  <teleport to="#app">-->
  <div
    v-if="event"
    ref="eventRef"
    class="event"
  >
    <head-new
      :page_title="t('calendar:EventTitle')"
      :back-action="() => backAction ? backAction() : back()"
    />

    <div
      class="pageWrapperOverflow"
    >
      <div
        class="main_container"
      >
        <div
          v-if="event.preview_image"
          class="top"
        >
          <img
            v-if="event.preview_image"
            class="preview"
            :src="imagesLinkCache.cache(event.preview_image)"
          />
          <div />
        </div>

        <Typography
          class="title"
          variant="subtitle"
        >
          {{ event.title }}
        </Typography>

        <Typography
          class="description"
          variant="body1"
          color="var(--text-notice-color)"
        >
          {{ event.description }}
        </Typography>

        <Typography
          v-if="event.event_speakers.length"
          class="title"
          variant="subtitle"
        >
          {{ t('calendar:Speakers') }}
        </Typography>

        <div
          v-if="event.event_speakers.length"
          class="swiperContainer"
        >
          <swiper
            :slides-per-view="1.5"
            :space-between="8"
            :free-mode="true"
            class="swiper"
          >
            <swiper-slide
              v-for="item in event.event_speakers"
            >
              <div
                class="speakerCard"
              >
                <div
                  class="speakerCardImage"
                  :style="{backgroundImage: item.speaker.avatar ? urlToCssURL(imagesLinkCache.cache(item.speaker.avatar)): null}"
                />
                <div
                  class="speakerCardBackGround"
                />

                <div
                  class="speakerCardTopSpace"
                />

                <Typography
                  class="name"
                  variant="title"
                >
                  {{ item.speaker.first_name }} {{ item.speaker.last_name }}
                </Typography>

                <Typography
                  class="aboutMe"
                  pre-line
                  variant="text2"
                  color="secondary"
                >
                  {{ item.speaker.about_me }}
                </Typography>

                <div
                  class="speakerCardBottomSpace"
                />
              </div>
            </swiper-slide>
          </swiper>
        </div>

        <div
          v-if="event.for_whom && event.for_whom.length"
          class="cardsList"
        >
          <Card>
            <Typography
              class="cardTitle"
              variant="subtitle1"
            >
              {{ t('calendar:EventSuitableIfYou') }}
            </Typography>
            <div
              class="tags"
            >
              <div
                style="width: 14px"
              />
              <Tag
                v-for="item in event.for_whom"
                class="tag"
              >
                {{ item.profession.title }}
              </Tag>
              <div
                style="width: 14px"
              />
            </div>
          </Card>
        </div>

        <div
          class="cardsList"
        >
          <Card>
            <Typography
              class="cardTitle"
              variant="subtitle1"
            >
              {{ state.dateSting }}
            </Typography>
            <EventType
              :event-type="event.event_type"
            />
          </Card>
        </div>

        <div
          v-if="event.place"
          class="cardsList"
        >
          <Card>
            <Typography
              class="cardTitle"
              variant="subtitle1"
            >
              {{ t('calendar:Place') }}
            </Typography>
            <Typography
              variant="body1"
              color="var(--text-notice-color)"
            >
              {{ event.place }}
            </Typography>
          </Card>
        </div>

        <AppButton
          v-if="event.source"
          style="margin-top: 16px"
          size="large"
          full-width
          @click="() => {
            yandexMetrika('reachGoal', 'eventExternalNavigate', {
              title: event.title,
              source: event.source,
            })
            openInNewTab(event.source)
          }"
        >
          {{ t('calendar:Action.GoToWebsite') }}
          <Icon
            style="margin-left: 8px"
            :icon="require(`@/assets/icons/white_24_arrowDiagonal.svg`)"
          />
        </AppButton>
      </div>
    </div>
  </div>
  <!--  </teleport>-->
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {nextTick, reactive, ref, watch} from 'vue'
import Card from '@/components/UI/Card.vue'
import HeadNew from '@/components/Head.vue'
import {useModalScrollingFix} from '@/hooks/useModalScrollingFix'
import Icon from '@/components/UI/Icon.vue'
import Tag from '@/components/Tag.vue'
import EventType from '@/views/HomeView/EventType.vue'
import moment from 'moment'
import {Swiper, SwiperSlide} from 'swiper/vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import {openInNewTab} from '@/utils/openInNewTab'
import {useModalHomeLayoutFix} from '@/hooks/useModalHomeLayoutFix'
import {yandexMetrika} from "@/external/yandexWebisor.js"
import {t} from 'i18next'

const modalScrollingFix = useModalScrollingFix()
const modalHomeLayoutFix = useModalHomeLayoutFix()

const emit = defineEmits(['close'])

const eventRef = ref()

const props = defineProps({
  event: {
    type: Object,
    default: null,
  },
  backAction: {
    type: Function,
  }
})

const state = reactive({
  dateSting: '',
})

watch(() => props.event, (event) => {
  nextTick(() => {
    if (event && eventRef.value) {
      modalScrollingFix.open()
      modalHomeLayoutFix.open(eventRef.value)

      const {start_date, end_date} = event
      const start = moment(start_date, 'YYYY-MM-DD')
      const end = moment(end_date, 'YYYY-MM-DD')

      if (start.year() === end.year() && start.month() === end.month() && start.day() === end.day()) {
        state.dateSting = `${start.format('DD.MM')}`
        return
      }
      if (start.year() === end.year() && start.month() === end.month()) {
        state.dateSting = `${start.format('DD.MM')} - ${end.format('DD.MM')}`
        return
      }
      state.dateSting = `${start.format('DD.MM')} - ${end.format('DD.MM')}`
    } else {
      modalScrollingFix.close()
      modalHomeLayoutFix.close()
    }
  })
})

function back() {
  emit('close')
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.event {
  position: fixed;
  z-index: 10;
  background: var(--background-main-color);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: calc(100vh - $bottomMenuOffset);
  min-height: calc(100svh - $bottomMenuOffset);
  max-height: calc(100vh - $bottomMenuOffset);
  max-height: calc(100svh - $bottomMenuOffset);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  padding-bottom: 32px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.top {
  margin-top: 16px;
  min-height: 24px;
}

.preview {
  height: 24px;
  user-select: none;
  flex-shrink: 1;
  max-width: 50%;
  object-fit: contain;
  background-repeat: no-repeat;
}

.title {
  margin: 16px 0 8px;
}

.description {
  margin-bottom: 24px;
}

.cardsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.cardTitle {
  margin-bottom: 8px;
  font-weight: 600;
}

.tags {
  display: flex;
  gap: 4px;
  overflow: auto;
  margin: 0 -16px;
  max-width: calc(100% + 32px);

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex-shrink: 0;
  }
}

.swiperContainer {
  margin: 0 -16px;
  padding: 0 16px;
  overflow: hidden;
  margin-bottom: 24px;
  margin-top: 16px;
}

.swiper {
  overflow: visible;
}

.emptyCard {
  height: 328px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  user-select: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.speakerCardImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 16px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.speakerCardBackGround {
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, .1) 45%, rgba(0, 0, 0, .4) 70%, rgba(0, 0, 0, .9) 100%) !important;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.speakerCard {
  //width: 240px;
  height: 328px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  user-select: none;
  cursor: pointer;

  .aboutMe {
    text-align: center;
    padding: 0 8px;
    flex-basis: 48px;
    position: relative;
    z-index: 2;
  }

  .name {
    text-align: center;
    padding: 0 8px;
    margin-bottom: 4px;
    flex-basis: 24px;
    position: relative;
    z-index: 2;
  }
}

.speakerCardTopSpace {
  flex-basis: 200px;
}

.speakerCardBottomSpace {
  flex-basis: 12px;
}

</style>
