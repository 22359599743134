<template>
  <div>
    <div
      style="flex-grow: 1"
    />

    <div
      class="main_container final"
    >
      <div
        class="finalIcon"
      >
        <div
          class="greenBackground"
        />
      </div>

      <Typography
        variant="v24-700"
        center
      >
        Твой профиль готов!
      </Typography>
      <Typography
        variant="v14-400"
        color="secondary"
        center
      >
        Теперь ты можешь начать пользоваться приложением и дополнить информацию в своем профиле в любое время.
      </Typography>
    </div>

    <div
      style="flex-grow: 1"
    />

    <div
      style="padding: 0 16px; background: var(--background-main-color); border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <AppButton
        style="margin-top: 16px; margin-bottom: 16px"
        size="large"
        full-width
        @click="() => props.nextStep()"
      >
        Перейти в приложение
      </AppButton>
    </div>
  </div>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store.js'
import {onBeforeMount, onMounted, reactive, watch} from 'vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import FormError from '@/components/form/FormError.vue'
import {openExternalURL} from "@/utils/openExternalURL.js";
import AppLink from "@/components/AppLink.vue";
import * as libPhoneNumber from 'libphonenumber-js'

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
})

const state = reactive({

})

const {handleSubmit, setErrors, errors, isSubmitting, setFieldValue, values, setValues,} = useForm({
  validationSchema: Yup.object({

  }),
})

const onSubmit = handleSubmit(async () => {
  const payload = {
    ...values,
  }
  await store.dispatch('user/updateProfile', payload)
  await props.nextStep()
})

</script>

<style lang="scss" scoped>

.final {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .greenBackground {
    position: absolute;
    background: radial-gradient(rgba(86, 231, 118, 0.35) 0%, rgba(88, 234, 120, 0.05) 25%, rgba(0, 0, 0, 0) 50%);
    width: 224px;
    height: 224px;
    left: -50%;
    top: -50%;
  }

  .finalIcon {
    position: relative;
    background-image: url("@/assets/icons/bigSuccess.svg");
    height: 112px;
    width: 112px;
  }
}

</style>
