<template>
  <div
    class="wrapper"
  >
    <head-new
      page_title="Кейсы и достижения"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'Profile'})"
    />
    <div
      class="main_container"
    >
      <div
        class="form"
      >
        <Card
          class="info"
        >
          <Icon
            class="icon"
            width="20"
            height="20"
            :icon="require(`@/assets/icons/user_20_suitcase.svg`)"
          />

          <Typography
            variant="body1"
          >
            Добавь топ-5 личных побед, которыми ты гордишься
          </Typography>
        </Card>

        <AppButton
          v-if="state.achievementList.length && achievementsLimit > state.achievementList.length"
          full-width
          variant="primary"
          size="large"
          style="margin-bottom: 24px"
          @click="createAchievement"
        >
          {{ ct('Action.Add') }}
        </AppButton>
      </div>

      <Typography
        variant="v12-400"
        color="secondary"
        style="margin-top: 24px;margin-bottom: 12px"
      >
        Зажми и перетащи для изменения порядка отображения
      </Typography>

      <div
        v-if="state.achievementList.length"
        class="achievementList"
      >
        <VueDraggable
          v-model="list"
          animation="150"
          ghost-class="ghost"
          drag-class="drag"
          class="container"
          handle=".handle"
          :force-fallback="true"
          @end="updateList"
        >
          <Card
            v-for="item in list"
            :key="item.id"
            class="draggable"
          >
            <div
              class="handle"
              style="padding: 6px"
            >
              <Icon
                :icon="require(`@/assets/icons/gray_24_handle.svg`)"
              />
            </div>

            <Typography
              variant="v12-400"
              color="var(--text-notice-color)"
            >
              Кейс или достижение
            </Typography>

            <div
              style="margin-top: 8px;margin-bottom: 16px"
            >
              {{ item.title }}
            </div>

            <div
              class="buttons"
            >
              <appButton
                full-width
                variant="secondary"
                @click="editAchievement(item.id)"
              >
                {{ ct('Action.Edit') }}
              </appButton>

              <appButton
                v-if="state.achievementList.length >= 2"
                full-width
                variant="danger"
                color="var(--app-accent-light-color)"
                @click="state.isDeleteId = item.id"
              >
                {{ ct('Action.Delete') }}
              </appButton>
            </div>
          </Card>
        </VueDraggable>
      </div>

      <div
        v-else
        class="middleText"
      >
        <div>
          <Typography
            variant="subtitle"
          >
            Здесь будут твои кейсы и достижения
          </Typography>

          <div
            style="height: 8px"
          />

          <Typography
            variant="body1"
            color="secondary"
          >
            Добавляй их и повышай доверие к себе
          </Typography>
        </div>

        <AppButton
          full-width
          variant="primary"
          size="large"
          style="margin-top: 24px"
          @click="createAchievement"
        >
          {{ ct('Action.Add') }}
        </AppButton>
      </div>

      <BottomDrawer
        :is-shown="state.isDeleteId"
        title="Удаление данных"
        @close="state.isDeleteId = null"
      >
        <Typography
          variant="body1"
          color="secondary"
        >
          Ты точно хочешь удалить кейс или достижение?
        </Typography>

        <div
          class="modalButtons"
        >
          <appButton
            variant="secondary"
            :disabled="state.isDeleting"
            @click="state.isDeleteId = null"
          >
            Оставить
          </appButton>

          <appButton
            variant="secondary"
            color="var(--app-accent-light-color)"
            :disabled="state.isDeleting"
            :loading="state.isDeleting"
            @click="deleteAchievement(state.isDeleteId)"
          >
            {{ ct('Action.Delete') }}
          </appButton>
        </div>
      </BottomDrawer>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import store from '@/store/store'
import {VueDraggable} from 'vue-draggable-plus'
import {onBeforeMount, reactive, ref, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Typography from '@/components/UI/Typography.vue'
import Icon from '@/components/UI/Icon.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {appAxios} from '@/axios'
import {useRoute} from "vue-router"
import {ct} from '../../../locales/i18nextInit.js'

const route = useRoute()

const list = ref([])

const achievementsLimit = store.state.user.directories.user_achievements_limit

onBeforeMount(() => {
  list.value = store.state.user.profile.achievements.sort((a, b) => a.order - b.order)
})

watch(() => store.state.user.profile.achievements, achievements => {
  if (achievements.length !== list.value.length) {
    list.value = achievements.sort((a, b) => a.order - b.order)
  }
})

const state = reactive({
  isLoading: true,
  achievementList: store.state.user.profile.achievements,
  isDeleteId: null,
  isDeleting: false,
})

function createAchievement() {
  router.push({
    name: route.meta.isCheckList ? 'CheckListCreateAchievement' : 'CreateAchievement',
  })
}

function editAchievement(id) {
  router.push({
    name: route.meta.isCheckList ? 'CheckListEditAchievement' : 'EditAchievement',
    params: {achievementId: id},
  })
}

async function deleteAchievement(id) {
  state.isDeleting = true

  const found = store.state.user.profile.achievements.find(item => item.id.toString() === id.toString())
  const res = await appAxios.achievements.remove(found)

  const resResult = await appAxios.achievements.fetchAll()

  await store.commit('user/setProfile', {
    achievements: resResult.data,
  })

  state.achievementList = resResult.data

  state.isDeleteId = null
  state.isDeleting = false
}

function updateList() {
  list.value.map((achievement, index) => {
    achievement.order = index + 1
    return appAxios.achievements.update(achievement)
  })
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.wrapper {
  min-height: 100vh;
  min-height: 100svh;
  max-height: 100vh;
  max-height: 100svh;
  display: flex;
  flex-direction: column;
  padding-bottom: $homeLayoutPaddingBottom + 56px;
  margin-bottom: -$homeLayoutPaddingBottom;
  overflow-y: auto;
  overflow-x: hidden;
}

.middleText {
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.info {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;

  & > .icon {
    flex-shrink: 0;
  }
}

//.achievementList {
//  display: flex;
//  flex-direction: column;
//  gap: 12px;
//}
//
//.achievement {
//  position: relative;
//}

//.achievementText {
//  margin-bottom: 16px;
//  margin-top: 4px;
//  padding-right: 20px;
//}

.removeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
  }
}

.container {
  display: grid;
  gap: 12px;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 8px;
}

.draggable {
  position: relative;
  user-select: none;
}

.handle {
  position: absolute;
  cursor: move;
  right: 8px;
  top: 8px;
}

.ghost {
  visibility: hidden;
}

.drag {
  //opacity: 1 !important;
}

</style>
