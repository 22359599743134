<template>
  <div
    class="headerIcon"
    :class="{warning: !profileProgress.isEnoughFilled}"
  >
    <div
      class="innerIcon"
      :class="{active}"
    >
      <svg
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 20.0002V15.5002C10 14.1192 11.119 13.0002 12.5 13.0002V13.0002C13.881 13.0002 15 14.1192 15 15.5002V20.0002H20.5V11.4143C20.5 10.8843 20.289 10.3752 19.914 10.0002L13.207 3.29325C12.816 2.90225 12.183 2.90225 11.793 3.29325L5.086 10.0002C4.711 10.3752 4.5 10.8843 4.5 11.4143V20.0002H10Z"
          :stroke="color"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
import store from "@/store/store.js";
import {computed} from "vue";

const profileProgress = computed(() => store.getters["user/profileProgress"])

const props = defineProps({
  color: {
    type: String,
    default: '#ffffff' // #8B8B8B
  },
  active: {
    type: Boolean,
    default: false,
  },
  warning: {
    type: Boolean,
    default: false,
  },
})

</script>

<style lang="scss" scoped>
.headerIcon {
  width: 24px;
  height: 24px;
  position: relative;

  &.warning:after {
    content: "";
    position: absolute;
    width: 14px;
    height: 13px;
    background-image: url("@/assets/icons/_images/14_redWarning.svg");
    top: -4px;
    right: -4px;
  }

}

.innerIcon {
  position: absolute;
  background: rgba(0, 0, 0, 0);
  width: 48px;
  height: 48px;
  left: -12px;
  top: -12px;
  padding: 10px;
  border: 2px solid rgba(231, 94, 94, 0);
  border-radius: 12px;
  box-shadow: 0 0 16px 0 rgba(231, 94, 94, 0);

  &.active {
    background: var(--app-accent-light-color);
    width: 48px;
    height: 48px;
    left: -12px;
    top: -24px;
    padding: 10px;
    border: 2px solid rgba(231, 94, 94, 0.24);
    border-radius: 12px;
    box-shadow: 0 0 16px 0 rgba(231, 94, 94, 0.3);
  }
}
</style>
