<template>
  <div
    v-if="!isStandalone && isAppleMobile && (isAppleMobile[0] < 16 || (isAppleMobile[0] === 16 && isAppleMobile[1] < 4))"
    class="main_container"
  >
    <Card
      style="margin-bottom: 16px"
    >
      <div
        style="display: flex;gap: 8px"
      >
        <Icon
          style="background-size: cover; flex-shrink: 0"
          width="36"
          height="36"
          :icon="require(`@/assets/icons/_images/16_redCircleError.svg`)"
        />
        <div>
          <Typography
            variant="subtitle"
            style="margin-bottom: 4px"
          >
            Уведомления не приходят
          </Typography>
          <Typography
            variant="body1"
          >
            Твоя версия iOS младше, чем 16.4
            Обнови iOS, чтобы получать уведомления от приложения
          </Typography>
        </div>
      </div>
    </Card>
  </div>

  <InstructionsInstallPWA />
</template>

<script setup>
import Icon from '@/components/UI/Icon.vue'
import {pwaUtils} from '@/utils/pwaUtils.js'
import Typography from '@/components/UI/Typography.vue'
import Card from '@/components/UI/Card.vue'
import InstructionsInstallPWA from "@/views/system/InstructionsInstallPWA.vue";

const isAppleMobile = pwaUtils.isAppleMobile()
const isAndroidMobile = pwaUtils.isAndroidMobile()
const isStandalone = pwaUtils.isStandalone()

</script>

<style lang="scss" scoped>

.main_container {

}

</style>
