<template>
  <div
    class="answer"
    :class="{
      showsResult: showsResult,
      isSelected: isSelected,
      correct: isCorrect,
      incorrect: !isCorrect,
    }"
  >
    <div
      class="check"
      :class="{[variant]: variant}"
    >
      <div
        class="checkBackground"
      />
      <template
        v-if="isSelected"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <template
            v-if="!showsResult || isCorrect"
          >
            <path
              class="stroke"
              d="M15.4113 10L11.4072 14.0017L9 11.6017"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </template>
          <template
            v-else
          >
            <path
              class="stroke"
              d="M14.8299 9.16992L9.16992 14.8299"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              class="stroke"
              d="M14.8299 14.8299L9.16992 9.16992"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </template>
        </svg>
      </template>
    </div>

    <LessonContentHTML
      :data="answer"
      :background="'transparent'"
    />
  </div>
</template>

<script setup>
import LessonContentHTML from '@/views/study/lessonContent/LessonContentHTML.vue'
import {computed} from 'vue'

const props = defineProps({
  variant: {
    type: String, //
    required: true,
  },
  answer: {
    type: Object,
    required: true,
  },
  showsResult: {
    type: Boolean,
    required: true,
  },
  isSelected: {
    type: Boolean,
    required: true,
  },
})

const isCorrect = computed(() => {
  return (props.answer.is_correct && props.isSelected) || (!props.answer.is_correct && !props.isSelected)
})

</script>

<style lang="scss" scoped>
.answer {
  transition: background-color ease .3s, border-color ease .3s, color ease .3s;
  padding: 16px;
  border-radius: 16px;
  background: var(--card-secondary-background);
  border: 1px solid #222222;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: var(--text-primary-color);

  &:hover:not(.showsResult) {
    background: #393939;
  }

  &:active:not(.showsResult) {
    background: #2C2B2B;
  }

  .stroke {
    transition: stroke ease .3s;
  }

  &.isSelected:not(.showsResult) {
    background: var(--card-secondary-background);
    border-color: #222222;
    .stroke {
      stroke: #222222;
    }
    .checkBackground {
      background: #fff;
      border-color: #fff;
    }

    &:hover:not(.showsResult) {
      background: #393939;
    }

    &:active:not(.showsResult) {
      background: #2C2B2B;
    }
  }

  &.showsResult.incorrect {
    background: var(--card-secondary-background);
    border-color: #20B537;
    .stroke {
      stroke: transparent;
    }
    .checkBackground {
      background: transparent;
      border-color: var(--text-notice-color);
    }
  }

  &.showsResult.isSelected.correct {
    background: rgba(32, 181, 55, 0.4);
    border-color: #20B537;
    .stroke {
      stroke: #fff;
    }
    .checkBackground {
      background: #20B537;
      border-color: #20B537;
    }

    &:hover:not(.showsResult) {
      background: rgba(32, 181, 55, 0.5);
    }

    &:active:not(.showsResult) {
      background: rgba(32, 181, 55, 0.3);
    }
  }

  &.showsResult.isSelected.incorrect {
    background: rgba(255, 48, 58, 0.4);
    border-color: var(--text-error-color);
    .stroke {
      stroke: #fff;
    }
    .checkBackground {
      background: var(--text-error-color);
      border-color: var(--text-error-color);
    }

    &:hover:not(.showsResult) {
      background: #cd3535;
    }

    &:active:not(.showsResult) {
      background: #C52828;
    }
  }
}

.check {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .checkBackground {
    transition: background-color ease .3s, border-color ease .3s, color ease .3s;
    position: absolute;
    z-index: 1;
    background: transparent;
    width: 18px;
    height: 18px;
    border: 2px solid var(--text-notice-color);
    border-radius: 9px;
  }

  svg {
    position: relative;
    z-index: 2;
  }

  &.multi_answer {
    .checkBackground {
      border-radius: 5px;
    }
  }
}

</style>
