<template>
  <div
    class="wrapper"
  >
    <head-new
      page_title="Мои продукты"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'Profile'})"
    />
    <div
      class="main_container"
    >
      <div
        class="form"
      >
        <Card
          class="info"
        >
          <Icon
            class="icon"
            width="20"
            height="20"
            :icon="require(`@/assets/icons/user_20_showcase.svg`)"
          />
          <Typography
            variant="body1"
          >
            Добавляй бесплатные продукты и становись популярным экспертом на платформе
          </Typography>
        </Card>

        <Typography
          variant="body1"
          center
          style="margin-bottom: 16px"
        >
          Максимум можно публиковать 3 продукта
        </Typography>

        <AppButton
          v-if="state.productsList.length <= 2 && !state.isLoading"
          full-width
          variant="primary"
          size="large"
          style="margin-bottom: 24px"
          @click="createProduct"
        >
          {{ ct('Action.Add') }}
        </AppButton>
      </div>

      <template
        v-if="!state.isLoading"
      >
        <div
          v-if="state.productsList.length"
          class="productList"
        >
          <Card
            v-for="item in state.productsList"
            class="product"
          >
            <div
              v-if="item.preview_image"
              class="imgWrapper"
              :style="{height: `${((Math.min(width, 480) - 64) / 1.8)|0}px`}"
            >
              <div
                class="img"
                :style="{
                  backgroundImage: urlToCssURL(imagesLinkCache.cache(item.preview_image)),
                }"
              />
            </div>

            <div
              class="deleteButton"
            >
              <AppButton
                variant="transparent"
                class="removeBtn"
                @click="state.isDeleteId = item.id"
              >
                <Icon
                  :icon="require(`@/assets/icons/gray_16_trash.svg`)"
                />
              </AppButton>
            </div>

            <Typography
              variant="subtitle2"
              :color="productsStatus[subscriptionStatus === subscriptionStatuses.active ? item.status : 'draft'].color"
              style="margin-bottom: 4px"
            >
              {{ productsStatus[subscriptionStatus === subscriptionStatuses.active ? item.status : 'draft'].title }}
            </Typography>

            <Typography
              variant="text1"
            >
              Бесплатный продукт
            </Typography>

            <Typography
              variant="subtitle1"
              class="productTitle"
            >
              {{ item.title }}
            </Typography>

            <Typography
              variant="body1"
              class="productText"
              style="white-space: pre-wrap;"
            >
              {{ item.description }}
            </Typography>

            <appButton
              full-width
              variant="secondary"
              @click="editProduct(item.id)"
            >
              {{ ct('Action.Edit') }}
            </appButton>
          </Card>
        </div>
        <div
          v-else
          class="middleText"
        >
          <div>
            <Typography
              variant="subtitle"
            >
              Здесь будут твои продукты
            </Typography>

            <div
              style="height: 8px"
            />

            <Typography
              variant="body1"
              color="secondary"
            >
              Добавляй их и привлекай новых клиентов
            </Typography>
          </div>

          <AppButton
            v-if="state.productsList.length < 3 && state.productsList.length"
            full-width
            variant="primary"
            size="large"
            style="margin-top: 24px"
            @click="createProduct"
          >
            {{ ct('Action.Add') }}
          </AppButton>
        </div>
      </template>

      <BottomDrawer
        :is-shown="state.isDeleteId"
        title="Удаление продукта"
        @close="state.isDeleteId = null"
      >
        <Typography
          variant="body1"
          color="secondary"
        >
          Ты точно хочешь удалить этот продукт?
        </Typography>
        <div
          class="modalButtons"
        >
          <appButton
            variant="secondary"
            @click="state.isDeleteId = null"
          >
            Оставить
          </appButton>

          <appButton
            variant="danger"
            @click="deleteProduct(state.isDeleteId)"
          >
            {{ ct('Action.Delete') }}
          </appButton>
        </div>
      </BottomDrawer>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {computed, onBeforeMount, onMounted, reactive, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Typography from '@/components/UI/Typography.vue'
import Icon from '@/components/UI/Icon.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {appAxios} from '@/axios'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import {useRoute} from "vue-router"
import {productsStatus} from "@/configs/productsStatus.js";
import store from "@/store/store.js";
import {subscriptionStatuses} from '@/configs/subscriptionStatuses.js'

import {useWindowSize} from '@vueuse/core'
import {ct} from '../../../locales/i18nextInit.js'
const {width, height} = useWindowSize()

const route = useRoute()

const subscriptionStatus = computed(() => store.getters["user/subscriptionStatus"])

const state = reactive({
  isLoading: true,
  productsList: [],
  isDeleteId: null,
})

onBeforeMount(async () => {
  try {
    const res = await appAxios.products.fetchMyAll()
    state.productsList = res.data
  } catch (e) {
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

function createProduct() {
  router.push({
    name: route.meta.isCheckList ? 'CheckListCreateProduct' : 'CreateProduct',
  })
}

function editProduct(id) {
  router.push({
    name: route.meta.isCheckList ? 'CheckListEditProduct' : 'EditProduct',
    params: {productId: id},
  })
}

async function deleteProduct(id) {
  const res = await appAxios.products.remove({id})

  const resResult = await appAxios.products.fetchMyAll()

  state.productsList = resResult.data

  await store.commit('user/setProfile', {
    products: resResult.data,
  })

  state.isDeleteId = null
  // await router.push({name: 'test'})
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.wrapper {
  min-height: 100vh;
  min-height: 100svh;
  max-height: 100vh;
  max-height: 100svh;
  display: flex;
  flex-direction: column;
  padding-bottom: $homeLayoutPaddingBottom + 56px;
  margin-bottom: -$homeLayoutPaddingBottom;
  overflow: auto;
}

.main_container {
  position: relative;
}

.middleText {
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.info {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;

  & > .icon {
    flex-shrink: 0;
  }
}

.productList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.product {
  position: relative;
}

.productTitle {
  margin-top: 4px;
}

.productText {
  margin-bottom: 16px;
  margin-top: 4px;
}

.removeBtn {
  position: absolute;
  right: -12px;
  top: -14px;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
  }
}

.imgWrapper {
  width: 100%;
  border-radius: 8px;
  background: var(--app-skeleton-color);
  margin-bottom: 12px;
  display: flex;

  .img {
    width: 100%;
    flex-shrink: 0;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &.full {
    margin: -16px -16px 0 -16px;
    border-radius: 16px 16px 0 0;
    width: calc(100% + 32px);
    height: 160px;
    margin-bottom: 4px;

    .img {
      height: 160px;
      border-radius: 16px 16px 0 0;
    }
  }
}

.deleteButton {
  position: relative;
}
</style>
