import {sleep} from "@/utils/sleep.js";
import {settings} from '@/configs/settings.js'

const rawYandexWebVisorNum = settings.env.VUE_APP_YANDEX_WEBVISOR
export const yandexWebVisorNum = rawYandexWebVisorNum ? Number(settings.env.VUE_APP_YANDEX_WEBVISOR) : null

export function yandexMetrika(type, value, params) {
  void async function () {
    if (!yandexWebVisorNum) {
      return
    }
    while (!window.ym) {
      await sleep(1e3)
    }
    window.ym(yandexWebVisorNum, type, value, params)
  }()
}
