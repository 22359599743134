<script setup>
import {onMounted, reactive, ref, toRef, watch} from 'vue'
import {useField} from 'vee-validate'
import {vMaska} from "maska"
import {iosTelegramFix} from '@/appType/telegram/iosTelegramFix.js'

const textAreaRef = ref()

const emit = defineEmits(['keyup', 'keydown', 'inputRef'])

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  inputmode: {
    type: String,
    default: undefined,
  },
  textInfo: {
    type: String,
  },
  rows: {
    type: Number,
    default: 1,
  },
  maxRows: {
    type: Number,
    default: 8,
  },
  onlyInput: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onKeydown: {
    type: Function,
    default: undefined,
  },
  // mask
  mask: {
    type: String,
    default: '',
  },
  pattern: {
    type: String,
    default: '',
  },
  iosTelegramFix: {
    type: Object,
    default: {
      top: 0,
      bottom: 0,
    },
  }
})


// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const state = reactive({
  grownRow: 0,
})

function handle(event) {
  event.target.parentNode.dataset.replicatedValue = event.target.value
  handleChange(event)
}

watch(textAreaRef, ref => {
  emit('inputRef', ref)
})

</script>

<template>
  <div
    class="textInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid, fullWidth: props.fullWidth }"
  >
    <label
      v-if="!onlyInput"
      :for="name"
    >
      {{ label }}
    </label>
    <div
      class="inputWrapper"
    >
      <div
        class="growArea"
        :style="{minHeight: `${30 + 20 * props.rows}px`}"
      >
        {{ inputValue + ' ' }}
      </div>
      <textarea
        :id="name"
        ref="textAreaRef"
        v-maska
        :name="name"
        :value="inputValue"
        :placeholder="placeholder"
        :inputmode="inputmode"
        :data-maska="mask"
        :pattern="pattern"
        :disabled="props.disabled"
        :data-iosTelegramFixTop="iosTelegramFix.isActive ? props.iosTelegramFix.top : undefined"
        :data-iosTelegramFixBottom="iosTelegramFix.isActive ? props.iosTelegramFix.bottom : undefined"
        @input="handle"
        @blur="handleBlur"
        @keydown="onKeydown"
      />
    </div>
    <!--    v-show="errorMessage || meta.valid"-->
    <div
      v-if="textInfo && !onlyInput"
      class="textInfo"
    >
      {{ textInfo }}
    </div>
    <div
      v-if="!onlyInput"
      class="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/variables";

.textInput {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.fullWidth {
    width: 100%;
  }

  label {
    color: var(--form-label-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    width: 100%;
    position: relative;

    textarea {
      display: block;
      width: 100%;
      border-radius: 12px;
      color: var(--text-secondary-color);
      border: 1px solid var(--form-border-base-color);
      background-color: var(--form-background-color, transparent);
      font-size: 16px;
      font-weight: 400;
      padding: 14px 12px;

      &::placeholder {
        color: #838181;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }

      &:focus {
        border: 1px solid var(--form-border-active-color);
      }
    }
  }

  .inputError {
    border: 1px solid var(--form-border-active-color) !important;
  }

  .errorMessage {
    color: var(--text-error-color);
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .textInfo {
    color: #838181;
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .error_icon {
    position: absolute;
    top: calc(50%);
    right: 12px;
    transform: translateY(-50%);
    z-index: 100000;
  }
}

.TextInput input {
  color: #fff;
  border: 1px solid var(--form-border-base-color);
  background: yellow;

  &:focus {
    border: 1px solid var(--form-border-active-color);
  }
}

.inputWrapper > textarea,
.inputWrapper > .growArea {
  display: block;
  border-radius: 12px;
  color: var(--text-secondary-color);
  border: 1px solid var(--form-border-base-color);
  font-size: 16px;
  font-weight: 400;
  padding: 14px 12px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.inputWrapper > .growArea {
  visibility: hidden;
}

.inputWrapper > textarea {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

</style>
