export const studentCoursesStatuses = {
  deleted: 'deleted',
  active: 'active',
  unpaid: 'unpaid',
}

export const coursesStatuses = {
  deleted: 'deleted',
  draft: 'draft',
  active: 'active',
  inactive: 'inactive',
}
