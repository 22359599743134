<template>
  <div
    class="pageWrapper"
  >
    <HeadNew
      :title="t('calendar:ListTitle')"
      :back-action="() => router.push({name: 'Home'})"
      :right-action="() => state.isCalendarShown = true"
      :right-icon="icons.calendar"
    />

    <Event
      :event="state.selectedEvent"
      @close="() => state.selectedEvent = null"
    />

    <ModalDialog
      :is-shown="state.isCalendarShown"
      :title="t('calendar:CalendarTitle')"
      :has-title-close="true"
      @close="state.isCalendarShown = false"
    >
      <Calendar
        v-if="!state.isLoading"
        style="padding-top: 0"
        :events-list="state.eventsList"
      />
    </ModalDialog>

    <div
      v-if="props.date"
      style="display: flex; align-items: center; justify-content: center; gap: 12px; margin: 16px 0"
    >
      <AppButton
        variant="transparent"
        size="small"
        @click="prevDay"
      >
        <Icon
          rotate="90"
          :icon="require(`@/assets/icons/white_24_arrowDown.svg`)"
          height="20"
          width="20"
        />
      </AppButton>
      <Typography
        variant="v16-500"
        color="primary"
        style="text-transform: capitalize"
      >
        {{ new Date(props.date).toLocaleDateString() }}
      </Typography>
      <AppButton
        variant="transparent"
        size="small"
        @click="nextDay"
      >
        <Icon
          rotate="270"
          :icon="require(`@/assets/icons/white_24_arrowDown.svg`)"
          height="20"
          width="20"
        />
      </AppButton>
    </div>
    <div
      v-else
      style="margin-top: 24px"
    />

    <div
      class="main_container pageWrapperOverflow"
    >
      <Card
        style="margin-bottom: 16px"
      >
        <Typography
          variant="v18-700"
          color="#ffff"
          style="margin-bottom: 8px"
        >
          {{ t('calendar:AddEventTitle') }}
        </Typography>

        <Typography
          variant="v14-400"
          color="secondary"
        >
          {{ t('calendar:AddEventText') }}
        </Typography>

        <AppButton
          full-width
          style="margin-top: 16px"
          @click="addEvent"
        >
          {{ t('calendar:Action.Add') }}
        </AppButton>
      </Card>

      <div
        v-for="event in state.filteredList"
        style="margin-bottom: 8px"
      >
        <EventCard
          :data="event"
          @click="openEvent(event)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios.js'
import moment from 'moment'
import HeadNew from '@/components/Head.vue'
import {openExternalURL} from '@/utils/openExternalURL.js'
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Icon from '@/components/UI/Icon.vue'
import EventCard from '@/views/HomeView/EventCard.vue'
import {yandexMetrika} from '@/external/yandexWebisor.js'
import ModalDialog from '@/components/UI/ModalDialog.vue'
import Calendar from '@/views/events/Calendar.vue'
import {router} from '@/router/router.js'
import Event from '@/views/HomeView/Event.vue'
import store from '@/store/store.js'
import {t} from 'i18next'
import {icons} from '@/assets/icon/icons.js'

const props = defineProps({
  date: {
    require: false,
  },
})

const state = reactive({
  isLoading: true,
  showEventOffer: false,
  selectedEvent: null,
  isCalendarShown: false,
  eventsList: [],
  filteredList: [],
})

onBeforeMount(async () => {
  const res = await appAxios.events.cacheAll()
  const eventsList = res.data

  state.eventsList = eventsList.sort(function (a, b) {
    return new Date(a.start_date) - new Date(b.start_date)
  })

  const filteredList = []

  for (const event of state.eventsList) {
    const {start_date, end_date} = event
    const start = moment(start_date, 'YYYY-MM-DD')
    const end = moment(end_date, 'YYYY-MM-DD')

    if(props.date) {
      const k = new Date(props.date).toLocaleDateString()
      const start = new Date(event.start_date)
      const end = new Date(event.end_date)
      while (start <= end) {
        const key = `${(new Date(start)).toLocaleDateString()}`
        if(key === k) {
          filteredList.push(event)
        }
        start.setDate(start.getDate() + 1)
      }
    } else {
      filteredList.push(event)
    }

    if (start.year() === end.year() && start.month() === end.month() && start.day() === end.day()) {
      event.dateSting = `${start.format('DD.MM')}`
    } else if (start.year() === end.year() && start.month() === end.month()) {
      event.dateSting = `${start.format('DD.MM')} - ${end.format('DD.MM')}`
    } else {
      event.dateSting = `${start.format('DD.MM')} - ${end.format('DD.MM')}`
    }
  }

  state.filteredList = filteredList
  state.isLoading = false
})

function prevDay() {
  const date = new Date(props.date)
  date.setDate(date.getDate() - 1)
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  router.push({
    name: 'EventsByDate',
    params: {date: `${year}-${month}-${day}`},
  })
}

function nextDay() {
  const date = new Date(props.date)
  date.setDate(date.getDate() + 1)
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  router.push({
    name: 'EventsByDate',
    params: {date: `${year}-${month}-${day}`},
  })
}

async function openEvent(event) {
  state.selectedEvent = event
  await yandexMetrika('reachGoal', 'eventOpen', {
    title: event.title,
  })
}

function addEvent() {
  if(!store.getters["user/profileProgress"].isEnoughFilled) {
    store.dispatch('app/setMessageComponent', {
      messageComponent: 'EnoughFilledRequired',
      messageComponentData: {text: 'Чтобы получить доступ к этой функции'},
    })
    return
  }
  openExternalURL('https://forms.yandex.ru/u/6704e0283e9d08bd0233645c/');
}

</script>

<style lang="scss" scoped>

.pageWrapper {
  min-height: calc(100svh);
  max-height: calc(100svh);
  min-height: calc(100vh);
  max-height: calc(100vh);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  padding-bottom: 24px;
  margin-bottom: 80px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

</style>
