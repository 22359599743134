import {declination} from '@/utils/declination.js'

export const subscriptionStatuses = {
  new: 'new',
  pending: 'pending',
  active: 'active',
  disabled: 'disabled',
}

export const paymentStatuses = {
  pending: 'pending',
  success: 'success',
  failed: 'failed',
}

export const subscriptionFeatures = {
  'Younesis Free': {
    // Твой тариф сейчас
    selection: [
      {
        type: 'check',
        text: 'Нетворкинг',
      },
      {
        type: 'check',
        text: 'Календарь мероприятий',
      },
      {
        type: 'check',
        text: 'Ярмарка бесплатных продуктов',
      },
    ],
  },
  'Younesis Base 1 месяц': {
    order: 1,
    selection: [
      {
        type: 'check',
        text: 'Всё что входит в тариф «Free»',
      },
      {
        type: 'check',
        text: 'Доступ к размещению бесплатных продуктов на ярмарке',
      },
    ],
    full: [
      {
        type: 'check',
        text: 'Нетворкинг',
      },
      {
        type: 'check',
        text: 'Календарь мероприятий',
      },
      {
        type: 'check',
        text: 'Доступ к размещению бесплатных продуктов на ярмарке',
      },
      {
        type: 'check',
        text: 'Доступ к размещению своих мероприятий в календаре',
      },
      {
        type: 'gray',
        text: 'Мероприятия должны пройти модерацию',
      },
      {
        type: 'check',
        text: 'Помощь в оформлении визитки',
      },
      {
        type: 'check',
        text: 'Помощь в оформлении бесплатных продуктов на ярмарке',
      },
      {
        type: 'check',
        text: 'Ярмарка бесплатных продуктов',
      },
    ],
  },
  'Younesis Base + 3 месяца': {
    order: 2,
    selection: [
      {
        type: 'check',
        text: 'Всё что входит в тариф «Base» со скидкой 20%',
      },
    ],
    full: [
      {
        type: 'check',
        text: 'Нетворкинг',
      },
      {
        type: 'check',
        text: 'Календарь мероприятий',
      },
      {
        type: 'check',
        text: 'Доступ к размещению бесплатных продуктов на ярмарке',
      },
      {
        type: 'check',
        text: 'Доступ к размещению своих мероприятий в календаре',
      },
      {
        type: 'gray',
        text: 'Мероприятия должны пройти модерацию',
      },
      {
        type: 'check',
        text: 'Помощь в оформлении визитки',
      },
      {
        type: 'check',
        text: 'Помощь в оформлении бесплатных продуктов на ярмарке',
      },
      {
        type: 'check',
        text: 'Ярмарка бесплатных продуктов',
      },
    ]
  },
  'Younesis Premium 12 месяцев': {
    order: 3,
    anotherName: 'Таня рекомендует!',
    selection: [
      {
        type: 'check',
        text: 'Всё что входит в тариф «Base»',
      },
      {
        type: 'plus',
        text: 'Доступ к легендарной <span style="color: #CF2E2E; font-weight: 700;">«Школе продюсера»</span> Татьяны Маричевой и всем предстоящим обновлениям',
      },
    ],
    full: [
      {
        type: 'check',
        text: 'Нетворкинг',
      },
      {
        type: 'check',
        text: 'Календарь мероприятий',
      },
      {
        type: 'check',
        text: 'Доступ к размещению бесплатных продуктов на ярмарке',
      },
      {
        type: 'check',
        text: 'Доступ к размещению своих мероприятий в календаре',
      },
      {
        type: 'gray',
        text: 'Мероприятия должны пройти модерацию',
      },
      {
        type: 'check',
        text: 'Помощь в оформлении визитки',
      },
      {
        type: 'check',
        text: 'Помощь в оформлении бесплатных продуктов на ярмарке',
      },
      {
        type: 'check',
        text: 'Ярмарка бесплатных продуктов',
      },
      {
        type: 'check',
        text: 'Доступ к легендарной <span style="color: #CF2E2E; font-weight: 700;">«Школе продюсера»</span> Татьяны Маричевой и всем предстоящим обновлениям',
      },
    ]
  },
}

export function singleSubscriptionPeriodLabel(type) {
  switch (type) {
  case 'day': return 'день'
  case 'month': return 'мес.'
  default: return type
  }
}

export function finalSubscriptionPeriodLabel(type, count) {
  switch (type) {
  case 'day': return `${count} ${declination('дней', 'день', 'дня', count)}`
  case 'month': {
    if(count === 12) {
      return declination('год', 'год', 'года', count)
    }
    return `${count} ${declination('месяцев', 'месяц', 'месяца', count)}`
  }
  default: return type
  }
}
