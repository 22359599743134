<template>
  <div
      :class="{hidden: state.hidden}"
      class="navigation"
  >
    <div
        class="main_container"
    >
      <router-link
          :to="{ name: 'Home'}"
          class="navLink"
          :class="{selected: route.meta.navigationTab === 'home', active: route.meta.navigationTab === 'home'}"
      >
        <HeaderHomeIcon
            class="headerIcon"
            :color="route.meta.navigationTab === 'home' ? selectedColor : defaultColor"
            :warning="true"
        />
        {{ ct('BottomNavigation.Home') }}
      </router-link>

      <router-link
          :to="{ name: 'CardHolder' }"
          class="navLink"
          :class="{selected: route.meta.navigationTab === 'social', active: route.meta.navigationTab === 'social'}"
      >
        <HeaderCardIcon
            class="headerIcon"
            :color="route.meta.navigationTab === 'social' ? selectedColor : defaultColor"
        />
        {{ ct('BottomNavigation.Cardholder') }}
      </router-link>

      <router-link
          :to="{ name: 'ChatList' }"
          class="navLink"
          :class="{selected: route.meta.navigationTab === 'chat', active: route.meta.navigationTab === 'chat'}"
      >
        <HeaderChatIcon
            class="headerIcon"
            :color="route.meta.navigationTab === 'chat' ? selectedColor : defaultColor"
        />
        {{ ct('BottomNavigation.Chat') }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import {useRoute} from 'vue-router'
import HeaderHomeIcon from '@/components/BottomAppBar/BottomAppBarHomeIcon.vue'
import HeaderChatIcon from '@/components/BottomAppBar/BottomAppBarChatIcon.vue'
import HeaderCardIcon from '@/components/BottomAppBar/BottomAppBarCardIcon.vue'
import {onMounted, onUnmounted, reactive} from 'vue'
import {ct} from '../../locales/i18nextInit.js'

const route = useRoute()

const state = reactive({
  hidden: false,
})

const theme = store.state.app.appData?.themes?.find(theme => theme.default)

const selectedColor = theme.styles.app['bar-icon-active-color'] ?? '#FFFFFF'
const defaultColor = theme.styles.app['bar-icon-color'] ?? '#8B8B8B'

function onFocus(e) {
  const tags = ['INPUT', 'TEXTAREA']
  const focused = e.target

  if (!focused) {
    return
  }

  if (tags.includes(focused.tagName)) {
    state.hidden = true
  }
}

function onBlur(e) {
  state.hidden = false
}

onMounted(() => {
  addEventListener("focus", onFocus, true)
  addEventListener("blur", onBlur, true)
})

onUnmounted(() => {
  removeEventListener("focus", onFocus)
  removeEventListener("blur", onBlur)
})

</script>

<style lang="scss">
@import "@/assets/variables";

.navigation {
  border-top: 1px solid var(--app-bar-border-color);
  background: var(--app-bar-color);
  display: flex;
  height: $bottomMenuOffset;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  flex-shrink: 0;

  .main_container {
    display: flex;
    padding: 12px 0 24px 0;

    & > .navLink {
      flex-basis: 100px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      gap: 6px;
      color: var(--app-bar-icon-color, #8B8B8B);

      &.selected {
        color: var(--app-bar-icon-active-color);
      }
    }
  }
}
</style>
