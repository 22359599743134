<template>
  <PinnedTopTemplate>
    <div
      class="main_container new_home_head"
    >
      <router-link
        :to="{ name: 'Profile'}"
        class="left_block"
      >
        <Avatar
          size="little"
          :image="store.state.user.profile.preview_avatar"
          :warning="!profileProgress.isEnoughFilled"
        />
        <div>
          <div
            class="name"
          >
            {{ store.state.user.profile.first_name }}
          </div>
          <div
            class="name"
          >
            {{ store.state.user.profile.last_name }}
          </div>
        </div>
      </router-link>

      <div
        class="space"
      />

      <div
        class="logo"
        :style="{backgroundImage: urlToCssURL(store.state.app.appIcons.logo128x58)}"
      />
    </div>

    <ModalDialog
      :is-shown="state.isSubscriptionShown"
      :title="'Подпишись на уведомления'"
      :has-title-close="true"
      @close="closeSubscriptionModal"
    >
      <Typography
        variant="v14-400"
      >
        Так ты не пропустишь сообщения в чатах и важные новости
      </Typography>
      <AppButton
        style="margin-top: 16px"
        full-width
        @click="allowSubscriptionModal"
      >
        Подписаться
      </AppButton>
    </ModalDialog>

    <!--  <div-->
    <!--      v-if="!state.isSubscriptionActive"-->
    <!--      class="main_container"-->
    <!--      style="margin-bottom: 16px"-->
    <!--  >-->
    <!--    <RouletteBanner/>-->
    <!--  </div>-->

    <div
      v-if="!profileProgress.isEnoughFilled"
      class="main_container"
    >
      <BlockCompleteProfile />
    </div>

    <div
      class="main_container"
    >
      <anons />
    </div>

    <div
      class="main_container"
      style="overflow: hidden"
    >
      <CollectionsBlock />
    </div>

    <div
      class="main_container"
      style="display: flex; justify-content: space-between; align-items: center; min-height: 36px; margin-top: 8px;"
    >
      <Typography
        variant="title"
        style="margin: 4px 0 4px"
      >
        {{ t('networking:Home.Title') }}
      </Typography>

      <AppButton
        v-if="state.specialists && state.specialists.length"
        style="padding: 0"
        variant="transparent"
        size="small"
        @click="() => router.push({name: 'SocialBrowsing'})"
      >
        {{ t('networking:Home.Start') }}
        <NextIcon
          width="20"
          height="20"
          rotate="270"
        />
      </AppButton>
    </div>

    <div
      class="main_container"
      style="padding: 0; margin-top: 8px; overflow: hidden"
    >
      <div
        class="main_container"
      >
        <swiper
          v-if="state.specialists === null"
          :allow-slide-next="false"
          :allow-slide-prev="false"
          :slides-per-view="'auto'"
          :space-between="8"
          :free-mode="true"
          class="specialists_swiper"
        >
          <swiper-slide
            class="slide"
          >
            <UserCard
              :is-loading="true"
              variant="small"
              :hide-actions="true"
            />
          </swiper-slide>
          <swiper-slide
            class="slide"
          >
            <UserCard
              :is-loading="true"
              variant="small"
              :hide-actions="true"
            />
          </swiper-slide>
        </swiper>
        <Card
          v-else-if="state.specialists.length === 0"
        >
          <Icon
            width="100%"
            height="138"
            :icon="require(`@/assets/images/home/emptyNetworking.png`)"
            style="background-size: contain; margin-bottom: 16px"
          />
          <Typography
            variant="subtitle3"
            color="var(--text-notice-color)"
            center
            style="margin: 0 32px; min-height: 40px"
          >
            Ты мастер нетворкинга! Скоро появятся новые люди, и мы тебе их покажем
          </Typography>
        </Card>
        <swiper
          v-else
          :slides-per-view="'auto'"
          :space-between="8"
          :free-mode="true"
          class="specialists_swiper"
        >
          <swiper-slide
            v-for="item of state.specialists"
            class="slide"
          >
            <UserCard
              variant="small"
              :data="item"
              :user-contact="item.userContact"
              :profile-click="() => router.push({name: 'SocialBrowsing', params: {userId: item.id}})"
              :hide-actions="true"
              @add-contact="user => router.push({name: 'SocialBrowsing', params: {userId: user.id}})"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <Events />

    <div
      style="height: 24px;"
    />
  </PinnedTopTemplate>
</template>

<script setup>
import Anons from "@/views/HomeView/Anons.vue"
import {Swiper, SwiperSlide} from 'swiper/vue'
import Avatar from '@/components/profile/Avatar.vue'
import store from '@/store/store'
import Typography from '@/components/UI/Typography.vue'
import UserCard from '@/components/UserCard.vue'
import {router} from '@/router/router'
// import RouletteBanner from '@/views/HomeView/roulette/RouletteBanner.vue'
import {computed, onBeforeMount, onUnmounted, reactive, ref, toRaw} from 'vue'
import Icon from '@/components/UI/Icon.vue'
import Events from '@/views/HomeView/Events.vue'
import {appAxios} from '@/axios'
import AppButton from "@/components/UI/AppButton.vue"
// import PWABlock from '@/views/system/PWABlock.vue'
import Card from "@/components/UI/Card.vue"
import {subscriptionStatuses} from "@/configs/subscriptionStatuses.js"
import ModalDialog from "@/components/UI/ModalDialog.vue"
import BlockCompleteProfile from '@/components/BlockCompleteProfile.vue'
import CollectionsBlock from '@/views/collections/CollectionsBlock.vue'
import {urlToCssURL} from '@/utils/urlToCssURL.js'
import PinnedTopTemplate from '@/layouts/PinnedTopTemplate.vue'
import {i18nextUseNamespace} from '@/locales/i18nextInit.js'
import {t} from 'i18next'
import BackIcon from '@/assets/icon/BackIcon.vue'
import NextIcon from '@/assets/icon/NextIcon.vue'

const networkingT = i18nextUseNamespace('networking')

const {profile, directories} = store.state.user

const profileProgress = computed(() => store.getters["user/profileProgress"])

const observerRef = ref(null)

// appAxios.collections.getById(1)

const state = reactive({
  isSubscriptionShown: false,
  specialists: null,
  subscription: null,
  isSubscriptionActive: false,
})

onBeforeMount(async () => {
  state.subscription = store.state.user.directories.subscription_price_list[0]
  const userSubscription = store.state.user.profile?.subscriptions[0]
  if (userSubscription && userSubscription.status === subscriptionStatuses.active) {
    state.isSubscriptionActive = true
  }

  await Promise.all([
    new Promise(async (res) => {
      const resContacts = await appAxios.networking.fetchRecommendations()
      state.specialists = resContacts.data
      // state.specialists = []
      res()
    }),
    // new Promise(async (res) => {
    //   const resContacts = await appAxios.myContacts.fetchAll()
    //   state.contactList = resContacts.data
    //   res()
    // }),
  ])

  //OneSignal.Notifications.isPushSupported()
  //permissionNative 'denied' 'default' 'granted'

  const targetNode = document.body
  const config = {attributes: false, childList: true, subtree: true}
  const callback = () => {
    const el = document.querySelector('#onesignal-slidedown-allow-button')
    state.isSubscriptionShown = !!el
  }
  const observer = new MutationObserver(callback)
  observerRef.value = observer
  observer.observe(targetNode, config)

  const countToNextTime = {
    1: 24 * 60 * 60 * 1e3,
    2: 3 * 24 * 60 * 60 * 1e3,
    default: 7 * 24 * 60 * 60 * 1e3,
  }
  const promptInfo = JSON.parse(localStorage.getItem('onesignal-notification-prompt-custom')) ?? {}
  if (!promptInfo.nextTime) {
    try {
      localStorage.removeItem('onesignal-notification-prompt')
      OneSignal.Slidedown.promptPush()
    } catch (e) {
      console.log('oneSignal prompt', e)
    }
    promptInfo.count = 1
    promptInfo.nextTime = Date.now() + (countToNextTime[promptInfo.count] ?? countToNextTime.default)
  }
  if (promptInfo.nextTime < Date.now()) {
    try {
      localStorage.removeItem('onesignal-notification-prompt')
      OneSignal.Slidedown.promptPush()
    } catch (e) {
      console.log('oneSignal prompt', e)
    }
    promptInfo.count++
    promptInfo.nextTime = Date.now() + (countToNextTime[promptInfo.count] ?? countToNextTime.default)
  }
  localStorage.setItem('onesignal-notification-prompt-custom', JSON.stringify(promptInfo))
})

function closeSubscriptionModal() {
  const el = document.querySelector('#onesignal-slidedown-cancel-button')
  if (el) {
    el.click()
  }
}

function allowSubscriptionModal() {
  const el = document.querySelector('#onesignal-slidedown-allow-button')
  if (el) {
    el.click()
  }
}

onUnmounted(() => {
  if (observerRef.value) {
    observerRef.value.disconnect()
    observerRef.value = null
  }
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.specialists {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
}

.marginTop {
  margin-top: 16px;
}

.browsingCard {
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 196px;
  cursor: pointer;
}


.new_home_head {
  display: flex;
  align-items: center;
  padding-top: $topOffset !important;
  //box-sizing: content-box;
  padding-bottom: 20px !important;

  .left_block {
    display: flex;
    align-items: center;
    gap: 9px;

    img.user_img {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    .name {
      color: var(--text-primary-color);
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    .money {
      position: relative;
      border-radius: 44px;
      //background: linear-gradient(90deg, #af7311 0%, rgba(175, 116, 17, 0.5) 100%);
      color: var(--text-primary-color);
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      padding: 3px 9px 3px 26px;

      img {
        position: absolute;
        left: -7px;
        top: -7px;
      }
    }
  }
}

.anons_block {
  //padding-top: 20px;
  padding-bottom: 8px;
  display: flex;
  align-items: stretch;
  gap: 12px;

  .link {
    width: calc(50% - 4px);
    border-radius: 20px;
    background: var(--card-secondary-background);
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:nth-child(2) {
      .icon {
        background: #38a0ff;
      }
    }
  }

  .icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #b61cff;
  }

  .title {
    color: var(--text-primary-color);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  .subtitle {
    color: var(--text-notice-color);
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
}

.tools {
  border-radius: 20px;
  background: var(--card-secondary-background);
  padding: 16px;
  margin: 16px auto;

  h2 {
    margin-bottom: 15px;
  }

  .tools_lists {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 8px;

    .tools_card {
      position: relative;
      border-radius: 12px;
      background: var(--theme-night-background-bg-card-04, var(--app-skeleton-color));
      padding: 12px;
      width: calc(50% - 4px);

      .title {
        color: var(--text-primary-color);
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        position: relative;
        z-index: 1;
      }

      &:nth-child(4) {
        position: relative;

        .bg_img {
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 12px;
          background-color: rgba(59, 59, 59, 0.6);
          background-image: url("@/assets/images/lines_bg.png");
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
      }
    }

    .alert {
      position: absolute;
      top: -4px;
      right: -4px;
      display: flex;
      width: 28px;
      height: 28px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background: #ff2e37;
      color: var(--text-primary-color);
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }

    .alert_2 {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 3;
      display: inline-flex;
      height: 25px;
      padding: 8px 4px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--card-secondary-background);
    }

    .img {
      display: flex;
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #cf3aa5;
      margin-bottom: 8px;
      position: relative;
      z-index: 1;
    }

    .img_2 {
      border-radius: 8px;
      background: #ffa723;
    }

    .img_3 {
      border-radius: 8px;
      background: #33bba3;
    }
  }
}

.event_calendar {
  .main_title {
    margin-bottom: 16px;
  }

  .subtitle {
    position: relative;
    padding-right: 7px;
    display: inline-block;
    margin-bottom: 14px;

    &::after {
      position: absolute;
      content: "";
      width: 100vw;
      height: 1px;
      background: var(--form-border-base-color);
      top: 50%;
      right: 0;
      transform: translate(100%, -50%);
    }
  }
}

.wheel-wrapper-top {
  width: 240px;
  height: 240px;
  padding: 16px;
  top: -115px;
  z-index: 4;
  transform: translate(-50%, 0);
}

.specialists_swiper {
  overflow: visible;
  height: 224px;
}

.slide {
  width: calc(70%);
  min-width: 290px;
}

.halfSlide {
  width: calc(35%);
}

.space {
  flex-grow: 1;
}

.logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: var(--logo-home-src);
  background-position: right center;
  height: var(--logo-home-height, 48px);
  width: var(--logo-home-width, 106px);
}

</style>

<style>
#onesignal-slidedown-container {
  visibility: hidden !important;
}
</style>
