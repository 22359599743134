<template>
  <div
    v-if="user"
    class="modal"
    :class="{fullHeight}"
  >
    <head-new
      :back-action="back"
      background-color="var(--background-main-color)"
    />

    <div
      class="pageWrapperOverflow"
    >
      <div
        class="main_container userData"
      >
        <Avatar
          size="large"
          :image="imagesLinkCache.cache(props.user.preview_avatar)"
        />
        <template
          v-if="!props.userContact?.contact_user"
        >
          <div
            class="userInfo"
          >
            <Typography
              variant="v16-700"
            >
              <div>{{ props.user.first_name }}</div>
              <div>{{ props.user.last_name }}</div>
            </Typography>
          </div>
        </template>
        <template
          v-else
        >
          <div
            class="userInfo"
          >
            <Typography
              v-if="!state.isLoading"
              variant="title"
            >
              {{ props.user?.first_name }} {{ props.user?.last_name }}
            </Typography>
            <AppButton
              v-if="!state.isLoading"
              variant="secondary"
              size="small"
              @click="startChat(props.user.id)"
            >
              <Icon
                :icon="require(`@/assets/icons/white_20_chat.svg`)"
              />
              {{ ct('Action.StartChat') }}
            </AppButton>
          </div>
        </template>
      </div>

      <div
        class="main_container"
      >
        <UserLocationAndAge
          :city="props.user.city"
          :birth-date="props.user.birth_date"
        />

        <UserSpecializations
          style="margin-bottom: 8px; margin-top: 12px;"
          :specializations="props.user.specializations"
        />

        <UserProfessions
          class="userProfile"
          :professions="props.user.professions"
        />

        <Typography
          pre-line
          variant="body1"
        >
          {{ props.user.about_me }}
        </Typography>
      </div>

      <div
        class="main_container"
      >
        <BlockNiches
          v-if="props.user.niches.length"
          :niches="props.user.niches"
        />

        <BlockLookingFor
          v-if="props.user.looking_for.length"
          :looking-for="props.user.looking_for"
          :is-active="false"
        />

        <BlockAchievements
          v-if="props.user.achievements.length"
          :achievements="props.user.achievements"
        />

        <BlockProducts
          v-if="props.user.products.length"
          :products="props.user.products"
        />

        <BlockEducations
          v-if="props.user.education[0] && (props.user.education[0].courses.length || props.user.education[0].communities.length || props.user.education[0].institutions.length)"
          :education="props.user.education"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive, watch} from 'vue'
import {useModalScrollingFix} from '@/hooks/useModalScrollingFix'
import {useModalHomeLayoutFix} from '@/hooks/useModalHomeLayoutFix'
import HeadNew from '@/components/Head.vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import UserLocationAndAge from '@/components/UserLocationAndAge.vue'
import Avatar from '@/components/profile/Avatar.vue'
import Typography from '@/components/UI/Typography.vue'
import UserProfessions from '@/components/UserProfessions.vue'
import BlockEducations from '@/components/BlockEducations.vue'
import BlockAchievements from '@/components/BlockAchievements.vue'
import BlockNiches from '@/components/BlockNiches.vue'
import BlockProducts from '@/components/BlockProducts.vue'
import BlockLookingFor from '@/components/BlockLookingFor.vue'
import UserSpecializations from "@/components/UserSpecializations.vue"
import AppButton from "@/components/UI/AppButton.vue"
import {router} from "@/router/router.js"
import Icon from "@/components/UI/Icon.vue"
import {useRoute} from "vue-router"
import {ct} from '../../locales/i18nextInit.js'

const route = useRoute()

const modalScrollingFix = useModalScrollingFix()
const modalHomeLayoutFix = useModalHomeLayoutFix()

const emit = defineEmits(['close'])

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
  userContact: {
    type: Object,
    default: null,
  },
  fullHeight: {
    type: Boolean,
    default: null,
  }
})

const state = reactive({
  dateSting: '',
  userContact: props.userContact,
  isAddingContactShown: false,
  isDeleting: false,
})

watch(() => props.user, (event) => {
  if (props.user) {
    modalScrollingFix.open()
    modalHomeLayoutFix.open()
  } else {
    modalScrollingFix.close()
    modalHomeLayoutFix.close()
  }
})

function back() {
  emit('close')
}

async function startChat(userId) {
  await router.push({
    name: 'SearchUserChat',
    params: {userId},
  })
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.modal {
  position: fixed;
  z-index: 1001;
  background: var(--background-main-color);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: calc(100vh - $bottomMenuOffset);
  min-height: calc(100svh - $bottomMenuOffset);
  max-height: calc(100vh - $bottomMenuOffset);
  max-height: calc(100svh - $bottomMenuOffset);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.fullHeight {
    min-height: calc(100vh);
    min-height: calc(100svh);
    max-height: calc(100vh);
    max-height: calc(100svh);
  }
}

.pageWrapperOverflow {
  //padding-bottom: 16px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.userData {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
}

.userProfile {
  margin-bottom: 8px;
  margin-top: 12px;
}

//.contacts {
//  display: flex;
//  flex-direction: column;
//  margin-bottom: 8px;
//
//  .contact {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    padding: 6px 0;
//    border-bottom: 1px solid #2B2B2B;
//  }
//
//  .contact:last-child {
//    border-bottom: none;
//  }
//
//  .test {
//    display: flex;
//    align-items: center;
//  }
//}

.productInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  //.imgWrapper {
  //  width: 100%;
  //  height: 116px;
  //  border-radius: 8px;
  //  background: var(--app-skeleton-color);
  //
  //  .img {
  //    width: 100%;
  //    height: 116px;
  //    flex-shrink: 0;
  //    border-radius: 8px;
  //    background-repeat: no-repeat;
  //    background-size: cover;
  //    background-position: center center;
  //  }
  //}

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .bottom {
    display: flex;
    gap: 8px;
    padding-top: 16px;
    margin-top: 4px;
    border-top: 1px solid #494949;
    align-items: center;

    & > * {
      flex-shrink: 0;
    }

    &.drawer {
      margin: 0 -16px;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.share {
  margin-top: 20px;
  margin-bottom: 12px;
}

</style>
