<template>
  <div
    class="headerIcon"
  >
    <div
      class="innerIcon"
      :class="{active}"
    >
      <svg
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.651 18.396C3.921 17.096 3.5 15.598 3.5 14C3.5 9.029 7.529 5 12.5 5C17.471 5 21.5 9.029 21.5 14C21.5 18.971 17.471 23 12.5 23C10.902 23 9.404 22.579 8.104 21.849L3.5 23L4.651 18.396Z"
          :stroke="color"
          stroke-width="1.5882"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div
        v-if="messages"
        class="messages"
      >
        {{ messages }}
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed} from "vue";
import store from "@/store/store.js";

const messages = computed(() => {
  const count = store.state.user?.profile?.unchecked_messages_count
  if (count) {
    const num = Number(count)
    if (num > 99) {
      return `99+`
    }
    return num
  }
  return null
})

const props = defineProps({
  color: {
    type: String,
    default: '#ffffff' // #8B8B8B
  },
  active: {
    type: Boolean,
    default: false,
  },
})

</script>

<style lang="scss" scoped>

.icon {
  position: relative;
  top: -2px;
}

.headerIcon {
  width: 24px;
  height: 24px;
  position: relative;
}

.messages {
  position: absolute;
  background: var(--text-error-color);
  height: 15px;
  min-width: 15px;
  top: 5px;
  right: 5px;
  padding: 1px 4px;
  border-radius: 15px;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: var(--text-primary-color);
}

.innerIcon {
  position: absolute;
  background: rgba(0, 0, 0, 0);
  width: 48px;
  height: 48px;
  left: -12px;
  top: -12px;
  padding: 10px;
  border: 2px solid rgba(231, 94, 94, 0);
  border-radius: 12px;
  box-shadow: 0 0 16px 0 rgba(231, 94, 94, 0);
  transition: all 1s linear;

  &.active {
    background: var(--app-accent-light-color);
    width: 48px;
    height: 48px;
    left: -12px;
    top: -24px;
    padding: 10px;
    border: 2px solid rgba(231, 94, 94, 0.24);
    border-radius: 12px;
    box-shadow: 0 0 16px 0 rgba(231, 94, 94, 0.3);
  }
}
</style>
