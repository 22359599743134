<template>
  <Card>
    <div
      class="calendarHeader"
    >
      <AppButton
        variant="darkGray"
        size="small"
        @click="prevMonth"
      >
        <Icon
          rotate="90"
          :icon="require(`@/assets/icons/white_24_arrowDown.svg`)"
          height="20"
          width="20"
        />
      </AppButton>
      <Typography
        variant="v16-600"
        color="secondary"
        style="text-transform: capitalize"
      >
        {{ new Intl.DateTimeFormat('ru-RU', {month: 'long'}).format(state.selectedMonth) }}
        {{ state.selectedMonth.getFullYear() }}
      </Typography>
      <AppButton
        variant="darkGray"
        size="small"
        @click="nextMonth"
      >
        <Icon
          rotate="270"
          :icon="require(`@/assets/icons/white_24_arrowDown.svg`)"
          height="20"
          width="20"
        />
      </AppButton>
    </div>
    <div
      class="week"
    >
      <div
        class="cell isWeekDay"
      >
        Пн
      </div>
      <div
        class="cell isWeekDay"
      >
        Вт
      </div>
      <div
        class="cell isWeekDay"
      >
        Ср
      </div>
      <div
        class="cell isWeekDay"
      >
        Чт
      </div>
      <div
        class="cell isWeekDay"
      >
        Пт
      </div>
      <div
        class="cell isWeekDay"
      >
        Сб
      </div>
      <div
        class="cell isWeekDay"
      >
        Вс
      </div>
    </div>

    <div
      style="margin-bottom: -12px"
    >
      <div
        v-for="week in state.weeks"
        class="week"
      >
        <div
          v-for="date in week"
          class="cell"
          :class="{
            isToday: date.isToday,
            isAnotherMonth: !date.isCurrentMonth,
            hasEvents: date.events?.length,
          }"
          @click="openEvent(date)"
        >
          {{ date.day }}
        </div>
      </div>
    </div>
  </Card>
</template>

<script setup>
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Icon from '@/components/UI/Icon.vue'
import Typography from '@/components/UI/Typography.vue'
import {onBeforeMount, reactive, watch} from 'vue'
import {router} from '@/router/router.js'

const props = defineProps({
  eventsList: {
    type: Array,
    require: true,
  },
})

const state = reactive({
  selectedMonth: new Date(),
  weeks: [],
  eventsByDate: {},
})

watch(() => state.selectedMonth, () => {
  calcDays()
})

onBeforeMount(() => {
  const eventsByDate = {}
  props.eventsList.forEach(event => {
    const start = new Date(event.start_date)
    const end = new Date(event.end_date)
    while (start <= end) {
      const key = `${(new Date(start)).toLocaleDateString()}`
      if (!eventsByDate[key]) {
        eventsByDate[key] = []
      }
      eventsByDate[key].push(event)
      start.setDate(start.getDate() + 1)
    }
  })
  state.eventsByDate = eventsByDate
  calcDays()
})

function prevMonth() {
  const now = state.selectedMonth
  const year = now.getFullYear()
  const month = now.getMonth() // (0 - январь, 11 - декабрь)
  state.selectedMonth = new Date(year, month, 0)
}

function nextMonth() {
  const now = state.selectedMonth
  const year = now.getFullYear()
  const month = now.getMonth() // (0 - январь, 11 - декабрь)
  state.selectedMonth = new Date(year, month + 2, 0)
}

function calcDays() {
  const dayToday = (new Date()).getDate()
  const yearToday = (new Date()).getFullYear()
  const monthToday = (new Date()).getMonth()

  const date = state.selectedMonth
  const year = date.getFullYear()
  const month = date.getMonth() // (0 - январь, 11 - декабрь)

  const daysInMonth = new Date(year, month + 1, 0).getDate()
  const currentMonthDays = []

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, month, day)
    currentMonthDays.push({
      events: state.eventsByDate[date.toLocaleDateString()],
      isCurrentMonth: true,
      isToday: dayToday === day && monthToday === month && yearToday === year,
      date,
      day,
    });
  }

  let prevMonthDays = []
  let startDay = new Date(year, month, 1).getDay()
  if (startDay !== 1) {
    let needDays = 6
    if (startDay === 0) {
      // fine
    } else {
      needDays = Math.abs(1 - startDay)
    }
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevYear = month === 0 ? year - 1 : year;
    const daysInPrevMonth = new Date(prevYear, prevMonth + 1, 0).getDate()
    prevMonthDays = Array.from(
      {length: needDays},
      (_, i) => {
        const date = new Date(prevYear, prevMonth, daysInPrevMonth + i - needDays + 1)
        return {
          events: state.eventsByDate[date.toLocaleDateString()],
          isCurrentMonth: false,
          isToday: false,
          day: daysInPrevMonth + i - needDays + 1,
          date,
        }
      }
    )
  }

  let nextMonthDays = []
  startDay = new Date(year, month, daysInMonth).getDay()

  if (startDay !== 0) {
    let needDays = Math.abs(7 - startDay)
    nextMonthDays = Array.from(
      {length: needDays},
      (_, i) => {
        const date = new Date(year, month, daysInMonth + i + 1)
        return {
          events: state.eventsByDate[date.toLocaleDateString()],
          isCurrentMonth: false,
          isToday: false,
          day: i + 1,
          date,
        }
      }
    )
  }

  const days = [
    ...prevMonthDays,
    ...currentMonthDays,
    ...nextMonthDays,
  ]

  const weeks = [];
  for (let i = 0; i < days.length; i += 7) {
    weeks.push(days.slice(i, i + 7));
  }

  state.weeks = weeks
}

function openEvent({date}) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы с 0, поэтому прибавляем 1
  const day = date.getDate().toString().padStart(2, '0'); // Добавляем ведущие нули, если день < 10
  router.push({
    name: 'EventsByDate',
    params: {date: `${year}-${month}-${day}`},
  })
}

</script>


<style lang="scss" scoped>
.calendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.week {
  display: flex;
  justify-content: space-between;
}

.cell {
  width: 32px;
  height: 32px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  cursor: pointer;

  &.isWeekDay {
    font-weight: 600;
    color: #D3D1D2;
  }

  &.isAnotherMonth {
    color: #838181;
  }

  &.hasEvents {
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background: var(--app-accent-light-color);
      bottom: 2px;
    }
  }

  &.isToday {
    background: var(--app-accent-light-color);
    border-radius: 8px;

    &:after {
      background: #FFF;
    }
  }
}

</style>
