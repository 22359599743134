<template>
  <div
    class="pageWrapper"
  >
    <head-new
      :back-action="() => router.push({name: 'Home'})"
      title="Чек-лист"
    />
    <div
      class="main_container"
      style="padding-bottom: 32px; margin-top: 16px"
    >
      <!--      <Typography center variant="body1">-->
      <!--        Заполни недостающие разделы, чтобы открыть доступ к ярмарке и нетворкингу-->
      <!--      </Typography>-->

      <template
        v-for="field in progress.fields"
      >
        <div
          class="stepWrapper"
        >
          <div
            class="steps"
          >
            <Typography
              variant="body2"
            >
              {{ field.title }}
            </Typography>
            <Typography
              v-if="field.filled !== field.count"
              variant="body2"
              style="flex-shrink: 0; align-self: flex-start;"
            >
              {{ field.filled }} из {{ field.count }}
            </Typography>
            <span
              v-else
              class="check checked"
            />
          </div>
          <BarProgress
            :completed="field.filled"
            :bar-count="field.count"
            style="margin-bottom: 16px"
          />
          <div
            class="buttons"
          >
            <template
              v-for="item in field.items"
            >
              <OnboardingItem
                :right-icon="require(`@/assets/icons/white_24_arrowDown.svg`)"
                :checked="item.isFilled"
                :text="item.title"
                :action="() => router.push(item.route)"
              />
            </template>
          </div>
        </div>
      </template>

      <div
        style="height: 46px"
      />
    </div>

    <div
      class="applyWrapper"
    >
      <div
        class="main_container"
      >
        <AppButton
          class="submitButton"
          full-width
          @click="() => router.push({name: 'Home'})"
        >
          Приступить
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import Icon from "@/components/UI/Icon.vue"
import Typography from "@/components/UI/Typography.vue"
import BarProgress from "@/components/BarProgress.vue"
import OnboardingItem from "@/views/profile/onboarding/OnboardingItem.vue"
import {router} from "@/router/router.js"
import {computed, onBeforeMount, reactive, watch} from "vue"
import store from "@/store/store.js";
import AppButton from "@/components/UI/AppButton.vue";

const state = reactive({})

const progress = computed(() => {
  const progress = store.getters["user/profileProgress"]
  const fields = []

  // if (progress.isEnoughFilled) {
  //   fields.push({
  //     title: 'Для максимального результата',
  //     items: progress.fullFields,
  //     filled: progress.fullFilledCount,
  //     count: progress.fullFields.length,
  //   })
  //   fields.push({
  //     title: 'Открыт доступ ко всем функциям!',
  //     items: progress.enoughFields,
  //     filled: progress.enoughFilledCount,
  //     count: progress.enoughFields.length,
  //   })
  // }
  // else {
  fields.push({
    title: 'Заполни недостающие разделы, чтобы открыть доступ к ярмарке и нетворкингу',
    items: progress.enoughFields,
    filled: progress.enoughFilledCount,
    count: progress.enoughFields.length,
  })
  // fields.push({
  //   title: 'Для максимального результата',
  //   items: progress.fullFields,
  //   filled: progress.fullFilledCount,
  //   count: progress.fullFields.length,
  // })
  // }
  return {
    progress,
    fields,
  }
})

onBeforeMount(() => {
  if (progress.value.progress.isFullFilled) {
    router.replace({name: 'Profile'})
  }
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: 100svh;
  max-height: 100svh;
  display: flex;
  flex-direction: column;
}

//.pageWrapperOverflow {
//  padding-top: 64px;
//  padding-bottom: 32px;
//  overflow-y: auto;
//}

.absolute {
  position: absolute;
  width: 100%;
}

.greenCrossWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

  .greenBackground {
    position: absolute;
    background: radial-gradient(rgba(86, 231, 118, 0.35) 0%, rgba(86, 231, 118, 0.075) 35%, rgba(0, 0, 0, 0) 60%);
    width: 200px;
    height: 200px;
  }

  .grayBackground {
    position: absolute;
    background: radial-gradient(rgba(168, 168, 168, 0.15) 0%, rgba(168, 168, 168, 0.15) 20%, rgba(168, 168, 168, 0.05) 40%, rgba(0, 0, 0, 0) 60%);
    width: 200px;
    height: 200px;
  }

  .circle {
    background: rgba(86, 231, 118, 0.16);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    &.s1 {
      width: 77px;
      height: 77px;
    }

    &.s2 {
      width: 65px;
      height: 65px;
    }

    &.s3 {
      width: 52px;
      height: 52px;
    }
  }
}

.stepWrapper {
  background: var(--card-secondary-background);
  padding: 16px;
  border-radius: 12px;
  margin-top: 8px;
  margin-bottom: 16px;

  .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.check {
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    width: 14px;
    height: 14px;
    margin: 3px;
    border-radius: 24px;
    border: 2px solid #D3D2D2;
  }

  &.checked {
    &:before {
      background: #20B537;
      border: 2px solid #20B537;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      display: block;
      width: 24px;
      height: 24px;
      background: url("@/assets/icons/white_24_checkBox.svg") no-repeat;
      background-size: contain;
    }
  }
}

.applyWrapper {
  //margin: 0 -16px;
  width: calc(100%);
  background: red;
  border-top: 1px solid var(--divider-color);
  background: var(--app-background-color);
  position: fixed;
  bottom: 0;
  //width: calc(100% - 32px);
  //max-width: 448px;
  padding: 16px 0;
}


</style>
