
const settings = {
  env: {},
}

const currentURL = new URL(window.location.href)
const socketURL = new URL(window.location.href)
socketURL.protocol = 'wss'

const envs = import.meta.env

const jsonEnvs = {
  VUE_APP_VERSION: 'undefined',
  VUE_APP_API_URL: `${currentURL.protocol}//${currentURL.host}`,
  VUE_APP_WS_URL: `${socketURL.protocol}//${socketURL.host}`,
  VUE_APP_ONE_SIGNAL_APP_ID: '',
  VUE_APP_YANDEX_WEBVISOR: '',
  VUE_APP_SENTRY_DSN: '',
  VUE_APP_SENTRY_PROPAGATION_TARGET: '',
  VUE_APP_IS_DEVELOPMENT: false,
  VUE_APP_DISABLE_PWA_REQUIRED: false,
  VUE_APP_FPS_COUNTER: false,
}

Object.entries(jsonEnvs).forEach(([key, defaultValue]) => {
  settings.env[key] = defaultValue
  try {
    const json = JSON.parse(envs[key])
    if (json !== undefined && json !== null) {
      settings.env[key] = json
    }
  } catch (e) {
    if (envs[key]) {
      settings.env[key] = envs[key]
    }
  }
})

export { settings }
