<script setup>
import {nextTick, reactive, ref, toRef, watch} from 'vue'
import {useField} from 'vee-validate'
import {vMaska} from "maska"
import AppButton from '@/components/UI/AppButton.vue'
import Icon from '@/components/UI/Icon.vue'
import {useFocus} from "@vueuse/core"
import {iosTelegramFix} from '@/appType/telegram/iosTelegramFix.js'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  onlyInput: {
    type: Boolean,
    default: false,
  },
  isFilters: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  inputmode: {
    type: String,
    default: undefined,
  },
  textInfo: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  // mask
  mask: {
    type: String,
    default: '',
  },
  pattern: {
    type: String,
    default: '',
  },
  //
  leftIcon: {
    type: String,
    default: '',
  },
  iosTelegramFix: {
    type: Object,
    default: {
      top: 0,
      bottom: 0,
    },
  }
})

const emit = defineEmits(['submit', 'focus', 'options', 'blur'])

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

const target = ref()
const {focused} = useFocus(target)

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const state = reactive({

})

watch(focused, focused => {
  setTimeout(() => {
    state.isTrottleFocused = focused
  }, 0)
})

</script>

<template>
  <div
    class="textInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid, fullWidth: fullWidth }"
  >
    <label
      v-if="!onlyInput"
      :for="name"
    >
      {{ label }}
    </label>
    <div
      class="input__box"
    >
      <div
        class="inputWrapper"
      >
        <div
          v-if="leftIcon"
          class="leftIcon"
        >
          <Icon
            :icon="require(`@/assets/icons/gray_24_search.svg`)"
          />
        </div>
        <input
          :id="name"
          ref="target"
          v-maska
          :name="name"
          :type="type"
          :value="inputValue"
          :placeholder="placeholder"
          :inputmode="inputmode"
          :data-maska="mask"
          :pattern="pattern"
          :disabled="disabled"
          :data-iosTelegramFixTop="iosTelegramFix.isActive ? props.iosTelegramFix.top : undefined"
          :data-iosTelegramFixBottom="iosTelegramFix.isActive ? props.iosTelegramFix.bottom : undefined"
          @input="handleChange"
          @blur="handleBlur();emit('blur')"
          @focus="$emit('focus')"
          @keyup.enter="$emit('submit')"
        />
  
        <div
          class="buttons"
        >
          <AppButton
            v-show="inputValue"
            size="small"
            variant="transparent"
            @click="handleChange('')"
          >
            <Icon
              class="clearIcon"
              :icon="require(`@/assets/icons/gray_24_modalClose.svg`)"
            />
          </AppButton>
        </div>
      </div>
      <AppButton
        v-if="!isFilters"
        variant="secondary"
        size="large"
        @click="$emit('options')"
      >
        <Icon
          class="clearIcon"
          :icon="require(`@/assets/icons/gray_24_options.svg`)"
        />
      </AppButton>
    </div>
    <div
      v-if="textInfo && !onlyInput"
      class="textInfo"
    >
      {{ textInfo }}
    </div>
    <div
      v-if="!onlyInput"
      class="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/variables";

.input {
  &__box {
    display: flex;
    gap: 8px;
  }
}

.textInput {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.fullWidth {
    width: 100%;
  }

  label {
    color: var(--form-label-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    width: 100%;
    position: relative;

    .leftIcon {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    .buttons {
      position: absolute;
      right: 6px;
      top: 6px;
      display: flex;
    }

    input {
      width: 100%;
      border-radius: 12px;
      color: var(--text-secondary-color);
      border: 1px solid var(--form-border-base-color);
      font-size: 16px;
      font-weight: 400;
      height: 48px;
      padding: 12px 48px 12px 14px;

      &::placeholder {
        color: #838181;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }

      &:disabled {
        color: #838181;
        border: 1px solid #303030;
      }

      &:focus {
        border: 1px solid var(--form-border-active-color);
      }
    }

    .leftIcon + input {
      padding-left: 42px;
    }
  }

  .inputError {
    border: 1px solid var(--form-border-active-color) !important;
  }

  .errorMessage {
    color: var(--text-error-color);
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .textInfo {
    color: #838181;
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .error_icon {
    position: absolute;
    top: calc(50%);
    right: 12px;
    transform: translateY(-50%);
    z-index: 100000;
  }
}

.TextInput input {
  color: #fff;
  border: 1px solid var(--form-border-base-color);

  &:focus {
    border: 1px solid var(--form-border-active-color);
  }
}

//@media only screen and (max-width: 992px) {
//  .TextInput input {
//    height: 56px;
//    padding: 0px 16px;
//  }
//}
</style>
