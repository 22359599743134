<template>
  <img
    v-fullscreen-image="{
      imageUrl: [props.data.image],
      zoom: true,
    }"
    :src="imagesLinkCache.cache(props.data.image)"
  />
</template>

<script setup>
import {imagesLinkCache} from '@/utils/imagesLinkCache'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

</script>

<style lang="scss" scoped>

img {
  width: 100%;
  border-radius: 12px;
  user-select: none;
}

</style>
