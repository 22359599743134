<template>
  <div
    class="textInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid, fullWidth: fullWidth }"
  >
    <ImageCropper
      :aspect-ratio="aspectRatio"
      :files="state.filesToCrop"
      @on-crop="onCrop"
      @on-close="state.filesToCrop = null"
    />

    <label
      :for="name"
    >
      {{ label }}
    </label>

    <div
      class="inputWrapper"
    >
      <div
        class="imagePreview"
        :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.imagePreview ?? props.imagePreview))}"
      >
        <label
          :class="{hasImage: state.imagePreview ?? props.imagePreview}"
          class="wrapper"
          :for="uid"
        >
          <input
            :id="uid"
            class="input"
            type="file"
            accept="image/*"
            @change="onFileChange"
          />
        </label>
      </div>
    </div>

    <div
      v-if="textInfo"
      class="textInfo"
    >
      {{ textInfo }}
    </div>
    <div
      class="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import {reactive, toRef} from 'vue'
import {useField} from 'vee-validate'
import {getUidNumber} from '@/utils/globalUidCounter'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import ImageCropper from "@/components/UI/ImageCropper.vue"
import {resizeImage} from "@/utils/resizeImage.js"

const uid = `ImageCover-${getUidNumber()}`

const state = reactive({
  imagePreview: null,
  filesToCrop: [],
})

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  textInfo: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  imagePreview: {
    type: String,
  },
  aspectRatio: {
    type: Number,
    default: 1,
  },
})

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

async function onFileChange(e) {
  const newImage = await resizeImage({
    file: e.target.files[0],
    maxSize: 1080 * 4,
  })
  state.filesToCrop = [newImage]
}

async function onCrop(rawFile) {
  const blob = await resizeImage({
    file: rawFile,
    maxSize: 1080,
  })
  const file = new File([blob], rawFile.name)
  state.file = file
  if (!file) {
    return
  }
  state.imagePreview = URL.createObjectURL(file)
  state.file = null
  state.filesToCrop = []
  handleChange(file)
}


</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.wrapper {
  cursor: pointer;
  background-color: var(--card-secondary-background);
  height: 106px;
  width: 106px;
  border-radius: 16px;
  display: flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("@/assets/icons/white_24_camera.svg");

  &.hasImage {
    background-color: rgba(34,34,34,.2);
  }
}

.imagePreview {
  border-radius: 16px;
  background-size: cover;
  background-position: center center;
}

input {
  display: none;
}

.textInput {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.fullWidth {
    width: 100%;
  }

  label {
    color: var(--form-label-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    height: 106px;
    width: 106px;
    position: relative;
  }

  .errorMessage {
    color: var(--text-error-color);
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .textInfo {
    color: #838181;
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }
}

</style>
