<template>
  <Event
    :event="state.eventsList[0]"
    :back-action="backAction"
  />
</template>

<script setup>
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios.js'
import moment from 'moment'
import {monthNames} from '@/configs/monthNames.js'
import {useRoute} from 'vue-router'
import Event from '@/views/HomeView/Event.vue'
import {router} from '@/router/router.js'

const route = useRoute()

const state = reactive({
  eventsList: [],
})

function backAction() {
  router.push({name: 'Home'})
}

onBeforeMount(async () => {
  try {
    const res = await appAxios.events.fetchOne(route.params.eventId)

    const eventsList = [res.data]
    state.eventsList = eventsList.sort(function (a, b) {
      return new Date(a.start_date) - new Date(b.start_date)
    })

    for (const event of state.eventsList) {
      const {start_date, end_date} = event
      const start = moment(start_date, 'YYYY-MM-DD')
      const end = moment(end_date, 'YYYY-MM-DD')

      if (start.year() === end.year() && start.month() === end.month() && start.day() === end.day()) {
        state.dateSting = `${Number(start.format('DD.MM'))}`
        return
      }
      if (start.year() === end.year() && start.month() === end.month()) {
        state.dateSting = `${Number(start.format('DD.MM'))} - ${Number(end.format('DD.MM'))}`
        return
      }
      state.dateSting = `${Number(start.format('DD.MM'))} - ${Number(end.format('DD.MM'))}`
    }
  } catch (e) {
    console.error(e)
    // todo: error?
  } finally {
    state.isLoading = false
  }
})


</script>

<style lang="scss" scoped>

</style>
